import Axios from "axios";
import AkkhorLayout from '../../layout/akkhor';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/api";
import ViewQuestion from "../questions/ViewQuestion";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import moment from 'moment'
import { confirmAlert } from "react-confirm-alert";
import { Modal, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MyEditor from "../../component/MyEditor";

export default function MarkTestView(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [Answers, setAnswers] = useState(null);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [modalTestReset, setModalTestReset] = useState(false);
  const [markPoint, setMarkPoint] = useState(0);
  const [listPoint, setListPoint] = useState({});
  const [listComment, setListComment] = useState({});
  const [dataEdit, setDataEdit] = useState({ type: '', data: [] });

  const [dateBegin, setDateBegin] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);

  const handleCallback = (start, end, label) => {
    setDateBegin(start.format('DD-MM-yyyy HH:mm'))
    setDateEnd(end.format('DD-MM-yyyy HH:mm'))
  }

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          const _answers = _detail.info
          setDetail(_detail)

          let _listPoint = {}
          let _listComments = {}
          let listCorrect = []

          _answers.map((ans, index) => {
            _listPoint[ans.id] = ans.point
            _listComments[ans.id] = ans.comments
            let _corrects = ans.corrects
            if (ans.code === "QMatching") {
              let _x = [];
              _corrects.map((item) => {
                _x[item.right] = item.label;
                return item;
              })
              _corrects.corrects = _x;
            }
            ans.corrects = _corrects
            listCorrect.push(ans)
            return ans
          })
          setListPoint(_listPoint)
          setListComment(_listComments)
          setAnswers(listCorrect);
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id, reload]);

  const marktestActionTotal = () => {
    if (dataEdit.type === 'setPoint') {
      const data_save = { type: 'total_point', point: markPoint };
      Axios.put(URL_LIST_ALL + "at_exam_students/" + id, data_save, user.config).then(res => {
        // console.log(res);
        if (res.data.status === 'success') {
          setReload(!reload)
          confirmAlert({
            title: 'Thành công', message: 'Bạn đã sửa thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
        }
        else alert(res.data.message)
      }).catch(err => {
        console.log(err)
      });
    }
  }

  const marktestRefresh = () => {
    if (dateBegin && dateEnd) {
      const data_save = { status: 0, dateend: dateEnd };
      Axios.put(URL_LIST_ALL + "at_exam_students/" + id, data_save, user.config).then(res => {
        console.log(res);
        if (res.data.status === 'success') {
          confirmAlert({
            title: 'Thành công', message: 'Cập nhập thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
          setReload(!reload)
        }
        else alert(res.data.message)

      }).catch(err => {
        console.log(err)
      });
      setModalTestReset(false)
    }

  }

  const saveMarkPoint = () => {
    if (listPoint) {
      const data_save = { points: listPoint, comments: listComment }
      Axios.put(URL_LIST_ALL + "marktest/" + id, data_save, user.config).then(res => {
        console.log(res);
        if (res.data.status === 'success') {
          confirmAlert({
            title: 'Thành công', message: 'Cập nhập thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
          setReload(!reload)
        }
        else alert(res.data.message)

      }).catch(err => {
        console.log(err)
      });
    }
  }
  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {(detail && Answers) ? (<div className="container-fluid">
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>Chấm điểm {detail.type === 1 ? "Phiếu bài tập" : "Bài kiểm tra"}</li>
          </ul>
        </div>
        <div className="card height-auto">
          <div className="card-body">
            <div>
              <h2 className="text-center mb-3">{detail.title}</h2>
              <div className="row">
                <div className="col-12"><strong>Họ tên:</strong> {detail.student_info && detail.student_info.fullname}</div>
                <div className="col-12"><strong>Mã số:</strong> {detail.student_info && detail.student_info.code}</div>
                <div className="col-12"><strong>Lớp:</strong> {detail.student_info && detail.student_info.class}</div>
              </div>
              <div className="row">
                <div className="col-sm-3 d-flex">
                  <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.correct_number}/{detail.total_number}</div>
                    <div>Số câu trả lời đúng</div>
                  </div>
                </div>
                <div className="col-sm-3 d-flex">
                  <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                  <div className="py-1 px-2">
                    <div className="cursor-pointer rounded" style={{ fontSize: 25, fontWeight: 'bold' }} onClick={() => { setDataEdit({ type: 'setPoint', data: {} }); setTimeout(setShow(true), 200); setMarkPoint(0) }}>
                      {detail.point > 10 ? 10 : Math.round(detail.point * 10) / 10}
                    </div>
                    <div>Điểm đạt được</div>
                    <div className="btn btn-sm btn-info cursor-pointer rounded" onClick={() => { setDataEdit({ type: 'setPoint', data: {} }); setTimeout(setShow(true), 200); setMarkPoint(0) }}>
                      Sửa tổng điểm
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 d-flex">
                  <div className="text-info" style={{ fontSize: 50 }}><i className="far fa-clock"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{moment.unix(detail.time).utc().format(detail.time > 3600 ? 'HH:mm:ss' : 'm:ss')}</div>
                    <div>Thời gian làm bài</div></div>
                </div>
                <div className="col-sm-3">
                  <div className="btn btn-sm btn-info cursor-pointer rounded mt-3" style={{ maxHeight: 31, color: "#FFF" }} onClick={() => setModalTestReset(true)}>
                    Cho phép làm lại
                  </div>
                  <div className={modalTestReset ? 'w-100' : 'd-none'}>
                    <span>Thời gian làm bài</span>
                    <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: true }} onCallback={handleCallback} >
                      <div className='d-flex align-items-center'>
                        <input
                          className="form-control h-31 form-control-sm"
                          placeholder='Từ ngày'
                          readOnly={true}
                          onChange={(e) => setDateBegin(e)}
                          value={dateBegin}
                        />
                        <span className='fas fa-arrow-right mx-4' />
                        <input
                          className="form-control h-31 form-control-sm"
                          placeholder='Đến ngày'
                          readOnly={true}
                          onChange={(e) => setDateEnd(e)}
                          value={dateEnd}
                        />
                      </div>
                    </DateRangePicker>
                    <div className="btn btn-sm btn-info cursor-pointer rounded mt-1 mb-3" style={{ maxHeight: 31, color: "#FFF" }} onClick={() => marktestRefresh()}>
                      Lưu
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end mb-2">
                <Button variant="success" onClick={() => { saveMarkPoint() }}>Lưu lại</Button>
              </div>


              {Answers && Answers.map((ans, index) => (
                <div key={index} className="mb-2 border rounded overflow-hidden " id={"nuques-" + index}>
                  <div>
                    <div className={"font-weight-bold p-2 " + (ans.id && ans.percent === 100 ? 'bg-info' : 'bg-danger') + " text-light d-flex justify-content-between"}>
                      <div>Câu hỏi {index + 1}</div>
                      <div className="d-flex">
                        <input style={{ maxWidth: 100 }} type="text" className="form-control form-control-sm" placeholder={Math.round(ans.point * 10) / 10}
                          onChange={e => {
                            setListPoint({ ...listPoint, [ans.id]: e.target.value })
                          }} />
                      </div>
                    </div>
                    {ans.parent_title && <div className="p-2 border-bottom">{parse(ans.parent_title)}</div>}
                    <div className="p-3 border-bottom mb-3">{parse(ans.title)}</div>
                    <div>
                      <ViewQuestion item={ans}></ViewQuestion>
                    </div>
                    <div className="comment border-top p-2" style={{ height: 200 }}>
                      <span>Nhận xét từ giáo viên</span>

                      <MyEditor height={150} key={Math.random()} initialValue={listComment[ans.id] && listComment[ans.id]} onValue={(value) => setListComment({ ...listComment, [ans.id]: value })} />
                    </div>
                  </div>
                </div>)
              )}
              <div className="w-100 d-flex justify-content-end mt-2">
                <Button variant="success" onClick={() => { saveMarkPoint() }}>Lưu lại</Button>
              </div>
            </div>

          </div>
        </div>
      </div>
      ) : (<>
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      </>)}

      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Sửa tổng điểm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="w-100">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" placeholder="Nhập tổng điểm" onChange={e => setMarkPoint(e.target.value)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShow(false); setMarkPoint(0) }}>Đóng</Button>
          <Button variant="success" onClick={() => { marktestActionTotal() }}>Lưu</Button>
        </Modal.Footer>
      </Modal>
    </AkkhorLayout>
  );
}
