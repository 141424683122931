import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
import moment from "moment";
import ItemStudentHome from "./ItemStudentHome";
import Loading from "../component/Loading";
export default function DashboardStudent() {
    const { user } = useSelector((state) => state.auth);
    const class_id = user && user.data && user.data.class && user.data.class.class_id>0?user.data.class.class_id:0;
    const [loading] = useState(false);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [dayChoice, setDayChoice] = useState(moment());
    const [listTimeTable, setListTimeTable] = useState([]);
    //Lấy danh sách thời khoá biểu
    useEffect(  () => {
        let mounted = true;
        if (dayChoice !== null) {
            setListTimeTable([])
            Axios.get(URL_LIST_ALL + "timetable?page=1&limit=20&class_id=" + class_id+"&date="+dayChoice.format('YYYY-MM-DD')+'&orderby=orderonday-asc', user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if(mounted)setListTimeTable(res.data.data);
                }else console.log(res.data.message);
            }).catch((err) => {console.log(err)});
        }
        return () =>  (mounted = false);
    }, [dayChoice,user,class_id]);
    //
    const creatWeek = () => {
        let weeks= []
        for (let i=0; i<7; i++) {
            weeks.push(moment().weekday(currentWeek+i))
        }
        return weeks
    }
    function showTime() {
        let str1  = dayChoice.format('dddd, DD MMMM');
        let str2 = dayChoice.format('YYYY');
        let string = str1 + ' năm ' + str2;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const today=()=>{
        setDayChoice(moment())
        setCurrentWeek(0)
    }
    
   

    return ( <>
       {loading ? ( <Loading />) : (
        <><div className="breadcrumbs-area">
           <h3>Student Dashboard</h3>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="dashboard-summery-one ">
                    <div className="d-flex mb-3 justify-content-between">
                    <h3>Lịch tuần này: {showTime()}</h3>
                    <button type="button" className="btn btn-sm btn-info" onClick={today}>Hôm nay</button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className={"p-2  pr-md-5"} style={{cursor: 'pointer'}} onClick={()=>setCurrentWeek(currentWeek-7)}>&lt;</div>  
                    {creatWeek().map((item, i) => {
                    return (<div key={i} className={"p-2 px-md-5 "+(i===6?"text-danger":(item.format('YYYY-MM-DD')===dayChoice.format('YYYY-MM-DD')?"text-primary":"text-dark"))} style={{cursor: 'pointer'}} onClick={()=>setDayChoice(item)}>
                        <div>{item.format('ddd')}</div>
                        <div>{item.format('DD')}</div>
                    </div>)
                    })}
                    <div className={"p-2  pl-md-5"} style={{cursor: 'pointer'}} onClick={()=>setCurrentWeek(currentWeek+7)}>&gt;</div>
                    </div>
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Giờ</th>
                                    <th>Tiết</th>
                                    <th>Lớp</th>
                                    <th>Giáo viên</th>
                                    <th>Môn</th>
                                    <th>Bài dạy</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listTimeTable.map((item, i) => {
                                return (<ItemStudentHome key={i} timeTable={item} ></ItemStudentHome>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
      )}
    </>)
}