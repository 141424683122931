import React, { useState } from "react";
import LineTo from 'react-lineto';
import parse from 'html-react-parser'

const style = {
    delay: true,
    borderColor: '#6c757d',
    borderStyle: 'solid',
    borderWidth: 3,
};

export default function QMatching(props) {
    const { item, currentAnswer } = props;
    const answers = item.answers
    const [choices] = useState(currentAnswer || [])

    return (<div className="col-12 form-group mt-3">
        <div className="d-flex">
            {answers && <div>
                {answers.map((itAs, i) => <div key={i} className="mb-2 ">
                    <button className={"btn btn-sm  " + item.id + "-lineleft" + (i) + " btn-secondary"} >{parse(itAs + '')}</button>
                </div>)}
            </div>}

            {answers && <div className="ml-5">
                {item.corrects.corrects.map((itAs, i) => <div key={i} className="mb-2 ">
                    <button className={"btn btn-sm btn-secondary " + item.id + "-lineright" + (i)} >{parse(itAs + '')}</button>
                </div>)}
            </div>}
            {choices.map((itAs, i) => <LineTo key={i} from={item.id + "-lineleft" + itAs.left} to={item.id + "-lineright" + itAs.right} fromAnchor="right" toAnchor="left"  {...style} />)}
        </div>
    </div>);
}
