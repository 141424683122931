import React from 'react';
import AkkhorLayout from '../layout/akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

export default function ProfilePage() {
    const { user } = useSelector((state) => state.auth);
  return (
    <AkkhorLayout idrole="my-profile">
                <div className="breadcrumbs-area">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li>
                        <li>Hồ sơ của tôi</li>
                    </ul>
                </div>
                
                {user&&<div className="card height-auto">
                    <div className="card-body">
                    
                        <div className="single-info-details">
                            <div className="item-content">
                                <div className="header-inline item-header">
                                    <h3 className="text-dark-medium font-medium">{user.data.fullname}</h3>
                                    <div className="header-elements">
                                        <ul>
                                            <li><Link to="/update-profile" title="Cập nhật hồ sơ"><i className="far fa-edit"></i> Cập nhật hồ sơ</Link></li>
                                            <li><Link to="/change-password" title="Thay đổi mật khẩu"><i className="far fa-lock"></i> Thay đổi mật khẩu </Link></li>
                                        </ul>
                                    </div>
                                </div>
                        
                                <div className="info-table table-responsive">
                                    <table className="table text-nowrap">
                                        <tbody>
                                            <tr>
                                                <td>Họ và tên:</td>
                                                <td className="font-medium text-dark-medium">{user.data.fullname}</td>
                                            </tr>
                                            <tr>
                                                <td>Giới tính:</td>
                                                <td className="font-medium text-dark-medium">{user.data.gender===1?'Nam':'Nữ'}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Ngày sinh:</td>
                                                <td className="font-medium text-dark-medium">{user.data.birthday}</td>
                                            </tr>
                                            <tr>
                                                <td>Dân tộc:</td>
                                                <td className="font-medium text-dark-medium">{user.data.nation}</td>
                                            </tr>
                                          
                                            <tr>
                                                <td>Email:</td>
                                                <td className="font-medium text-dark-medium">{user.data.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Di động:</td>
                                                <td className="font-medium text-dark-medium">{user.data.mobile}</td>
                                            </tr>
                                            <tr>
                                                <td>Địa chỉ:</td>
                                                <td className="font-medium text-dark-medium">{user.data.address}</td>
                                            </tr>
                                            {user.data.school&&<tr>
                                                <td>Trường:</td>
                                                <td className="font-medium text-dark-medium">{user.data.school.title}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}    
    </AkkhorLayout>
  );
}
