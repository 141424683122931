import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterClassWeek from "../../component/FilterClassWeek";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import Moment from "react-moment";
import { CSVLink } from "react-csv";
import moment from "moment";
//Nhật ký lớp học
export default function DiaryPage() { 
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const [classChoice, setClassChoice] = useState(null);
  const [weekChoice, setWeekChoice] = useState(0);
  const [dayChoice, setDayChoice] = useState('all');
  const [timeTable, setTimeTable] = useState([]);
  const [student, setStudent] = useState(null);//Danh sách học sinh vắng mặt
  const [minon, setMinon] = useState(10);
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice && weekChoice && year_id > 0) {
      setTimeTable([])
      //Lấy thời khóa biểu
      let urlTimeTable = URL_LIST_ALL + "timetable?page=1&limit=500&class_id=" + classChoice + "&week=" + weekChoice + '&orderby=date-asc_orderonday-asc&year_id=' + year_id;
      if (dayChoice !== 'all') urlTimeTable += "&date=" + dayChoice;
      Axios.get(urlTimeTable, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTimeTable(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

      //Lấy danh sách học sinh vắng mặt trong các tiết học
      setStudent([])
      let urlOnday = URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=1&class_id=" + classChoice + "&week=" + weekChoice + '&orderby=date-asc_orderonday-asc&year_id' + year_id
      if (dayChoice !== 'all') urlOnday += "&date=" + dayChoice;
      Axios.get(urlOnday, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) {
              const list = res.data.data;
              let newobj = {}
              let _findminon = minon;
              list.map((item) => {
                if (item.orderonday < _findminon) _findminon = item.orderonday
                if (item.status > 2) {
                  if (newobj[item.date + item.orderonday]) newobj[item.date + item.orderonday] += ', ' + item.fullname
                  else newobj[item.date + item.orderonday] = '' + item.fullname
                }
                return item
              })
              setMinon(_findminon)
              setStudent(newobj);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, weekChoice, dayChoice, user, year_id]);// eslint-disable-line



  const xepLoai = (total) => {
    if (total >= 9) return 'Tốt'
    if (total >= 7) return 'Khá'
    if (total >= 5) return 'Trung bình'
    if (total >= 1) return 'Kém'
    return 'Chưa'
  }


  const filterDataExport = () => {
    let array = [
      {
        '1': 'Tiết học',
        '2': 'Môn học',
        '3': 'Nội dung',
        '4': 'Học sinh vắng',
        '5': 'Nhận xét của giáo viên',
        '6': '',
        '7': 'Xếp loại tiết học',
        '8': '',
        '9': '',
        '10': '',
        '11': '',
        '12': '',
        '13': 'Giáo viên',
      }, {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': 'Ưu điểm',
        '6': 'Tồn tại',
        '7': 'HT (4)',
        '8': 'KL (3)',
        '9': 'VS (2)',
        '10': 'CC (1)',
        '11': 'Cộng (10)',
        '12': 'Xếp loại',
        '13': '7'
      }

    ];

    timeTable.map((item, i) => {
      if (item.orderonday === 1) {
        array.push({
          '1': moment(item.date).format('dddd'),
          '2': moment(item.date).format('DD/MM/YYYY'),
          '3': '',
          '4': '',
          '5': '',
          '6': '',
          '7': '',
          '8': '',
          '9': '',
          '10': '',
          '11': '',
          '12': '',
          '13': '',
        })
      }
      array.push({
        '1': item.orderonday,
        '2': item.title,
        '3': item.name,
        '4': student[item.date + item.orderonday],
        '5': item.comment_advantages,
        '6': item.comment_defect,
        '7': item.point_learn,
        '8': item.point_discipline,
        '9': item.point_hygiene,
        '10': item.point_diligent,
        '11': item.point_total,
        '12': xepLoai(item.point_total),
        '13': item.fullname
      });
      return item;
    })
    return array
  }

  return (
    <AkkhorLayout idrole="report.report-diary">
      <h4 className="my-2">Nhật ký lớp học</h4>
      <div className="d-flex justify-content-between" >
        <FilterClassWeek
          classChoice={classChoice}
          setClassChoice={setClassChoice}
          weekChoice={weekChoice}
          setWeekChoice={setWeekChoice}
          dayChoice={dayChoice}
          setDayChoice={setDayChoice}
          viewtype={'all'}
          allday={true}
          typeclass={1}
        ></FilterClassWeek>
        <CSVLink
          data={filterDataExport()}
          filename={"Nhat-Ky-Lop-Hoc-Tuan-" + weekChoice + "-Nam-" + moment().format('YYYY') + ".csv"}
          className="btn"
          target="_blank"
        >
          Tải xuống <span className="fas fa-download text-info"></span>
        </CSVLink>
      </div>
      <div className="mt-3">
        <table className="table table-bordered">
          <thead className="align-items-center bg-secondary text-white text-center">
            <tr>
              <td>Tiết học</td>
              <td>Môn học</td>
              <td>Nội dung</td>
              <td>Học sinh vắng</td>
              <td colSpan="2">Nhận xét của giáo viên</td>
              <td colSpan="6">Xếp loại tiết học</td>
              <td>Giáo viên</td>
            </tr>
            <tr>
              <td>(1)</td>
              <td>(2)</td>
              <td>(3)</td>
              <td>(4)</td>
              <td>Ưu điểm</td>
              <td>Tồn tại</td>
              <td>HT<br />(4)</td>
              <td>KL<br />(3)</td>
              <td>VS<br />(2)</td>
              <td>CC<br />(1)</td>
              <td>Cộng<br />(10)</td>
              <td>XL</td>
              <td>(7)</td>
            </tr>
          </thead>
          <tbody>
            {timeTable.map((item, i) => (
              <>
                {item.orderonday === minon && <tr>
                  <td colSpan="13" className="bg-warning text-capitalize"><Moment locale="vi" format="dddd">{item.date}</Moment> <Moment format="DD/MM/YYYY">{item.date}</Moment> </td>
                </tr>}
                <tr>
                  <td>{item.orderonday}</td>
                  <td>{item.title}</td>
                  <td>{item.name}</td>
                  <td>{student[item.date + item.orderonday]}</td>
                  <td>{item.comment_advantages}</td>
                  <td>{item.comment_defect}</td>
                  <td>{item.point_learn}</td>
                  <td>{item.point_discipline}</td>
                  <td>{item.point_hygiene}</td>
                  <td>{item.point_diligent}</td>
                  <td>{item.point_total}</td>
                  <td>{xepLoai(item.point_total)}</td>
                  <td>{item.fullname} {item.fullname2 && <>, {item.fullname2}</>}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </AkkhorLayout>
  );
}