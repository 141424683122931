import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/api";
import moment from "moment";
import { useSelector } from "react-redux";
import SelectWeek from "./SelectWeek";
//SELECT  SUBSTRING_INDEX(fullname, ' ', 2)
export default function FilterGradeClassWeek(props) {
    const { gradeChoice, setGradeChoice, setClassChoice, classChoice, dayChoice, onListTimeTable,setSubjectChoice,subjectChoice } = props;
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const [weekChoice, setWeekChoice] = useState(0);
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    const [listSubject, setListSubject] = useState([]);
    const [listTimeTable, setListTimeTable] = useState([]);

    //Lấy danh sách khối
    useEffect(() => {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "grades?page=1&limit=500&orderby=title-ASC&school_id=" + school_id, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                if (mounted) setListGardes(res.data.data);
                if (res.data.data && res.data.data.length > 0)
                    if (mounted) setGradeChoice(res.data.data[res.data.data.length - 1].id);
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
        return () => (mounted = false);
    }, [school_id, user]);// eslint-disable-line
    //Lấy danh sách lớp
    useEffect(() => {
        let mounted = true;
        if (gradeChoice && year_id>0) {
            Axios.get(URL_LIST_ALL + "class?page=1&limit=500&orderby=title-ASC&grade_id=" + gradeChoice+ "&year_id=" + year_id, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListClass(res.data.data);
                    if (res.data.data && res.data.data.length > 0 && !classChoice) {
                        if (mounted) setClassChoice(res.data.data[res.data.data.length - 1].id);
                    }
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () => (mounted = false);
    }, [gradeChoice, year_id, user]);// eslint-disable-line
    //Lấy danh sách thời khoá biểu
    useEffect(() => {
        let mounted = true;
        if (weekChoice > 0) {
            let link = URL_LIST_ALL + "timetable?page=1&limit=500"
            if (classChoice > 0) link += "&class_id=" + classChoice;
            if (gradeChoice > 0) link += "&grade_id=" + gradeChoice;
            Axios.get(link + "&week=" + weekChoice + '&orderby=date-asc_orderonday-asc', user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    const list = res.data.data;
                    if (mounted) setListTimeTable(list);
                    let dayDefault = null;
                    list.forEach((item) => {
                        const now = moment().format('YYYY-MM-DD');
                        if (now === item.date) {
                            dayDefault = item
                        }
                    });
                    if (dayDefault === null) dayDefault = list[0]
                    if (dayDefault !== null) {
                        if (mounted) chooseDay(dayDefault.date, list)
                    }
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        } else {
            setListTimeTable([])
            onListTimeTable([])
        }
        return () => (mounted = false);
    }, [classChoice, gradeChoice, weekChoice, user]);// eslint-disable-line

     //Lấy danh sách môn học
     useEffect(() => {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "subjects?key=&istree=1&orderby=title-ASC&page=1&limit=50&school_id=" + school_id + "&grade_id=" + gradeChoice, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                if (mounted) setListSubject(res.data.data);
               
            } else console.log(res.data.message);
        }).catch((err) => console.log(err));
        return () => (mounted = false);
    }, [school_id,gradeChoice, user]);// eslint-disable-line

    //Lay danh sach ngay trong tuan
    const createWeekDate = () => {
        let list = {};
        listTimeTable.forEach(item => {
            list[item.date] = true;
        })
        return list;
    }

    const chooseDay = (date, list) => {
        onListTimeTable(list.filter(item => item.date === date), date);
    };
    return (
        <form className="form-inline">

            <label className="mr-2">Khối</label>
            <select className="form-control form-control-sm" value={gradeChoice ? gradeChoice : 0} onChange={(e) => {
                setClassChoice(null);
                setGradeChoice(e.target.value)
            }}>
                {listGardes.map((item) => {
                    return <option key={item.id} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0 ml-md-2">Lớp học</label>
            <select className="form-control form-control-sm" value={classChoice ? classChoice : 0} onChange={(e) => setClassChoice(e.target.value)}>
                {listClass.map((item, index) => {
                    return <option key={index} value={item.id}>{item.title}</option>
                })}
            </select>
            <label className="mr-2  mt-2 mt-sm-0 ml-md-2">Môn học</label>
            <select className="form-control form-control-sm" value={subjectChoice ? subjectChoice : 0} onChange={(e) => {
                setSubjectChoice(e.target.value);
            }}>
                <option value={0}>Tất cả các môn</option>
                {listSubject.map((item) => {
                    return <option key={item.id} value={item.id}>{item.title}</option>
                })}
            </select>
           
            <label className="mr-2  mt-2 mt-sm-0  ml-md-2">Tuần thứ</label>
            <SelectWeek weekChoice={weekChoice} setWeekChoice={setWeekChoice}></SelectWeek>
            <label className="mr-2 mt-2 mt-sm-0  ml-md-2">Ngày</label>
            <select className="form-control form-control-sm text-capitalize" value={dayChoice ? dayChoice : 0} onChange={(e) => chooseDay(e.target.value, listTimeTable)}>
                <option value={0} disabled>Chọn ngày</option>
                {Object.keys(createWeekDate()).map((date, index) => {
                    return <option key={index} value={date} >{moment(date).format('dddd') + ' - ' + moment(date).format('DD-MM-YYYY')}</option>
                })}
            </select>
        </form>
    );
}
