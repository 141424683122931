import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/akkhor';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/api';
import Moment from "react-moment";
import SRList from "../../component/List";

export default function MarkTestsStudent() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "exam_tests_check/"+ id, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) {
                const test_id = res.data.data.test_id;
                let url = URL_LIST_ALL + "at_exam_tests/" + test_id + '?info=1'
                Axios.get(url, user.config).then(async (res) => {
                    if (res.data.status === "success" && mounted) {
                        const _detail = res.data.data;
                        setDetail(_detail);
                    }
                    else console.log(res.data.message);
                }).catch((err) => console.log(err));
            }
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user, id]);

    let columns = [
        { name: "id", label: "ID", options: { filter: false, sort: true } },
        { name: "code", label: "Mã số", options: { filter: true, sort: true } },
        { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true } },
        { name: "class_title", label: "Lớp", options: { filter: false, sort: false } },
        {
            name: "status", label: "Trạng thái", options: {
                filter: true, sort: true, customBodyRender: (value) => {
                    if (value > 0)
                        return <b className="text-success">Đã làm</b>
                    else return <b className="text-danger">Chưa làm</b>
                }
            }
        },
        {
            name: "info", label: "Thời gian nộp bài", options: {
                filter: false, sort: true,
                customBodyRender: (value) => {
                    let info = JSON.parse(value)
                    const time = new Date(info.datecreate * 1000);
                    if (!isNaN(time.getTime())) return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
                    return null;
                },
            },
        },
        {
            name: "info", label: "Số câu đúng", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    if (value) {
                        let info = JSON.parse(value)
                        return info.correct_number;
                    } else return null;
                },
            }
        },
        {
            name: "info", label: "Số câu sai", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    if (value) {
                        let info = JSON.parse(value)
                        if (info.total_number > 0) return info.total_number - info.correct_number;
                    } return null;
                },
            }
        },
        // { name: "point", label: "Điểm số", options: { filter: true, sort: true } },
        {
            name: "point", label: "Điểm số", options: {
                filter: true, sort: true, customBodyRender: (value, tableMeta) => {
                    return value > 10 ? 10 : value
                }
            },
        },
        {
            name: "id", label: "#", options: {
                filter: true, sort: true, customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[4];
                    if (status > 0)
                        if(detail.type !== 3 ){
                        return <Link to={"/marktests-view/" + value} target="_blank"><b className="text-info">Chấm bài</b></Link>
                        }else return <Link to={"/marktests-azota-view/" + value} target="_blank"><b className="text-info">Chấm bài</b></Link>
                    else return null;
                }
            },
        }
    ];


    //lastname-ASC_fullname-ASC
    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>
            {detail && <>
                <div className="breadcrumbs-area py-2">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li>
                        <li> <Link to="/examination-report">Thống kê</Link></li>
                        <li>Chấm điểm {detail.title}</li>
                    </ul>
                </div>
                <div className="card height-auto">
                    <div className="card-body">
                        
                        <SRList name={'exam_tests_check/'+id } idrole={"marktests"} linkaction={'tests'} params={"info=1"} title={""} defaultLimit={500} serverSide={true} noAction={true} onlyList={true} columns={columns} />

                    </div>
                </div>
            </>}
        </AkkhorLayout>
    );
}
