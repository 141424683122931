import { useFormik } from "formik";
import * as Yup from "yup";
import React, {  useEffect, useState } from "react";
import AkkhorLayout from "../../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { confirmAlert } from "react-confirm-alert";

export default function FormGeneralSettingPage() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(  () => {
        Axios.get(URL_LIST_ALL+'settings?school_id='+school_id,user.config).then(res => {
            if (res.data.status === "success") {
               if(res.data.data.length>0)setDetail(res.data.data[0]);
            } else console.log(res.data.message);
        }).catch(err => console.log(err));  
    },[school_id,user]);

    const formik = useFormik({
        initialValues: detail?detail:{email_alert_attend:''},
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
            email_alert_attend: Yup.string().trim().nullable().required('Nhập họ và tên')
        }),
        onSubmit: (values, { setSubmitting }) => {
            Axios.put(URL_LIST_ALL+'settings/'+detail.id, values, user.config).then(res => {
                setRefresh(!refresh);
                setSubmitting(false);
            }).catch(err => console.log(err));
        },
    });

    const createUserStudent = () => {
        Axios.get(URL_LIST_ALL+'queue/create-user-student',user.config).then(res => {
            if (res.data.status === "success") {
                confirmAlert({
                    title: 'Thông báo',
                    message: 'Ra lệnh tạo tài khoản thành công. Hệ thống cần phải xử lý trong 1-2 phút trước khi hoàn thành!',
                    buttons: [{ label: 'OK' }]
                });
            }else{
                confirmAlert({
                    title: 'Lỗi',
                    message: res.data.message,
                    buttons: [{ label: 'OK' }]
                });
            }
        }).catch(err => console.log(err));
    }
    

  return (
    <AkkhorLayout idrole="setting.general-setting">
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Cài đặt chung</h3>
                </div>
            </div>
            {detail&&<form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 form-group">
                        <label>Email nhận thông báo <span className="text-danger">*</span> (<span style={{fontStyle:'italic', fontSize:13}}>Các email ngăn cách bởi dấu chấm phẩy ;</span>)</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email_alert_attend")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="email_alert_attend"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Thời gian đăng ký hoặc huỷ suất ăn trước {formik.values.timelunch}h</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("timelunch")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="timelunch"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Màu chữ lịch TKB dạy thay </label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("color_isreplace")} autoComplete="off"/>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Màu chữ lịch TKB đổi lịch </label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("color_isrotate")} autoComplete="off"/>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Màu chữ lịch TKB dạy thay và đổi lịch</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("color_both")} autoComplete="off"/>
                    </div>

                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{formik.isSubmitting ? <Spinner />:<>Lưu cài đặt</>}</button>
                    </div>
                </div>
            </form>}
            <div className="border-top w-100 mt-3"></div>
            <div className="heading-layout1 pt-2">
                <div className="item-title">
                    <h3>Cài đặt khác</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12 form-group">
                    <label>Tự động tạo tài khoản đăng nhập cho tất cả học sinh trong trường</label>
                    <div><button type="reset" onClick={createUserStudent} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Tạo tài khoản</button></div>
                </div>
            </div>
        </div>
      </div>
    </AkkhorLayout>
  );
}
