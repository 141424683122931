import React from 'react';
import { Link } from 'react-router-dom';
import ChangeTeaching from './ChangeTeaching';
import NhanXetTietHoc from './NhanXetTietHoc';

export default function ItemTeachingHome(props) {
    const { i, timeTable, onChange, edit } = props;
    return (
        <>
           <tr  key={i}>
                <td>{timeTable.time}</td>
                <td>{timeTable.orderonday}</td>
                <td>{timeTable.classtitle}</td>
                <td>{timeTable.title}</td>
                <td>{timeTable.name}</td>
                <td>
                    <Link to={'/attends?grade_id='+timeTable.grade_id+'&class_id='+timeTable.class_id+'&week='+timeTable.week+'&date='+timeTable.date+'&idchoice='+timeTable.id} className="btn btn-sm btn-info mr-3">Điểm danh</Link>  
                    <Link to={'/examofnotebook?grade_id='+timeTable.grade_id+'&class_id='+timeTable.class_id+'&week='+timeTable.week+'&date='+timeTable.date+'&idchoice='+timeTable.id} className="btn btn-sm btn-success mr-3">Vở ghi chép</Link>  
                    <Link to={'/examofexercise?grade_id='+timeTable.grade_id+'&class_id='+timeTable.class_id+'&week='+timeTable.week+'&date='+timeTable.date+'&idchoice='+timeTable.id} className="btn btn-sm btn-primary mr-3">Bài tập</Link>  
                    <NhanXetTietHoc timeTable={timeTable} onChange={onChange} />  
                    {edit&&<ChangeTeaching timeTable={timeTable}  onChange={onChange} />}
                </td>
            </tr>
           
        </>
    );
}
