import React, { useState } from "react";
import parse from 'html-react-parser'

export default function QDropDrag(props) {
    const { item, currentAnswer } = props;
    const answers = item.answers
    const [corrects] = useState(currentAnswer || answers.list)

    return (<div className="col-12 form-group mt-3">
        {answers && corrects && <>
            {
                corrects.left ?
                    corrects.left.map((item, i) => <button key={i} className={"mb-2 btn btn-sm mr-3 btn-secondary"} >{parse(item)}</button>)
                :
                    corrects.map((item, i) => <button key={i} className={"mb-2 btn btn-sm mr-3 btn-secondary"} >{parse(item)}</button>)
            }
        </>}
    </div>);
}