import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import Resizer from "react-image-file-resizer";
import { confirmAlert } from "react-confirm-alert";

export default function MyEditor(props) {
  const editorRef = useRef(null);
  const { height, initialValue, onValue } = props;
  const [value, setValue] = useState(initialValue);
  const { user } = useSelector((state) => state.auth);
  //const [file, setFile] = useState(null)

  const inputRef = useRef(null);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600, //width
        600, //height
        "JPEG", 90, 0,
        (uri) => resolve(uri),
        "file"
      );
    });
  const uploadAudio = (event) => {

    const fileAudio = event.target.files[0]
    if (fileAudio) {
      let data = new FormData()
      data.append("type", "s3")
      data.append("name", "file")
      data.append("file", fileAudio)
      data.append("filetype", fileAudio.type)
      let file_size = fileAudio.size;
      if (file_size > 3145728) {
        confirmAlert({
          title: 'Lỗi',
          message: "File không được lớn hơn 3 Mb",
          buttons: [{ label: 'OK' }]
        })
        return
      } else {
        axios.post(URL_LIST_ALL + "upload", data, user.config).then(async (res) => {
          if (res.data.status === "success") {
            const url = res.data.url
            if(url){
              let oldValue = value.slice()
              let htmlAudio = '<audio controls src="'+url+'"> Your browser does not support them <code>audio</code> element.</audio>'
              setValue(oldValue + htmlAudio)
            }
            
            // setFile(res.data.url);
          } else {
            return;
          }
        }).catch((err) => { console.log(err) })
      }

    }
  }
  return (<>
    <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="audio/*" onChange={uploadAudio} />
    <Editor
      apiKey="jddlj5fpzsahmppu36m8i2l0ng8mowsrd9m8dxqodu2t2cw0"
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onFocusOut={() => {
        const valuecontent = editorRef.current.getContent()
        if (valuecontent && valuecontent !== initialValue) {
          onValue(editorRef.current.getContent())
        }
      }}
      onEditorChange={(newValue, editor) => {
        setValue(newValue);
      }}

      init={{
        height: height,
        menubar: false,
        external_plugins: {
          tiny_mce_wiris:
            "https://tomoclass.com/js/mathtype-tinymce5/plugin.min.js",
          pluginId: "http://localhost:3000/js/mathtype-tinymce5/tomoaudio.js"
        },
        plugins: [
          "advlist autolink lists link image charmap print preview anchor media ",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | styleselect |" +
          "bold italic underline backcolor |" +
          "alignleft aligncenter alignright alignjustify |" +
          "bullist numlist outdent indent removeformat |" +
          "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry image media audio help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

        setup: function (editor) {
          editor.ui.registry.addButton('audio', {
            tooltip: 'Chèn âm thanh',
            text: '<img src="data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiPgo8dGl0bGU+aGVhZHBob25lczwvdGl0bGU+CjxwYXRoIGQ9Ik00LjUgOWgtMXY3aDFjMC4yNzUgMCAwLjUtMC4yMjUgMC41LTAuNXYtNmMwLTAuMjc1LTAuMjI1LTAuNS0wLjUtMC41eiI+PC9wYXRoPgo8cGF0aCBkPSJNMTEuNSA5Yy0wLjI3NSAwLTAuNSAwLjIyNS0wLjUgMC41djZjMCAwLjI3NSAwLjIyNSAwLjUgMC41IDAuNWgxdi03aC0xeiI+PC9wYXRoPgo8cGF0aCBkPSJNMTYgOGMwLTQuNDE4LTMuNTgyLTgtOC04cy04IDMuNTgyLTggOGMwIDAuOTYgMC4xNjkgMS44OCAwLjQ3OSAyLjczMi0wLjMwNCAwLjUxOS0wLjQ3OSAxLjEyMy0wLjQ3OSAxLjc2OCAwIDEuNzYzIDEuMzA0IDMuMjIyIDMgMy40NjR2LTYuOTI4Yy0wLjQ5OSAwLjA3MS0wLjk2MyAwLjI0OC0xLjM3MSAwLjUwNi0wLjA4NC0wLjQxNy0wLjEyOS0wLjg0OS0wLjEyOS0xLjI5MiAwLTMuNTkgMi45MS02LjUgNi41LTYuNXM2LjUgMi45MSA2LjUgNi41YzAgMC40NDItMC4wNDQgMC44NzQtMC4xMjggMS4yOTItMC40MDgtMC4yNTktMC44NzMtMC40MzUtMS4zNzItMC41MDd2Ni45MjljMS42OTYtMC4yNDMgMy0xLjcwMSAzLTMuNDY0IDAtMC42NDUtMC4xNzUtMS4yNDktMC40NzktMS43NjggMC4zMS0wLjg1MyAwLjQ3OS0xLjc3MyAwLjQ3OS0yLjczMnoiPjwvcGF0aD4KPC9zdmc+Cg==">',
            icon: null,

            onAction: function () {
              inputRef.current.click()
              // editor.insertContent('&nbsp;<b>It\'s my button!</b>&nbsp;');
              // if (file) console.log(file);
            }
          });
        },
        images_upload_handler: async function (
          blobInfo: any,
          success: any,
          failure: any
        ) {
          let filesize = 0;
          let data = new FormData()
          data.append("type", "s3")
          data.append("name", "file")
          data.append("file", blobInfo.blob())
          data.append("filetype", blobInfo.blob().type)
          // console.log(imageFile);
          try {
            resizeFile(blobInfo.blob())
              .then((image) => {
                if (filesize > 3145728) {
                  confirmAlert({
                    title: 'Lỗi',
                    message: "File không được lớn hơn 3 Mb",
                    buttons: [{ label: 'OK' }]
                  })
                  return
                }
                axios.post(URL_LIST_ALL + "upload", data, user.config)
                  .then(async (res) => {
                    if (res.data.status === "success") {
                      success(res.data.url);
                    } else {
                      return;
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })
            //success(data.fileURL);
          } catch (error) {
            console.log(error);
            return;
          }

        }
      }
      }
    />
  </>
  );
}
