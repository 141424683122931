import React from "react";
import AkkhorLayout from "../../layout/akkhor";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import SRList from "../../component/List";
import Moment from "react-moment";

export default function HistoryLogin() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Người đăng nhập", options: { filter: true, sort: true } },
    { name: "datecreate",label: "Ngày đăng nhập",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  
  return (
    <AkkhorLayout idrole="internal.history-login">
      <SRList name="users_login" idrole="internal.history-login" params={params}  title="Lịch sử đăng nhập" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
