
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";
import TeacherOfSubject from "../component/TeacherOfSubject";

export default function ListSubjectsPage() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;
  const [refresh, setRefresh] = useState(false);
  const columns = [
    { name: "id", label: "ID",  options: { filter: false, sort: true }},
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true }},
    { name: "titlegrade", label: "Khối", options: { filter: true, sort: true }},
    { name: "numlessons", label: "Tổng số tiết",  options: { filter: true, sort: true } },
    { name: "numberteacher", label: "Số giáo viên",  options: { filter: true, sort: true } },
    { name: "lessionperweek", label: "Số tiết trong tuần",  options: { filter: true, sort: true } },
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    { name: "id",label: "Giáo viên",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <TeacherOfSubject id={value} row={tableMeta.tableData[tableMeta.rowIndex]} onRefresh={()=>setRefresh(!refresh)} />;
        },
      }
    }
  ];
  
  return (<AkkhorLayout  idrole="list-subjects"><SRList name="subjects"   idrole="list-subjects" refresh={refresh} params={params} title="Môn học" defaultLimit={50}  serverSide={true} columns={columns}></SRList></AkkhorLayout>);
}
