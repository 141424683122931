import React, { useState } from "react";

export default function QFillTo(props) {
    const {type, answers} = props;
    const [choice] = useState(answers.correct)
  
    return (<div className="col-12 d-flex mt-3 mb-3">
        {type === 'question' && <>
            {type === 'question' &&  answers.correct.map((item,i)=><input value={choice[i]||''} type="text" readOnly key={i} className={"form-control form-control-sm border mr-3"} style={{width:100}} />)}
        </>}
        {type === 'answer' && Object.entries(answers.correct).map((item,i)=><input value={choice[item[0]]||''} type="text" readOnly key={i} className={"form-control form-control-sm border mr-3"} style={{width:100}} />)}
    </div>);
}