import Axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from "../../layout/akkhor";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ItemCreateTimeTable from "./ItemCreateTimeTable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";


export default function CreateTimeTable() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const [datebegin, setdateBegin] = useState(new Date());
    const [listGardes, setListGardes] = useState([]);
    const [listClass, setListClass] = useState([]);
    const [listSubjects, setListSubjects] = useState([]);
    const [gradeChoice, setGradeChoice] = useState(null);
    const [classChoice, setClassChoice] = useState(null);
    const [content, setContent] = useState({});
    const [old, setOld] = useState(null);
    //Lấy danh sách khối
    useEffect(() => {
        let mounted = true;
        Axios.get(URL_LIST_ALL + "grades?orderby=title-ASC&page=1&limit=500&school_id=" + school_id, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                if (mounted) setListGardes(res.data.data);
                if (res.data.data && res.data.data.length > 0)
                    if (mounted) setGradeChoice(res.data.data[res.data.data.length - 1].id);
            } else console.log(res.data.message);
        }).catch(err => console.log(err));
        return () => (mounted = false);
    }, [school_id, user]);// eslint-disable-line
    //Lấy danh sách môn học
    useEffect(() => {
        let mounted = true;
        if (gradeChoice) {
            setListSubjects([]);
            Axios.get(URL_LIST_ALL + "subjects?page=1&limit=500&grade_id=" + gradeChoice, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListSubjects(res.data.data);
                } else console.log(res.data.message);
            }).catch(err => console.log(err));
        }
        return () => (mounted = false);
    }, [gradeChoice, user]);
    //Lấy danh sách lớp
    useEffect(() => {
        let mounted = true;
        if (gradeChoice) {
            setListClass([]);
            Axios.get(URL_LIST_ALL + "class?page=1&limit=500&grade_id=" + gradeChoice+ "&year_id=" + year_id, user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListClass(res.data.data);
                    if (res.data.data && res.data.data.length > 0)
                        if (mounted) setClassChoice(res.data.data[res.data.data.length - 1].id);
                } else console.log(res.data.message);
            }).catch(err => console.log(err));
        }
        return () => (mounted = false);
    }, [gradeChoice, user,year_id]);



    const listDay = ['Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu'];
    const listSess = ['Sáng', 'Chiều']
    const listIndexTime = [[
        { index: 1, time: '7h40-8h25', begin: '7:40', end: '8:25' },
        { index: 2, time: '8h30-9h15', begin: '8:30', end: '9:15' },
        { index: 3, time: '9h30-10h15', begin: '9:30', end: '10:15' },
        { index: 4, time: '10h20-11h05', begin: '10:20', end: '11:05' },
        { index: 5, time: '11h10-11h50', begin: '11:10', end: '11:50' }
    ], [
        { index: 6, time: '13h35-14h15', begin: '13:35', end: '14:15' },
        { index: 7, time: '14h20-15h00', begin: '14:20', end: '15:00' },
        { index: 8, time: '15h10-15h50', begin: '15:10', end: '15:50' },
        { index: 9, time: '15h55-16h35', begin: '15:55', end: '16:35' }
    ]];

    const onChoiceSubject = (e) => {
        let newContent = Object.assign({}, content);
        newContent['' + e.day + '-' + e.index] = e;
        //console.log(JSON.stringify(newContent));
        setContent(newContent);
    }

    const saveTimeTable = (e) => {
        const data = {
            year_id, school_id, grade_id: gradeChoice, class_id: classChoice, content: JSON.stringify(content), dateapply: moment(datebegin).format('YYYY-MM-DD'), isrun: 0
        }
        Axios.post(URL_LIST_ALL + "timetable_temp", data, user.config).then((res) => {
            if (res.data.status === "success") {
                confirmAlert({
                    title: 'Thành công',
                    message: 'Bạn cập tạo thời khoá biểu thành công',
                    buttons: [{ label: 'OK' }]
                });
            } else {
                confirmAlert({
                    title: 'Lỗi',
                    message: res.data.message,
                    buttons: [{ label: 'OK' }]
                });
            }
        }).catch(err => console.log(err));
    }

    const openOld = () => {
        setOld(null);
        Axios.get(URL_LIST_ALL + "timetable?page=1&limit=500&week=35&grade_id=" + gradeChoice + '&orderby=date-asc_orderonday-asc', user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                const list = res.data.data;
                let newList = [[], [], [], [], []];
                let newContent = {}
                list.map((item) => {
                    const localDate = new Date(item.timebegin * 1000);
                    const index = localDate.getDay() - 1;
                    newList[index][item.orderonday] = item;
                    newContent[index + '-' + item.orderonday] = { day: index, index: item.orderonday, subject_id: item.subject_id, teacher_id: item.teacher_id, time: item.time }
                    return item;
                })
                setContent(newContent);
                setOld(newList);
            } else console.log(res.data.message);
        }).catch(err => console.log(err));
    }


    return (
        <AkkhorLayout idrole="setting.import-data">
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Tạo thời khoá biểu</h3>
                        </div>
                    </div>
                    <div className="form-inline">
                        <label className="mr-2">Khối</label>
                        <select className="form-control form-control-sm" value={gradeChoice ? gradeChoice : 0} onChange={(e) => setGradeChoice(e.target.value)}>
                            {listGardes.map((item) => {
                                return <option key={item.id} value={item.id}>{item.title}</option>
                            })}
                        </select>
                        <label className="mr-2 ml-md-4">Lớp học</label>
                        <select className="form-control form-control-sm" value={classChoice ? classChoice : 0} onChange={(e) => setClassChoice(e.target.value)}>
                            {listClass.map((item) => {
                                return <option key={item.id} value={item.id}>{item.title}</option>
                            })}
                        </select>
                        <label className="mr-2 ml-md-4">Ngày áp dụng</label>
                        <DatePicker className="form-control form-control-sm" selected={datebegin} onChange={setdateBegin} />
                        <button className="btn btn-sm btn-success ml-3" onClick={saveTimeTable} >Tạo ngay</button>
                        <button className="btn btn-sm btn-warning ml-3" onClick={openOld} >Tải lịch mới nhất</button>
                    </div>
                    <div className="d-flex mt-4">
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 100 }}>
                            <div className="text-center w-100">Ngày</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 60 }}>
                            <div className="text-center w-100">Buổi</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 50 }}>
                            <div className="text-center w-100">Tiết</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 250 }}>
                            <div className="text-center w-100">Thời gian</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 200 }}>
                            <div className="text-center w-100">Môn học</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 250 }}>
                            <div className="text-center w-100">Giáo viên 1</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 250 }}>
                            <div className="text-center w-100">Giáo viên 2</div>
                        </div>
                    </div>
                    {listDay.map((item, i) => <div key={i} className="d-flex">
                        <div className="d-flex border p-2 align-items-center " style={{ minWidth: 100 }}>
                            <div className="text-center w-100">{item}</div>
                        </div>
                        <div>
                            {listSess.map((sess, j) => <div key={j} className="d-flex">
                                <div className="d-flex border p-2 align-items-center" style={{ minWidth: 60 }}><div className="text-center w-100">{sess}</div></div>
                                <div>
                                    {listIndexTime[j].map((time, j) => <div key={j} className="d-flex">
                                        <ItemCreateTimeTable day={i} item={time} classChoice={classChoice} listSubjects={listSubjects} onChoiceSubject={onChoiceSubject} old={old && old[i].length > 0 ? old[i][time.index] : null} />
                                    </div>)}
                                </div>
                            </div>)}
                        </div>
                    </div>)}
                </div>
            </div>
        </AkkhorLayout>
    );
}
