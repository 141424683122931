import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/api';
import Moment from "react-moment";

export default function ExaminationPage() {
    const { user } = useSelector((state) => state.auth);
    //const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const [list, setList] = useState([]);
    const now = (new Date()).getTime() / 1000;
    useEffect(() => {
        let mounted = true;
        let url = URL_LIST_ALL + "at_exam_students?join=1&orderby=id-DESC&limit=60&checked=1"
        Axios.get(url, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) setList(res.data.data);
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user]);

    const statusQues = (_test) => {
        if (_test.status_action > 0){
            if(_test.type === 3){
                return <Link to={"/examination-azota-view/" + _test.id}  > <div className="bg-info px-2 py-1 text-light text-center"><b>Xem kết quả</b></div></Link>
            }
            return <Link to={"/examination-view/" + _test.id}  > <div className="bg-info px-2 py-1 text-light text-center"><b>Xem kết quả</b></div></Link>
        }else {
            let dateend = _test.studateend ? _test.studateend : _test.dateend

            if (now >= _test.datebegin && now <= dateend) {
                if(_test.type === 3){
                    return <Link to={"/examination-detail-azota/" + _test.id}  > <div className="bg-success px-2 py-1 text-light text-center"><b>Vào làm</b></div></Link>
                }
                return <Link to={"/examination-detail/" + _test.id}  > <div className="bg-success px-2 py-1 text-light text-center"><b>Vào làm</b></div></Link>
            } else {
                if (now > dateend) return <div className="bg-danger px-2 py-1 text-light text-center"><b>Đã quá hạn</b></div>
                else return <div className="bg-warning px-2 py-1 text-light text-center"><b>Chưa đến hạn</b></div>
            }
        }

    }
    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
            <div className="breadcrumbs-area py-2">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Khảo thí</li>
                </ul>
            </div>
            {user && <div className="card height-auto">
                <div className="card-body">
                    {list.length > 0 && <>
                        <div className="row">
                            {list.map((item, i) => <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={i}>

                                <div className="border rounded shadow mb-3 overflow-hidden">
                                    <div className="p-2 ">
                                        <div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.title}</div>
                                        <span className="mr-1">Từ <Moment format="DD/MM">{new Date(item.datebegin * 1000)}</Moment></span>
                                        <span>đến <Moment format="DD/MM">{item.studateend ? new Date(item.studateend * 1000) : new Date(item.dateend * 1000)}</Moment></span>
                                        <div>Có {item.number} câu hỏi</div>
                                        <div style={{ fontSize: '1.5em' }} className="border-top mt-2 pt-2">{item.subjecttitle}</div>
                                    </div>
                                    {statusQues(item)}
                                </div>
                            </div>)}
                        </div>
                    </>}
                </div>
            </div>}
        </AkkhorLayout>
    );
}
