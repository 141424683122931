import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
// import { checkRole } from "../model";
import SelectSearch from "react-select-search";
import Spinner from "./Spinner";

export default function AsignMarkTest(props) {
  const { index, listClass, classId, listTeacher } = props;
  const { user } = useSelector((state) => state.auth);
  const [teacherChoice, setTeacherChoice] = useState({});
  const { year_id } = useSelector((state) => state.save);
  const [show, setShow] = useState(false);
  const [teachersCheck, setTeachersCheck] = useState([]);
  const [spinder, setSpinder] = useState(false);
  const { loading } = useState(listTeacher.length > 0 ? true : false);

  const list = JSON.parse(listClass);
  const saveAssign = () => {
    if (teachersCheck) {
      Axios.post(URL_LIST_ALL + "exam_tests_check", { data: teachersCheck }, user.config)
        .then((res) => {
          if (res.data.status === "success") {
            alert('Lưu thành công');
          } else alert(res.data.message)
        }).catch((err) => console.log(err));
    }
  }
  useEffect(() => {
    if (show) {
      setSpinder(true)
      Axios.get(URL_LIST_ALL + "exam_tests_check?test_id=" + classId, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          const rsData = res.data.data
          if (rsData.length > 0) {
            let new_rsdata = {}
            rsData.forEach(item => {
              const classId = item.class_id
              new_rsdata[classId + ""] = item
            });
            setTeacherChoice(new_rsdata)
            //console.log(new_rsdata);
          }
        } else console.log(res.data.message);
        setSpinder(false)
      }).catch((err) => {console.log(err);setSpinder(false)});
    }

  }, [user, year_id, classId,show]);

  // const allowAll = checkRole(user, 'teaching', 'all');
  // console.log(JSON.parse(user.data.roles))
  const selectTeacherCheck = (classid, userid) => {
    const postValue = { class_id: classid, user_id: userid, test_id: classId };
    let listTeach = teachersCheck.slice();
    if (!!listTeach) {
      listTeach.push(postValue)
      setTeachersCheck(listTeach)
    }
  }

  return (
    <>
      <a href={"#class" + index} className="text-info" onClick={() => setShow(true)}>Phân công chấm lại</a>
      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Phân công chấm bài</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinder ?
            <div className="d-flex justify-content-center"><Spinner /></div>
            :
            <ul>
              {list.map((item, index) => {
                const class_id = item.value

                let defaul_id = user.data.id
                if (!!teacherChoice) {
                  for (var k in teacherChoice) {
                    if (class_id === teacherChoice[k].class_id) {
                      defaul_id = teacherChoice[k].user_id
                      break
                    }
                  }
                }

                return <li key={index}>
                  <div className="d-flex justify-content-start align-items-center row p-2">
                    <div className="col-2">Lớp {item.label}</div>
                    <form className="form-inline p-2">
                      {/* {allowAll && <> */}
                      <div className="border rounded mr-md-4">
                        <SelectSearch disabled={loading} value={defaul_id} search options={listTeacher} placeholder="Chọn giáo viên" onChange={async (e) => { selectTeacherCheck(class_id, e) }} />
                      </div>
                      {/* </>} */}
                    </form>
                  </div>
                </li>
              })}
            </ul>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Đóng</Button>
          <Button variant="success" onClick={saveAssign}>Lưu</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
