import Axios from "axios";
import AkkhorLayout from '../../layout/akkhor';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/api";
import ViewQuestion from "../questions/ViewQuestion";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import moment from 'moment'

export default function DetailExamView(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [Answers, setAnswers] = useState(null);

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          setDetail(_detail)
          let info = _detail.info
          let listCorrect = []

          info.forEach(item => {
            let _corrects = item.corrects
            if (item.code === "QMatching") {
              let _x = [];
              _corrects.map((item) => {
                _x[item.right] = item.label;
                return item;
              })
              _corrects.corrects = _x;
            }
            item.corrects = _corrects
            listCorrect.push(item)
          });
          setAnswers(listCorrect);
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id]);

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {(detail && Answers) ? (<div className="container-fluid">
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>{detail.type === 1 ? "Phiếu bài tập" : "Bài kiểm tra"}</li>
          </ul>
        </div>
        <div className="card height-auto">
          <div className="card-body">
            <div>
              <h2 className="text-center mb-3">{detail.title}</h2>
              <div className="row">
                <div className="col-sm-4 d-flex">
                  <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.correct_number}/{detail.total_number}</div>
                    <div>Số câu trả lời đúng</div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.point > 10 ? 10 : Math.round(detail.point * 10) / 10}</div>
                    <div>Điểm đạt được</div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div className="text-info" style={{ fontSize: 50 }}><i className="far fa-clock"></i></div>
                  <div className="py-1 px-2">
                    <div style={{ fontSize: 25, fontWeight: 'bold' }}>{moment.unix(detail.time).utc().format(detail.time > 3600 ? 'HH:mm:ss' : 'm:ss')}</div>
                    <div>Thời gian làm bài</div></div>
                </div>
              </div>

              {Answers && Object.values(Answers).map((ans, index) => (
                <div key={index} className="mb-2 border rounded overflow-hidden " id={"nuques-" + index}>
                  <div>
                    <div className={"font-weight-bold p-2 " + (ans.id && ans.percent === 100 ? 'bg-info' : 'bg-danger') + " text-light d-flex justify-content-between"}>
                      <div>Câu hỏi {index + 1}</div>
                      <div className="">
                        {ans.point && ans.percent === 100 ? <> {Math.round(ans.point * 10) / 10} điểm </> : <> 0 điểm </>}
                      </div>
                    </div>
                    {ans.parent_title && <div className="p-2 border-bottom">{parse(ans.parent_title)}</div>}
                    <div className="p-3 border-bottom mb-3">{parse(ans.title)}</div>
                    <div>
                      <ViewQuestion item={ans}></ViewQuestion>
                    </div>
                    {ans.comments &&
                      <div className="comment border-top p-2 mt-3">
                        <span>Nhận xét từ giáo viên</span>
                        <div className="w-100 mt-2 d-flex">
                          <strong>{ans.teacher_comment}</strong>: {parse(ans.comments+'')}
                        </div>
                      </div>
                    }

                  </div>
                </div>)
              )}
            </div>
          </div>
        </div>
      </div>
      ) : (<>
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      </>)}
    </AkkhorLayout>
  );
}
