import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../redux/authRedux";
import { saveYear, saveListYear, resetRedux } from "../redux/Actions";
import onClickOutside from "react-onclickoutside";
import { TYPE_ACCOUNT } from "../configs";
import UserDropDown from "./UserDropDown";
import Logo from "../assets/img/tomo.png";
import Avatar from "../assets/img/figure/admin.jpg";


function HeaderBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { year_id, listyear } = useSelector((state) => state.save);
  const [showMobile, setShowMobile] = useState(false);
  const [show, setShow] = useState(false);
  const info = user && user.data ? user.data : null;
  const school = user && user.data && user.data.school ? user.data.school : null;
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;

  //Hàm xử lý đăng xuất
  const dologout = () => {
    confirmAlert({
      title: "Đăng xuất",
      message: "Bạn có chắc muốn đăng xuất",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Đăng xuất",
          onClick: () => {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          },
        },
        { label: "Không" },
      ],
    });
  };
  HeaderBar["handleClickOutside_header"] = () => setShow(false);
  return (
    <>
      {/* Header Menu Area Start Here */}
      <div className="navbar navbar-expand-md header-menu-one bg-light">
        <div className="nav-bar-header-one">
          <div className="header-logo">
            <Link to="/dashboard">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="toggle-button sidebar-toggle">
            <button type="button" className="item-link">
              <span className="btn-icon-wrap">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
        <div className="d-md-none mobile-nav-bar">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setShowMobile(!showMobile)}
          >
            <i className="fas fa-user-alt"></i>
          </button>
          <button
            type="button"
            className="navbar-toggler sidebar-toggle-mobile"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div
          className={`header-main-menu collapse navbar-collapse ${showMobile ? "show" : ""}`}
          id="mobile-navbar"
        >
          <ul className="navbar-nav">
            <li className="navbar-item header-search-bar">{school ? school.title : ''}</li>
            <li className="navbar-item header-search-bar">
              <UserDropDown data={listyear} apiname={"years?orderby=id-DESC&school_id=" + school_id} defaultValue={year_id} autoSelect={true} placeholder="Chọn năm học" colvalue={'years.id'} colname={'years.title'} name='year_id'
                onChange={(e) => {
                  dispatch(saveYear(e));
                }}
                onDataList={(e) => {
                  dispatch(saveListYear(e));
                }}
              ></UserDropDown>
            </li>
          </ul>
          {info && (
            <ul className="navbar-nav">
              <li
                className={`navbar-item dropdown header-admin ${show ? "show" : ""
                  }`}
              >
                <a
                  className="navbar-nav-link dropdown-toggle"
                  href="#shool"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setShow(!show)}
                >
                  <div className="admin-title">
                    <h5 className="item-title">
                      {info.firstname} {info.lastname}{" "}
                    </h5>
                    <span>{TYPE_ACCOUNT[info.typeaccount]}</span>
                  </div>
                  <div className="admin-img">
                    <img
                      src={Avatar}
                      alt="Admin"
                    />
                  </div>
                </a>
                <div
                  className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""
                    }`}
                >
                  <div className="item-header">
                    <h6 className="item-title">
                      {info.firstname} {info.lastname}{" "}
                    </h6>
                  </div>
                  <div className="item-content">
                    <ul className="settings-list">
                      <li>
                        <Link to="/my-profile">
                          <i className="flaticon-user"></i>Hồ sơ của tôi
                        </Link>
                      </li>
                      <li>
                        <a href="#logout" onClick={dologout}>
                          <i className="flaticon-turn-off"></i>Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
      {/* Header Menu Area End Here */}
    </>
  );
}
export default onClickOutside(HeaderBar, {
  handleClickOutside: () => HeaderBar["handleClickOutside_header"],
});
