import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { URL_LIST_ALL } from '../../configs/api';
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from 'react-redux';

export default function ItemTeaching(props) {
    const { i, timeTable } = props;
    const { user } = useSelector((state) => state.auth);
    const ValidSchema = Yup.object().shape({
        orderonsubject: Yup.string().trim().nullable().required('Vui lòng nhập thứ tự tiết học trong kỳ'),
        name: Yup.string().trim().nullable().required('Vui lòng nhập tên bài dạy')
    });

    let defaultValue = { name: timeTable.name + '' === 'null' ? '' : timeTable.name, notes: timeTable.notes + '' === 'null' ? '' : timeTable.notes, orderonsubject: timeTable ? timeTable.orderonsubject : '' }
    const formik = useFormik({
        initialValues: defaultValue,
        validationSchema: ValidSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false)
            Axios.put(URL_LIST_ALL + "timetable/" + (timeTable ? timeTable.id : 0), values, user.config).then((res) => {
                if (res.data.status === 'success') {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật lịch báo giảng thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }

            })
        }
    });

    return (
        <form className="d-flex" method="post" onSubmit={formik.handleSubmit}>
            <div className="border p-1 align-items-center text-center" style={{ minWidth: 50 }}>{i}</div>
            <div className="border p-1 align-items-center" style={{ minWidth: 90 }}>{timeTable.classtitle}</div>
            <div className="border p-1 align-items-center" style={{ minWidth: 145 }}>{timeTable.title}</div>
            {(user.data.id === timeTable.teacher_id || user.data.id === timeTable.teacher_id2) ? <>
                <div className="border p-1 align-items-center" style={{ width: 70 }}>
                    {timeTable.classtitle && <input type="text" className={`form-control  form-control-sm ${formik.touched.orderonsubject && formik.errors.orderonsubject ? 'border-danger' : ''}`}  {...formik.getFieldProps("orderonsubject")} autoComplete="off" />}
                </div>
                <div className="border p-1 align-items-center" style={{ minWidth: 180 }}>
                    {timeTable.classtitle && <input type="text" className={`form-control form-control-sm ${formik.touched.name && formik.errors.name ? 'border-danger' : ''}`}  {...formik.getFieldProps("name")} autoComplete="off" />}
                </div>
                <div className="border p-1 align-items-center" style={{ minWidth: 180 }}>
                    {timeTable.classtitle && <input type="text" className="form-control form-control-sm" {...formik.getFieldProps("notes")} autoComplete="off" />}
                </div>
                <div className="border p-1 align-items-center" style={{ minWidth: 70 }}>
                    {timeTable.classtitle && <button type="submit" className="btn btn-sm btn-success"><i className="fas fa-save"></i> Lưu</button>}
                </div>
            </> : <>
                <div className="border p-1 align-items-center" style={{ width: 70 }}>{timeTable.orderonsubject}</div>
                <div className="border p-1 align-items-center" style={{ minWidth: 200 }}>{timeTable.name}</div>
                <div className="border p-1 align-items-center" style={{ minWidth: 200 }}>{timeTable.notes} </div>
                <div className="border p-1 align-items-center" style={{ minWidth: 70 }}></div>

            </>}

        </form>
    );
}
