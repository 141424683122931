import React from "react";
import parse from 'html-react-parser'

export default function QDropDrag(props) {
    const {answers} = props;
    
    return (<div className="col-12 form-group mt-3">
        {answers && <>
            {answers.correct.map((item,i)=><button key={i} className={"mb-2 btn btn-sm mr-3 btn-secondary"} >{parse(item+'')}</button>)}
        </>}
    </div>);
}