import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from "../../layout/akkhor";
import { CSVLink } from "react-csv";
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import SpinnerCenter from "../../component/SpinnerCenter";

export default function ReportLunchPage() {
  const { user } = useSelector((state) => state.auth);
  const [dateValue, setDateValue] = useState(new Date());
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [mdata, setMdata] = useState(null);
  const [list, setList] = useState([]);
  const [loadGrade, setLoadGrade] = useState(false);
  const [loadClass, setLoadClass] = useState(false);
  useEffect(
    function () {
      let mounted = true;
      if (mounted) {
        setMdata(null);
        setList([]);
        setLoadGrade(true)
        setLoadClass(true)
      }
      Axios.get(URL_LIST_ALL + "report_lunch_grade/1?date=" + date, user.config)
        .then((res) => {
          setLoadGrade(false)
          if (res.data.status === "success") {
            if (mounted) {
              setMdata(res.data.data);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => {
          setLoadGrade(false);
          console.log(err)
        });


      Axios.get(URL_LIST_ALL + "report_lunch_class/1?date=" + date, user.config)
        .then((res) => {
          setLoadClass(false)
          if (res.data.status === "success") {
            if (mounted) {
              let mylist = res.data.data;
              mylist.sort((a, b) => (a.title > b.title))
              setList(mylist);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => {
          setLoadClass(false);
          console.log(err)
        });

      return () => (mounted = false);
    },
    [user, date]
  );


  const RunAgain=()=>{
    Axios.get(URL_LIST_ALL + "report_lunch_run/1?date=" + date, user.config)
    alert('Bạn đã gửi yêu cầu cập nhật lại báo cáo. Hệ thống cần mất một chút thời gian để tổng hợp. Bạn có thể quay lại sau để xem báo cáo mới')
  }

  const filterDataExport = () => {
    let array = [];
    let total_present = 0;
    let total_nopresent = 0;
    let total_lunch = 0;
    let total_nolunch = 0;
    mdata.listGrades.map((item) => {
      total_present += item.total_present;
      total_nopresent += item.total_nopresent;
      total_lunch += item.total_lunch;
      total_nolunch += item.total_nolunch;
      array.push({
        'TT': '',
        'Họ và tên giáo viên': '',
        'Lớp': item.title,
        'Số HS có mặt': item.total_present,
        'Số HS vắng': item.total_nopresent,
        'Tên HS vắng': item.nopresent_fullname,
        'Lý do': item.nopresent_notes,
        'Số HS ăn trưa': item.total_lunch,
        'Số HS không ăn': item.total_nolunch,
        'Tên HS không ăn': item.nolunch_fullname,
        'Ghi chú': ''
      });
      return item
    })
    array.push({
      'TT': '',
      'Họ và tên giáo viên': '',
      'Lớp': 'Tổng',
      'Số HS có mặt': total_present,
      'Số HS vắng': total_nopresent,
      'Tên HS vắng': '',
      'Lý do': '',
      'Số HS ăn trưa': total_lunch,
      'Số HS không ăn': total_nolunch,
      'Tên HS không ăn': '',
      'Ghi chú': ''
    });

    list.map((item, index) => {
      array.push({
        'TT': index + 1,
        'Họ và tên giáo viên': item.fullname,
        'Lớp': item.title,
        'Số HS có mặt': item.total_present,
        'Số HS vắng': item.total_nopresent,
        'Tên HS vắng': item.nopresent_fullname,
        'Lý do': item.nopresent_notes,
        'Số HS ăn trưa': item.total_lunch,
        'Số HS không ăn': item.total_nolunch,
        'Tên HS không ăn': item.nolunch_fullname,
        'Ghi chú': item.notes
      });
      return item
    })
    return array
  }


  let total_present = 0;
  let total_nopresent = 0;
  let total_lunch = 0;
  let total_nolunch = 0;

  const handleDateChange = async (date) => {
    setDateValue(date);
    setDate(moment(date).format('YYYY-MM-DD'));
  };
  return (
    <AkkhorLayout idrole="report.report-lunch">
      <div className="card height-auto mt-4">
        <div className="card-body">
          <div className="heading-layout1 d-flex justify-content-between w-100">
            
            {mdata !== null && list.length > 0 && (<CSVLink
              data={filterDataExport()}
              filename={"Diem-Danh-An-Trua-" + moment().format('DD-MM-YYYY') + ".csv"}
              className="btn"
              target="_blank"
            >
              Tải xuống <span className="fas fa-download text-info ml-2"></span>
            </CSVLink>)}

            <button className="btn btn-sm btn-warning" onClick={RunAgain}>Tạo lại báo cáo</button>
          </div>
          <div>
            <label className="p-1">Chọn ngày</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker autoOk={true} variant="inline" value={dateValue} format="dd/MM/yyyy"
                onChange={handleDateChange} />
            </MuiPickersUtilsProvider>
          </div>
          <div className="item-title">
              <h4>Báo cáo đăng ký ăn trưa</h4>
            </div>

            
          {loadGrade?<SpinnerCenter  title="Đang tải khối"/>:<>
          {mdata !== null && (
            <>

              <div className="row">
                <div className="col-sm-6">
                  <h1 className="mt-3">
                    Học sinh:{" "}
                    <span className="text-success">{mdata.total_students}</span>/
                    {mdata.all_students}
                  </h1>
                </div>
                <div className="col-sm-6">
                  <h1 className="mt-3">
                    Giáo viên:{" "}
                    <span className="text-primary">{mdata.total_teacher}</span>/
                    {mdata.all_teacher}
                  </h1>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-12"><h5 className="mb-2">Danh sách khối</h5>
                  <table className="table table-sm table-hover table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Tên khối</th>
                        <th>Số HS có mặt</th>
                        <th>Số HS vắng</th>
                        <th>Số HS ăn trưa</th>
                        <th>Số HS không ăn</th>
                      </tr>
                    </thead>
                    <tbody>

                      {mdata.listGrades.map((item, index) => {
                        total_present += item.total_present;
                        total_nopresent += item.total_nopresent;
                        total_lunch += item.total_lunch;
                        total_nolunch += item.total_nolunch;
                        return <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.total_present}</td>
                          <td>{item.total_nopresent}</td>
                          <td>{item.total_lunch}</td>
                          <td>{item.total_nolunch}</td>
                        </tr>
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th></th>
                        <th>Tổng</th>
                        <th>{total_present}</th>
                        <th>{total_nopresent}</th>
                        <th>{total_lunch}</th>
                        <th>{total_nolunch}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </>
          )}
          </>}
          {loadClass?<SpinnerCenter  title="Đang tải lớp"/>:<>
          {list && list.length > 0 ? (<>
            <h5 className="mb-2">Danh sách lớp</h5>
            <table className="table table-sm table-hover table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tên Lớp</th>
                  <th>Số HS có mặt</th>
                  <th>Số HS vắng</th>
                  <th>Tên HS vắng</th>
                  <th>Lý do</th>
                  <th>Số HS ăn trưa</th>
                  <th>Số HS không ăn</th>
                  <th>Tên HS không ăn</th>
                  <th>Ghi chú</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.total_present}</td>
                  <td>{item.total_nopresent}</td>
                  <td>{item.total_nopresent > 0 ? item.nopresent_fullname : ''}</td>
                  <td>{item.total_nopresent > 0 ? item.nopresent_notes : ''}</td>
                  <td>{item.total_lunch}</td>
                  <td>{item.total_nolunch}</td>
                  <td>{item.total_nolunch > 0 ? item.nolunch_fullname : ''}</td>
                  <td>{item.notes}</td>
                </tr>
                )}
              </tbody>
            </table></>):<div className="text-center text-secondary"><i>Dữ liệu lớp không có</i></div>}
          </>}
        </div>
      </div>
    </AkkhorLayout>
  );
}
