import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/api';
import Moment from "react-moment";
import UserDropDown from '../../component/UserDropDown';

export default function ReportExam() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { year_id } = useSelector((state) => state.save);
    const [grade_id, setGrade_id] = useState('all');
    const [list, setList] = useState([]);
    const now = (new Date()).getTime() / 1000;
    
    useEffect(() => {
        let mounted = true;
        let url = URL_LIST_ALL + "at_exam_tests?report=1&join=1&orderby=id-DESC&limit=60&year_id=" + year_id
        if (grade_id && grade_id !== 'all') url += "&grade_id=" + grade_id;
        Axios.get(url, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) setList(res.data.data);
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user, year_id, grade_id]);

    const countNumQues = (_test) => {
        if (_test.hasOwnProperty('questions')) {
            const _ques = JSON.parse(_test.questions);
            const _listQues = Object.values(_ques);
            let number = 0;
            _listQues.map((item) => {
                number += item.list.length;
                return item;
            });
            return number;
        } return 0;
    }

    const statusQues = (_test) => {
        const link = "/examination-report-detail/" + _test.id;
        if (now >= _test.datebegin && now <= _test.dateend) {
            return <div className="bg-success p-2 text-light text-center"><b>Trong hạn</b></div>
        } else {
            if (now > _test.dateend) return <Link to={link} ><div className="bg-danger p-2 text-light text-center"><b>Hết hạn</b></div></Link>
            else return <div className="bg-info p-2 text-light text-center"><b>Chưa đến hạn</b></div>
        }

    }
    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>
            <div className="breadcrumbs-area py-2">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>Thống kê</li>
                </ul>
            </div>
            {user && <div className="card height-auto">
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                            <label>Khối</label>
                            <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Tất cả khối" colvalue={"grades.id"} colname={"grades.title"} name="grade_id"
                                onChange={setGrade_id} all={true}
                            ></UserDropDown>
                        </div>
                    </div>
                    {list.length > 0 && <>
                        <div className="row">
                            {list.map((item, i) => {
                                let listTitleClass = [];
                                if (item.listclass) {
                                    const listclass = JSON.parse(item.listclass);
                                    listclass.map((_class => listTitleClass.push(_class.label)))
                                }
                                const link_student = "/examination-report-detail/" + item.id;
                                const link_question = "/examination-report-question/" + item.id;
                                return <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={i}>
                                    <div className="border rounded shadow mb-3 overflow-hidden">
                                        <div className="p-2 ">
                                            <div style={{ fontSize: 16, fontWeight: 'bold' }}>{item.title}</div>
                                            Từ <Moment format="DD/MM">{new Date(item.datebegin * 1000)}</Moment>
                                            đến <Moment format="DD/MM">{new Date(item.dateend * 1000)}</Moment>
                                            <div>Có {countNumQues(item) ? countNumQues(item) : item.number} câu hỏi</div>
                                            <div style={{ fontSize: '1.5em' }} className="border-top border-bottom my-2">{item.gradetitle} - {item.subjecttitle}</div>
                                            {listTitleClass && listTitleClass.length > 0 && <div className="border-bottom my-2"><b>Lớp:</b> {listTitleClass.join(', ')}</div>}
                                            <div className="d-flex justify-content-between">Tổng học sinh được giao: <b>{item.total_students}</b></div>
                                            <div className="d-flex justify-content-between">Học sinh đã làm bài: <b>{item.total_finish}</b></div>
                                            <div className="d-flex justify-content-between">Học sinh chưa làm bài: <b>{item.total_unfinish}</b></div>
                                        </div>
                                        <div className="button-group d-flex justify-content-between p-2 ">
                                            {item.type !== 3 && <Link to={link_question} className="btn btn-sm btn-warning">Thống kê theo câu hỏi</Link>}
                                            <Link to={link_student} className="btn btn-sm btn-primary">Thống kê theo học sinh</Link>
                                        </div>
                                        {statusQues(item)}
                                    </div>
                                </div>
                            })}
                        </div>
                    </>}
                </div>
            </div>}
        </AkkhorLayout>
    );
}
