import Axios from "axios";
import AkkhorLayout from '../../layout/akkhor';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/api";
import { Link } from 'react-router-dom';
import moment from 'moment'
import { confirmAlert } from "react-confirm-alert";
import { Modal, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function MarkTestViewAzota(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [modalTestReset, setModalTestReset] = useState(false);
  const [markPoint, setMarkPoint] = useState(0);
  const [dataEdit, setDataEdit] = useState({ type: '', data: [] });
  const [dateBegin, setDateBegin] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);
  const [studentAnswer, setstudentAnswer] = useState(null);
  const inputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState([]);

  const handleCallback = (start, end, label) => {
    setDateBegin(start.format('DD-MM-yyyy HH:mm'))
    setDateEnd(end.format('DD-MM-yyyy HH:mm'))
  }

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students_azota/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          setstudentAnswer(_detail.info.content ? JSON.parse(_detail.info.content) : null)
          setFileUpload(_detail.comments)
          setDetail(_detail)
        }
        else console.log(res.data.message);
      }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id, reload]);

  const marktestActionTotal = () => {
    if (dataEdit.type === 'setPoint') {
      const data_save = { type: 'total_point', point: markPoint };
      Axios.put(URL_LIST_ALL + "at_exam_students/" + id, data_save, user.config).then(res => {
        // console.log(res);
        if (res.data.status === 'success') {
          setReload(!reload)
          confirmAlert({
            title: 'Thành công', message: 'Bạn đã sửa thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
        }
        else alert(res.data.message)
      }).catch(err => {
        console.log(err)
      });
    }
  }

  const marktestRefresh = () => {
    if (dateBegin && dateEnd) {
      const data_save = { status: 0, dateend: dateEnd };
      Axios.put(URL_LIST_ALL + "at_exam_students/" + id, data_save, user.config).then(res => {
        console.log(res);
        if (res.data.status === 'success') {
          confirmAlert({
            title: 'Thành công', message: 'Cập nhập thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
          setReload(!reload)
        }
        else alert(res.data.message)

      }).catch(err => {
        console.log(err)
      });
      setModalTestReset(false)
    }

  }

  const uploadFile = async (event) => {

    const file = event.target.files[0]
    if (file) {
      let data = new FormData()
      data.append("type", "s3")
      data.append("name", "file")
      data.append("file", file)
      data.append("filetype", file.type)
      let file_size = file.size;
      if (file_size > 3145728) {
        confirmAlert({
          title: 'Lỗi',
          message: "File không được lớn hơn 3 Mb",
          buttons: [{ label: 'OK' }]
        })
        return
      } else {
        Axios.post(URL_LIST_ALL + "upload", data, user.config).then(async (res) => {
          if (res.data.status === "success") {
            let newList = fileUpload.slice();
            newList.push({ url: res.data.url, type: res.data.type })
            setFileUpload(newList)

          } else {
            return;
          }
        }).catch((err) => { console.log(err) })
      }

    }
  }

  const removeFile = async (url) => {
    let newList = []
    fileUpload.map(function (item) {
      if (item.url !== url) {
        newList.push(item)
      }
      return item
    })
    if (newList) {
      setFileUpload(newList)
    }
  }

  const saveMarkPoint = () => {
    if(fileUpload){
      const data_save = { comments: JSON.stringify(fileUpload) };
      Axios.put(URL_LIST_ALL + "at_exam_students/" + id, data_save, user.config).then(res => {
        // console.log(res);
        if (res.data.status === 'success') {
          setReload(!reload)
          confirmAlert({
            title: 'Thành công', message: 'Bạn đã sửa thành công', buttons: [{ label: 'OK' }]
          });
          setShow(false)
        }
        else alert(res.data.message)
      }).catch(err => {
        console.log(err)
      });
    }
  }
  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {detail ? (
        <div className="container-fluid">
          <div className="breadcrumbs-area py-3">
            <ul>
              <li>
                <Link to="/">Trang chủ</Link>
              </li>
              <li>Chấm điểm {detail.type === 1 ? "Phiếu bài tập" : "Bài kiểm tra"}</li>
            </ul>
          </div>
          <div className="card height-auto">
            <div className="card-body">
              <div>
                <h2 className="text-center mb-3">{detail.title}</h2>
                <div className="row">
                  <div className="col-12"><strong>Họ tên:</strong> {detail.student_info && detail.student_info.fullname}</div>
                  <div className="col-12"><strong>Mã số:</strong> {detail.student_info && detail.student_info.code}</div>
                  <div className="col-12"><strong>Lớp:</strong> {detail.student_info && detail.student_info.class}</div>
                </div>
                <div className="row">
                  <div className="col-sm-3 d-flex">
                    <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                    <div className="py-1 px-2">
                      <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.author_mark ? "Đã chấm" : "Chưa chấm"}</div>
                      <div>Trạng thái chấm bài</div>
                    </div>
                  </div>
                  <div className="col-sm-3 d-flex">
                    <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                    <div className="py-1 px-2">
                      <div className="cursor-pointer rounded" style={{ fontSize: 25, fontWeight: 'bold' }} onClick={() => { setDataEdit({ type: 'setPoint', data: {} }); setTimeout(setShow(true), 200); setMarkPoint(0) }}>
                        {detail.point > 10 ? 10 : Math.round(detail.point * 10) / 10}
                      </div>
                      <div>Điểm đạt được</div>
                      <div className="btn btn-sm btn-info cursor-pointer rounded" onClick={() => { setDataEdit({ type: 'setPoint', data: {} }); setTimeout(setShow(true), 200); setMarkPoint(0) }}>
                        Sửa tổng điểm
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 d-flex">
                    <div className="text-info" style={{ fontSize: 50 }}><i className="far fa-clock"></i></div>
                    <div className="py-1 px-2">
                      <div style={{ fontSize: 25, fontWeight: 'bold' }}>{moment.unix(detail.time).utc().format(detail.time > 3600 ? 'HH:mm:ss' : 'm:ss')}</div>
                      <div>Thời gian làm bài</div>
                    </div>
                  </div>
                  <div className="col-sm-3 d-flex">
                    <div><button className="btn btn-sm btn-warning w-100" onClick={() => window.open("/edit-image", "_blank")}>Công cụ chấm bài</button></div>
                  </div>

                  <div className="col-sm-3">
                    <div className="btn btn-sm btn-info cursor-pointer rounded mt-3" style={{ maxHeight: 31, color: "#FFF" }} onClick={() => setModalTestReset(true)}>
                      Cho phép làm lại
                    </div>
                    <div className={modalTestReset ? 'w-100' : 'd-none'}>
                      <span>Thời gian làm bài</span>
                      <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: true }} onCallback={handleCallback} >
                        <div className='d-flex align-items-center'>
                          <input
                            className="form-control h-31 form-control-sm"
                            placeholder='Từ ngày'
                            readOnly={true}
                            onChange={(e) => setDateBegin(e)}
                            value={dateBegin}
                          />
                          <span className='fas fa-arrow-right mx-4' />
                          <input
                            className="form-control h-31 form-control-sm"
                            placeholder='Đến ngày'
                            readOnly={true}
                            onChange={(e) => setDateEnd(e)}
                            value={dateEnd}
                          />
                        </div>
                      </DateRangePicker>
                      <div className="btn btn-sm btn-info cursor-pointer rounded mt-1 mb-3" style={{ maxHeight: 31, color: "#FFF" }} onClick={() => marktestRefresh()}>
                        Lưu
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-end mb-2">
                  <Button variant="success" onClick={() => { saveMarkPoint() }}>Lưu lại</Button>
                </div>

                <div className="col-12 mb-3">
                  <h4 className="mb-2">Đề bài</h4>
                  <div className="row">
                    {detail.files && detail.files.map(function (item, i) {
                      return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                        {item.type === "image" ?
                          <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                          :
                          <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                        }
                        <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>

                      </div>)
                    })}
                  </div>
                </div>
                {studentAnswer &&
                  <div className="col-12 mt-3">
                    <h4 className="mb-2">Bài làm</h4>
                    <div className="row">
                      {studentAnswer.map(function (item, i) {
                        return (<div className="col-auto" key={i} style={{ maxWidth: 200 }}>
                          {item.type === "image" ?
                            <img src={item.url} style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                            :
                            <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: "100%", height: 200, objectFit: "cover" }} alt="" />
                          }
                          <button className="btn btn-sm btn-info w-100" style={{ borderRadius: 0 }} onClick={() => window.open(item.url, "_blank")}>Tải về</button>

                        </div>)
                      })}
                    </div>
                  </div>
                }
                <div className="col-12 col-sm-6 mt-3">
                <div className="row">
                    <h4 className="text-left mb-3 col-12">Chấm bài</h4>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 form-group">
                         
                          <div className="row list-file">
                            {fileUpload && fileUpload.length > 0 && fileUpload.map(function (item, i) {
                              return (
                                <div className="item-upload" key={i} style={{ width: 130, overflow: "hidden", padding: 15, marginBottom: 15 }}>
                                  {item.type === "image" ? <img src={item.url} style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                    :
                                    <img src="https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/08/rules_of_fight_club.png" style={{ width: 100, height: 150, objectFit: "cover" }} alt="" />
                                  }
                                  <button className="btn btn-sm btn-danger text-center w-100" onClick={() => { removeFile(item.url) }}>Xóa</button>
                                </div>
                              )
                            })}
                          </div>
                          <div className="form-upload">
                            <input type="file" id="file" ref={inputRef} style={{ display: "none" }} accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={uploadFile} />
                            <div className="d-flex justify-content-center align-item-center" style={{ border: "1px dashed", padding: 20, borderRadius: 5, cursor: "pointer" }} onClick={() => { inputRef.current.click() }}>
                              <span>Tải lên bài làm</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="w-100 d-flex justify-content-end mt-2">
                  <Button variant="success" onClick={() => { saveMarkPoint() }}>Lưu lại</Button>
                </div>
              </div>

            </div>
          </div>
        </div>
      ) : (<>
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      </>)}

      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Sửa tổng điểm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="w-100">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" placeholder="Nhập tổng điểm" onChange={e => setMarkPoint(e.target.value)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShow(false); setMarkPoint(0) }}>Đóng</Button>
          <Button variant="success" onClick={() => { marktestActionTotal() }}>Lưu</Button>
        </Modal.Footer>
      </Modal>
    </AkkhorLayout>
  );
}
