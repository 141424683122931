import React from "react";
import { LIST_ALPHA } from "../../../configs";
import parse from 'html-react-parser'

export default function QChooseOne(props) {
    const { item, currentAnswer } = props;
    const answers = item.answers

    return (<div className="col-12 form-group">
        <ul>
            {answers && <>
                {answers.map((item, i) => <li key={i}  className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer rounded " + (currentAnswer === i ? ' btn-info' : ' btn-secondary')} >{parse(item+'')}</div></li>)}
            </>}
        </ul>
    </div>);
}
