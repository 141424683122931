import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import UserDropDown from "../../component/UserDropDown";
import AkkhorLayout from "../../layout/akkhor";
import SRList from "../../component/List";
import Moment from "react-moment";
import AsignMarkTest from "../../component/AssignMarkTest";
import { URL_LIST_ALL } from "../../configs/api";
import Axios from "axios";

export default function ListTestsPage(props) {
  const { disableAction, type } = props;
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
  const localclass_id = user && user.data && user.data.class && user.data.class.class_id > 0 ? user.data.class.class_id : 0;
  const [params, setParam] = useState('year_id=' + year_id + '&school_id=' + school_id + '&type=' + type);
  const [keyword, setKeyword] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [grade_id, setGrade_id] = useState(localgrade_id > 0 ? localgrade_id : 'all');
  const [class_id, setClass_id] = useState(localclass_id > 0 ? localclass_id : 'all');
  const [subject_id, setSubject_id] = useState('all');
  const [type_id, setType_id] = useState('all');
  const [teachers, setTeachers] = useState([]);
 

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const createParam = () => {
    let _param = 'year_id=' + year_id + '&school_id=' + school_id + '&type=' + type
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    if (class_id > 0) _param += "&class_id=" + class_id
    if (subject_id > 0) _param += "&subject_id=" + subject_id
    if (type_id > 0) _param += "&type_id=" + type_id
    if (keyword !== '') _param += "&key=" + keyword
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createParam();
  };

  useEffect(() => {
    createParam();
    let mounted = true;
    let config ={
      headers: {
        'Authorization': 'Bearer ' + user.access_token,
        'asselect': JSON.stringify(["users.id as value","users.fullname as name"])
      }
    }
    Axios.get(URL_LIST_ALL + "users?page=1&limit=500&typeaccount=1&school_id=" + school_id, config).then((res) => {
      if (res.data && res.data.status === "success") {
        if (mounted){
          let localList=[]
          localList = localList.concat(res.data.data)
          setTeachers(localList);
        }
    } else console.log(res.data.message);
  }).catch((err) => { console.log(err) });

  }, [year_id]);// eslint-disable-line

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên", options: { filter: true, sort: true } },
    { name: "fullname", label: "Người tạo", options: { filter: true, sort: true } },
    { name: "gradetitle", label: "Khối", options: { filter: true, sort: true } },
    { name: "subjecttitle", label: "Môn học", options: { filter: true, sort: true } },
    {
      name: "listclass", label: "Lớp", options: {
        filter: true, sort: true,
        customBodyRender: (value) => {
          const listClass = JSON.parse(value);
          return <>{listClass.map((item, index) => <span key={index}>{item.label}, </span>)}</>
        },
      }
    },
    {
      name: "datecreate", label: "Ngày đăng", options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      },
    }
  ];
  //if(localclass_id>0){
  columns.push({
    name: "id", label: "Xem trước", options: {
      filter: false, sort: true,
      customBodyRender: (value) => {
        return <Link to={"/examination-detail/" + value} className="btn btn-sm btn-info" >{type === 1 ? "Xem trước" : "Xem trước"}</Link>;
      }
    }
  })
  //xem báo cáo chi tiết một bài kiểm tra
  columns.push({
    name: "id", label: "Thống kê", options: {
      filter: false, sort: true,
      customBodyRender: (value) => {
        return <Link to={"/examination-report/" + value} className="btn btn-sm btn-success" >Báo cáo</Link>;
      }
    }
  })
  //Phân công chấm bài kiểm tra
  columns.push({
    name: "listclass", label: "Phân công", options: {
      filter: true, sort: true,
      customBodyRender: (value, tableMeta) => {
        return <AsignMarkTest index={tableMeta.rowIndex} row={tableMeta.rowData} classId={tableMeta.rowData[0]} listClass={value} refresh={() => setRefresh(!refresh)} listTeacher={teachers}/>
      }
    }
  })

  //}
  return (
    <AkkhorLayout idrole={type === 1 ? "list-exercise" : "list-tests"} roleignone={user.data.typeaccount === 2}>
      <SRList name="at_exam_tests" idrole={type === 1 ? "list-exercise" : "list-tests"} linkaction={'tests'} params={params} title={type === 1 ? "Danh sách phiếu bài tập" : "Danh sách bài kiểm tra"} defaultLimit={50} serverSide={true} noAction={disableAction} onlyList={true} columns={columns}>
        <div className="card pb-0 height-auto my-3">
          <div className="card-body">
            <div className="d-flex pb-2">
              <div className="mr-auto">
                <h3>Tìm kiếm</h3>
              </div>
              {!disableAction && <div className="p-2">
                <Link to={"/add-tests?type=" + type}>
                  <button className="btn btn-success">{type === 1 ? "Tạo phiếu bài tập" : "Tạo bài kiểm tra"}</button>
                </Link>
              </div>}
            </div>
            <form className="new-added-form" method="get" >
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Từ khoá </label>
                  <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Khối </label>
                  <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} name="grade_id" defaultValue={grade_id}
                    onChange={async (e) => { setGrade_id(e) }} all={true} disabled={user.data.typeaccount === 2}
                  ></UserDropDown>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label>Lớp </label>
                  <UserDropDown apiname={"class?key=&page=1&orderby=title-ASC&limit=500&grade_id=" + grade_id+ "&year_id=" + year_id} placeholder="Tất cả lớp" defaultValue={class_id} colvalue={'class.id'} colname={'class.title'} name='class_id'
                    onChange={async (e) => { setClass_id(e); }} all={true} disabled={user.data.typeaccount === 2}
                  ></UserDropDown>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label> Môn học </label>
                  <UserDropDown apiname={"subjects?key=&istree=1&page=1&orderby=title-ASC&limit=50&school_id=" + user.data.school_id + "&grade_id=" + grade_id} placeholder="Chọn môn học" defaultValue={subject_id} colvalue={"subjects.id"} colname={"subjects.title"} name="subject_id"
                    onChange={async (e) => { setSubject_id(e) }} all={true}
                  ></UserDropDown>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 form-group">
                  <label> Loại bài kiểm tra </label>
                  <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=1&school_id=" + school_id} placeholder="Chọn loại" colvalue={"exam_param.id"} defaultValue={type_id} colname={"exam_param.title"} name='type_id'
                    onChange={async (e) => { setType_id(e) }} all={true}></UserDropDown>
                </div>
                <div className="col-sm-6 col-12 form-group">
                  <label> Thời gian </label>
                  <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "" }}
                    onCallback={handleCallback}
                  >
                    <div className='d-flex align-items-center'>
                      <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                      <input
                        className="form-control h-31 form-control-sm"
                        placeholder='Từ ngày'
                        readOnly={true}
                        onChange={(e) => setStartDate(e)}
                        value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                      />
                      <span className='fas fa-arrow-right mx-4' />
                      <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                      <input
                        className="form-control h-31 form-control-sm"
                        placeholder='Đến ngày'
                        readOnly={true}
                        onChange={(e) => setEndDate(e)}
                        value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                      />
                    </div>
                  </DateRangePicker>
                </div>
                <div className="col-12 ">
                  <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SRList>
    </AkkhorLayout>
  );
}
