import React from "react";
import AkkhorLayout from "../../layout/akkhor";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import SRList from "../../component/List";
import Moment from "react-moment";

export default function HistoryActionUser() {
  const typeTableName={
    'users_lunch':'Đăng ký suất ăn',
    'exam_history':'Thực hiện Khảo thí', 
    'roles':'Quyền',
    'settings':'Cấu hình chung', 
    'timetable_excel':'Nhập Excel thời khoá biểu',
    'teachers_excel':'Nhập Excel giáo viên',
    'students_excel':'Nhập Excel học sinh',
    'students_extrac_excel':'Nhập Excel học sinh ngoại khoá',
    'extrac_excel':'Nhập Excel lớp ngoại khoá',
    'teachermain_excel':'Nhập Excel giáo viên chủ nhiệm',
    'timetable_temp':'Tạo thời khoá biểu',
    'class_excel':'Nhập Excel lớp học',
    'users':'Tài khoản',
    'students_onday':'Chuyên cần',
    'exam_question':'Câu hỏi khảo thí',
    'exam_tests':'Bài khảo thí',
    'class':'Lớp học',
    'class_group':'Nhóm ngoại khoá',
    'exam_param':'Thông số khảo thí',
    'grades':'Khối',
    'parts':'Bộ phận',
    'schools':'Trường học',
    'students':'Học sinh',
    'students_class':'Học sinh trong lớp',
    'subjects':'Môn học',
    'timetable':'Thời khoá biểu',
    'timetable_notes':'Ghi chú điểm danh',
    'users_class':'Giáo viên trong lớp',
    'users_login':'Tài khoản đăng nhập',
    'users_parts':'Nhân viên trong bộ phận',
    'weeks':'Tuần',
    'years':'Năm học',
    'reschedule':'Đổi lịch'
  }
  const typeAction={
    insert:'Thêm mới',
    update:'Chỉnh sửa',
    delete:'Xoá',
  }
  const colors = { insert: "#87bb17", update: "#ee8824", delete: "#cc3333"}
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.id>0?'user_id='+user.data.id:0;
  console.log(params);
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "fullname", label: "Người thao tác", options: { filter: true, sort: true } },
    { name: "tablename", label: "Chức năng", options: { filter: true, sort: true, customBodyRender: (value, tableMeta, updateValue) => {
      return typeTableName.hasOwnProperty(value)?typeTableName[value]:value;
    }, } },
    { name: "action", label: "Hành động", options: { filter: true, sort: true, customBodyRender: (value, tableMeta, updateValue) => {
      return (<b style={{color:colors[value]}}>{typeAction.hasOwnProperty(value)?typeAction[value]:value}</b>);
    } } },
    { name: "datecreate",label: "Ngày thao tác",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  
  return (
    <AkkhorLayout idrole="internal.history-action-user">
      <SRList name="logs" idrole="internal.history-action-user" params={params}  title="Lịch sử thao tác của mình" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
