import React from "react";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';

export default function TomoImageEditor() {
    const myTheme = {
        // Theme object to extends default dark theme.
    };
    return (
        <ImageEditor
            includeUI={{
                loadImage: {
                    path: 'https://tomoclass2021.s3.ap-southeast-1.amazonaws.com/images/2021/11/09/screen.jpg',
                    name: 'SampleImage',
                },
                theme: myTheme,
                menu: ['text','draw'],
                initMenu: 'text',
                uiSize: {
                    width: '100%',
                    height: '100vh',
                },
                menuBarPosition: 'bottom',
            }}
           
            selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
            }}
            usageStatistics={true}
        />
    )
}