
import React from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import moment from "moment";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";
const _gender={'Nữ':0,'Nam':1};

export default function ListTeachersPage() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;

  const columns = [
    {name: "id", label: "ID",  options: { filter: false, sort: true } },
    {name: "fullname",label: "Họ và tên", options: { filter: true, sort: true,filterType: 'textField' }},
    {
      name: "gender",
      label: "Giới tính",
      options: { filter: true, sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
        const icon = value === 1 ? "fas fa-male text-success" : "fas fa-female text-danger";
        return <i className={icon}></i>;
      },
      customFilterListOptions: { render: v => `Giới tinh: ${v}` },
      filterType: 'dropdown',
      filterOptions: { names: ['Nữ','Nam'],
        logic: (location, filters, row) => {
          if (filters.length && _gender[filters[0]] === location) return false;
          else return true;
        }
      }}
    },
    { name: "birthday",label: "Tuổi", options: 
      { 
        filter: true, sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value)
          {
            const year1 = new Date(value).getFullYear();
            const year2 = new Date().getFullYear();
            return <b className="text-info">{year1 && year2 ? year2 - year1 : 0}</b>;
          }else return null;
        },filterType: 'textField'
      }
    },
    { name: "mobile", label: "Điện thoại", width: 150 },
    { name: "email", label: "Email", width: 250 },
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  const columnsExport={'id':true,'username':true,'firstname':true,'lastname':true,'address':true,'mobile':true,'email':true,'nation':true,info:(e)=>e?JSON.parse(e):null,
    gender:(e)=>e===1?'Nam':(e===0?'Nữ':''),
    birthday:(e)=>{ 
      if(e)return moment(new Date(e)).format('DD/MM/YYYY')
      return '';
    }
  }

  return (<AkkhorLayout idrole="list-teachers"><SRList name="teachers" idrole="list-teachers" params={params} title="Giáo viên" defaultLimit={50}  serverSide={true} columns={columns} columnsExport={columnsExport} ></SRList></AkkhorLayout>)
}
