import React from "react";
import Chart from 'react-apexcharts'
import { Link } from "react-router-dom";

export default function HomeChartArea({ series, categories, title, more }) {
  const state =  {
    series: series,
    options: {
      chart: {
        height: 300,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: categories,
        labels: {
          format: 'dd/MM/yyyy'
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yyyy'
        },
      },
    },
  
  
  };
  return (
    <div className="card dashboard-card-one pd-b-20">
      <div className="card-body p-2">
        <div className="heading-layout1">
          <div className="item-title d-flex justify-content-between w-100">
            <h3>{title}</h3>
            {more&&<Link to={more} className="mt-2 text-info">Chi tiết... </Link>}
          </div>
        </div>
        <div className="earning-chart-wrap">
          <Chart options={state.options} series={state.series} type="area" height={400} />
        </div>
      </div>
    </div>
  );
}
