import React, { useState } from "react";

export default function QFillTo(props) {
    const { currentAnswer } = props;
  
    const [choice] = useState(currentAnswer?currentAnswer:{})
    return (<div className="col-12 d-flex mt-3 mb-3">
        {choice && <>
            {Object.values(choice).map((item, i) => <input value={item} type="text" readOnly key={i} className={"form-control form-control-sm border mr-3"} style={{ width: 100 }} />)}
        </>}
    </div>);
}