import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterGradeClassWeekSubject from "../../component/FilterGradeClassWeekSubject";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import ItemExamOfExercise from "./ItemExamOfExercise";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { checkRole } from "../../model";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let studentChoice = {}
export default function ExamOfExercise() {
  let query = useQuery();
  const { user } = useSelector((state) => state.auth);
  const [listStudent, setListStudent] = useState([]);
  const [teacher, setTeacher] = useState(null);
  const [teacher2, setTeacher2] = useState(null);
  const [listOnday, setListOnday] = useState(null);
  const [classChoice, setClassChoice] = useState(query.get('class_id'));
  const [timeTableChoice, setTimeTableChoice] = useState(null);
  const typecheck = 3;
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null) {
      setListStudent([])
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_class?page=1&limit=500&class_id=" + classChoice, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setListStudent(res.data.data);
        } else console.log(res.data.message);
      })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, user]);
  //Lấy danh sách giáo viên dạy lớp này
  useEffect(() => {
    let mounted = true;
    if (timeTableChoice) {
      setTeacher(null)
      if (timeTableChoice.teacher_id > 0) Axios.get(URL_LIST_ALL + "users/" + timeTableChoice.teacher_id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTeacher(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      //Lấy giáo viên hỗ trợ
      setTeacher2(null)
      if (timeTableChoice.teacher_id2 > 0) Axios.get(URL_LIST_ALL + "users/" + timeTableChoice.teacher_id2, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTeacher2(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [timeTableChoice, user])
  //Lấy danh sách chuyên cần
  useEffect(() => {
    let mounted = true;
    if (classChoice && timeTableChoice) {
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=" + typecheck + "&class_id=" + classChoice + "&orderonday=" + timeTableChoice.orderonday + "&date=" + timeTableChoice.date, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) {
              let listOnday = {};
              res.data.data.forEach(element => {
                listOnday[element.student_id] = element;
              });
              setListOnday(listOnday);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    } else {
      setListOnday(null);
    }
    return () => (mounted = false);
  }, [classChoice, timeTableChoice, user]);

  const choiceItem = (e) => {
    studentChoice[e.student_id] = e;
  }

  const createDataForItem = (student, onday) => {
    let obj = onday ? onday : { violation: 0, notes: '', notes2: '', status: 0 }
    let data = {
      ...student,
      student_id: student.id,
      subject_id: timeTableChoice ? timeTableChoice.subject_id : null,
      week: timeTableChoice ? timeTableChoice.week : null,
      date: timeTableChoice ? timeTableChoice.date : null,
      time: timeTableChoice ? timeTableChoice.time : null,
      orderonday: timeTableChoice ? timeTableChoice.orderonday : null,
      typecheck,
      violation: obj.violation,
      notes: obj.notes,
      notes2: obj.notes2,
      status: obj.status
    };
    delete data.id;
    if (!studentChoice.hasOwnProperty(student.id))
      studentChoice[student.id] = data;
    return data;
  }

  //Phân quyền
  let viewtype = 'me';
  const allowgroup = checkRole(user, 'examofexercise', 'group');
  if (allowgroup) viewtype = 'group';
  const allowall = checkRole(user, 'examofexercise', 'all');
  if (allowall) viewtype = 'all';

  return (
    <AkkhorLayout idrole="examofexercise">
      <h4 className="mt-2">Kiểm tra bài tập</h4>
      <FilterGradeClassWeekSubject
        classChoice={classChoice}
        setClassChoice={setClassChoice}
        setTimeTableChoice={setTimeTableChoice}
        viewtype={viewtype}
      ></FilterGradeClassWeekSubject>
      <div className="d-flex my-2">
        Giáo viên: {teacher ? <b className="ml-2"> {teacher.fullname}</b> : <i className="text-danger"> Không xác định</i>}
        {teacher2 && <b className="ml-2">, {teacher2.fullname}</b>}
      </div>
      <div style={{ width: '100%', height: '600px' }}>
        <StickyTable leftStickyColumnCount={2} borderWidth={0}>
          <Row className="bg-secondary text-light font-weight-bold" style={{ fontSize: 14 }}>
            <Cell className="bg-secondary text-center">#</Cell>
            <Cell className="bg-secondary text-center">Họ và tên</Cell>
            <Cell className="bg-secondary text-center">Giới tính</Cell>
            <Cell className="bg-secondary text-center">Hoàn thành</Cell>
            <Cell className="bg-secondary text-center">Chưa hoàn thành</Cell>
            <Cell className="bg-secondary text-center">Chưa làm</Cell>
            <Cell className="bg-secondary text-center">Vi phạm</Cell>
          </Row>
          {listOnday && <>{listStudent.map((item, i) => (
            <Row key={i}>
              <ItemExamOfExercise
                i={i}
                data={createDataForItem(item, (listOnday && listOnday[item.id] ? listOnday[item.id] : null))}
                choiceItem={choiceItem}
                isaction={timeTableChoice && timeTableChoice.teacher_id === user.data.id}
              ></ItemExamOfExercise>
            </Row>
          ))}
          </>}
        </StickyTable>
      </div>
      {timeTableChoice && (timeTableChoice.teacher_id === user.data.id || timeTableChoice.teacher_id2 === user.data.id) ? <></> : <p className="text-danger mt-3"><i>Bạn không có quyền kiểm tra vở bài tập tiết học này</i></p>}
    </AkkhorLayout>
  );
}
