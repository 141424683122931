
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/api";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

export default function ItemCreateTimeTable(props) {
    const { user } = useSelector((state) => state.auth);
    const {item, classChoice, listSubjects, onChoiceSubject, day , old } = props
    const [listTeachers, setListTeachers] = useState([]);
    const [idSubject, setIdSubject] = useState(null);
    const [idTeacher, setIdTeacher] = useState(null);
    const [idTeacher2, setIdTeacher2] = useState(null);
    const [value, onChange] = useState([item.begin, item.end]);
    
    const chooseSubject=(_idSubject, mustback)=>{
        setListTeachers([]);
        setIdSubject(_idSubject)
        Axios.get(URL_LIST_ALL + "users?page=1&limit=500&typeaccount=1&class_id="+classChoice+'&subject_id='+_idSubject, user.config).then((res) => {
            if (res.data && res.data.status === "success") {
                setListTeachers(res.data.data);
                if (res.data.data && res.data.data.length > 0)
                {
                    let currentId =  old && old.teacher_id?old.teacher_id:res.data.data[res.data.data.length-1].id;
                    if(mustback)currentId = res.data.data[res.data.data.length-1].id;
                    selectedTeacher(_idSubject,currentId,currentId,mustback);
                }else{
                    selectedTeacher(_idSubject,null,null,mustback);
                }
            }else console.log(res.data.message);
        }).catch(err=>console.log(err));
    }

    const selectedTeacher=(_idSubject, _idTeacher1,_idTeacher2, mustback)=>{
        setIdTeacher(_idTeacher1);
        setIdTeacher2(_idTeacher2);
        const data = { ...item, day , subject_id: _idSubject, teacher_id: _idTeacher1,teacher_id2: _idTeacher2, time:value.join('-').replace(/:/g, "h") }
        if(!old || mustback)onChoiceSubject(data)
    }

    

    useEffect(  () => {
        if(old) {
            chooseSubject(old.subject_id,false)
            const arr = old.time.split('-');
            onChange([arr[0].replace(/h/g, ":"),arr[1].replace(/h/g, ":")])
        }
    },[old])// eslint-disable-line


    const ChangeRangeTime=(e) => {
        onChange(e);
        console.log(e)
        const data = { ...item, day , subject_id: idSubject, teacher_id: idTeacher, time:e.join('-').replace(/:/g, "h") }
        onChoiceSubject(data)
    }

    return (<>
    <div className="d-flex border p-2 align-items-center" style={{minWidth:50}}>
        <div className="text-center w-100">{item.index}</div>
    </div>
    <div className="d-flex border p-2 align-items-center" style={{minWidth:250}}>
        <div className="w-100 d-flex">
        <TimeRangePicker
        onChange={ChangeRangeTime}
        value={value}
        disableClock={true}
        clearIcon={null}
      />
        </div>
    </div>
    <div className="d-flex border p-2 align-items-center" style={{minWidth:200}}>
        <div className="text-center w-100">
            <select className="form-control form-control-sm" onChange={(e)=>chooseSubject( e.target.value,true)} value={idSubject?idSubject:(old && old.subject_id?old.subject_id:'')}>
                <option value="">Chọn môn học</option>
                {listSubjects.map((item, index) => <option key={index} value={item.id}>{item.title}</option>)}
            </select>
        </div>
    </div>
    <div className="d-flex border p-2 align-items-center" style={{minWidth:250}}>
        <div className="text-center w-100">
            <select className="form-control form-control-sm" value={idTeacher?idTeacher:(old && old.teacher_id?old.teacher_id:'')} disabled={listTeachers.length ===0} onChange={(e)=>selectedTeacher(idSubject,e.target.value,idTeacher2,true)}>
                {listTeachers.length === 0 && <option value="">Chọn giáo viên</option>}
                {listTeachers.map((item, index) => <option key={index} value={item.id}>{item.fullname}</option>)}
            </select>
        </div>
    </div>
    <div className="d-flex border p-2 align-items-center" style={{minWidth:250}}>
        <div className="text-center w-100">
            <select className="form-control form-control-sm" value={idTeacher2?idTeacher2:(old && old.teacher_id2?old.teacher_id2:'')} disabled={listTeachers.length ===0} onChange={(e)=>selectedTeacher(idSubject,idTeacher,e.target.value,true)}>
                {listTeachers.length === 0 && <option value="">Chọn giáo viên</option>}
                {listTeachers.map((item, index) => <option key={index} value={item.id}>{item.fullname}</option>)}
            </select>
        </div>
    </div>
    </>);


}