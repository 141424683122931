import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterClassWeek from "../../component/FilterClassWeek";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import ItemLunch from "./ItemLunch";
import { confirmAlert } from "react-confirm-alert";
import { StickyTable, Row, Cell } from 'react-sticky-table';
let studentChoice = {}

export default function LunchPage() {
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const typeaccount = user && user.data && user.data.typeaccount > -1 ? user.data.typeaccount : 0;
  const [listStudent, setListStudent] = useState([]);
  const [listTeacher, setListTeacher] = useState([]);
  const [listOnday, setListOnday] = useState(null);
  const [classChoice, setClassChoice] = useState(null);
  const [weekChoice, setWeekChoice] = useState(0);
  const [dayChoice, setDayChoice] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const typecheck = 5;
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice) {
      setListStudent([])
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_class?page=1&limit=500&class_id=" + classChoice, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListStudent(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, user]);
  //Lấy danh sách giáo viên dạy lớp này
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null && year_id) {
      setListTeacher([])
      Axios.get(URL_LIST_ALL + "teacher_class?page=1&limit=500&ismain=1&class_id=" + classChoice + '&year_id=' + year_id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListTeacher(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, user, year_id])
  //Lấy danh sách chuyên cần
  useEffect(() => {
    let mounted = true;
    if (classChoice && weekChoice && dayChoice) {
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=" + typecheck + "&class_id=" + classChoice + '&week=' + weekChoice + "&date=" + dayChoice, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) {
              let listOnday = {};
              res.data.data.forEach(element => {
                listOnday[element.student_id] = element;
              });
              setListOnday(listOnday);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

    } else {
      setListOnday(null);
    }
    return () => (mounted = false);
  }, [classChoice, weekChoice, user, dayChoice, reload]);

  const choiceItem = (e) => {
    studentChoice[e.student_id] = e;
  }
  const callApi = () => {
    setLoading(true)
    let data = Object.values(studentChoice);
    Axios.post(URL_LIST_ALL + "students_onday", data, user.config).then(res => {
      setLoading(false)
      if (res.data.status === 'success') {
        setReload(!reload);
        confirmAlert({ title: 'Thành công', message: 'Bạn đã lưu thành công', buttons: [{ label: 'OK' }] });
      }
      else {
        alert(res.data.message)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err)
    });
  }


  const createDataForItem = (student, onday) => {
    let obj = onday ? onday : { violation: 0, notes: '', status: student.islunch === 1 ? 1 : 2, status2: 1 }
    let data = {
      ...student,
      student_id: student.id,
      week: weekChoice,
      date: dayChoice,
      time: null,
      orderonday: 10,
      typecheck,
      violation: obj.violation,
      notes: obj.notes,
      notes2: obj.notes2,
      status: obj.status,
      status2: obj.status2
    };
    delete data.id;
    if (!studentChoice.hasOwnProperty(student.id))
      studentChoice[student.id] = data;
    return data;
  }

  const checkUser = () => {
    const myrole = JSON.parse(user.data.roles)
    let find = false;
    listTeacher.map((item) => {
      if (user.data.id === item.id || (myrole.lunch.children.all.checked === true)) {
        find = true
        return true;
      }
      return item;
    })
    return find;
  }

  let viewtype = 'all';

  return (
    <AkkhorLayout idrole="extrac">
      <h4 className="my-2">Điểm danh hàng ngày / ăn trưa</h4>
      <FilterClassWeek
        disableeGrade={typeaccount === 1?true:false}
        classChoice={classChoice}
        setClassChoice={setClassChoice}
        weekChoice={weekChoice}
        setWeekChoice={setWeekChoice}
        dayChoice={dayChoice}
        setDayChoice={setDayChoice}
        viewtype={viewtype}
        typeclass={1}
        teacher={user.data.id}
      ></FilterClassWeek>
      <div className="d-flex my-2">
        Giáo viên: {listTeacher.map((teacher) => <b key={teacher.id} className="ml-2"> {teacher.fullname} ,</b>)}
      </div>
      <div style={{ width: '100%', height: '600px' }}>
        <StickyTable leftStickyColumnCount={2} borderWidth={0}>

          <Row className="bg-secondary text-light font-weight-bold" style={{ fontSize: 14 }}>
            <Cell className="bg-secondary text-center">#</Cell>
            <Cell className="bg-secondary text-center">Họ và tên</Cell>
            <Cell className="bg-secondary text-center">Giới tính</Cell>
            <Cell className="bg-secondary text-center">Có mặt</Cell>
            <Cell className="bg-secondary text-center">Vắng mặt</Cell>
            <Cell className="bg-secondary text-center">Ghi chú hàng ngày</Cell>
            <Cell className="bg-secondary text-center">Có ăn</Cell>
            <Cell className="bg-secondary text-center">Không ăn</Cell>
            <Cell className="bg-secondary text-center">Ghi chú ăn trưa</Cell>
          </Row>
          {listOnday && <>{listStudent.map((item, i) => (
            <Row key={i}>
              <ItemLunch
                i={i}
                data={createDataForItem(item, (listOnday && listOnday[item.id] ? listOnday[item.id] : null))}
                choiceItem={choiceItem}
                isaction={checkUser()}
              ></ItemLunch>
            </Row>
          ))}
          </>}
        </StickyTable>
      </div>
      {checkUser() ? <div className="d-flex mt-4">
        <div  >
          <button type="button" className="btn btn-success btn-block btn-lg" disabled={loading} onClick={callApi}>Lưu</button>
        </div>

      </div> : <p className="text-danger mt-3"><i>Bạn không có quyền điểm danh lớp này</i></p>}
    </AkkhorLayout>
  );
}
