
import React from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";

export default function ListGradesPage() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên khối", options: { filter: true, sort: true } },
    { name: "schoolstitle", label: "Trường",options: { filter: true, sort: true }},
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  

  return (
    <AkkhorLayout idrole="list-grades">
    <SRList name="grades" idrole="list-grades" params={params}  title="Khối học" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
