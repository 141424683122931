import Axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import 'moment/locale/vi';
import ItemTeaching from "./ItemTeaching";
import { useSelector } from "react-redux";
import UserDropDown from "../../component/UserDropDown";
import SelectWeek from "../../component/SelectWeek";
import { checkRole } from "../../model";

export default function Teaching() {
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const [teacherChoice, setTeacherChoice] = useState(user.data.id);
    const [weekChoice, setWeekChoice] = useState(null);
    const [listTimeTable, setListTimeTable] = useState([]);
    //Lấy danh sách thời khoá biểu
    useEffect(() => {
        let mounted = true;
        if (teacherChoice && weekChoice && year_id) {
            setListTimeTable([])
            Axios.get(URL_LIST_ALL + "timetable?page=1&limit=500&year_id=" + year_id + "&teacher=" + teacherChoice + "&week=" + weekChoice + '&orderby=date-asc', user.config).then((res) => {
                if (res.data && res.data.status === "success") {
                    if (mounted) setListTimeTable(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));
        }
        return () => (mounted = false);
    }, [teacherChoice, weekChoice, user, year_id]);

    //Lay danh sach ngay trong tuan
    const createWeekDate = () => {
        let list = {};
        listTimeTable.forEach(item => {
            list[item.date] = true;
        })
        return list;
    }
    //Lay danh sach tiet hoc trong ngay
    const morning = [1, 2, 3, 4, 5];
    const afternoon = [6, 7, 8, 9];
    //Lọc danh sách thời khoá biểu
    const filterCalendar = (date, orderonday) => {
        let list = {};
        listTimeTable.forEach(item => {
            if (item.date === date && item.orderonday === orderonday) {
                list = item;
                return;
            }
        })
        return list;
    }

    const allowAll = checkRole(user, 'teaching', 'all');
    return (
        <AkkhorLayout idrole="teaching">
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Lịch báo giảng</h3>
                        </div>
                    </div>
                    <form className="form-inline">
                        {allowAll && <><label className="mr-2">Giáo viên</label>
                            <div className="border rounded mr-md-4"><UserDropDown disabled={!allowAll} apiname={"users?page=1&limit=500&istimetable=1&typeaccount=1&school_id=" + school_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} onChange={async (e) => { setTeacherChoice(e) }} defaultValue={teacherChoice}></UserDropDown>
                            </div></>}
                        <label className="mr-2 ">Tuần thứ</label>
                        <SelectWeek weekChoice={weekChoice} setWeekChoice={setWeekChoice}></SelectWeek>
                        {allowAll && <button type="button" className="btn btn-sm btn-primary ml-3" onClick={() => setTeacherChoice(user.data.id)} ><i className="fas fa-user-alt"></i> Lịch của tôi</button>}
                    </form>
                    <div className="d-flex mt-4  ">
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 80 }}>
                            <div className="text-center w-100">Tuần</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 100 }}>
                            <div className="text-center w-100">Ngày</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 60 }}>
                            <div className="text-center w-100">Buổi</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 50 }}>
                            <div className="text-center w-100">Tiết</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 90 }}>
                            <div className="text-center w-100">Lớp</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 145 }}>
                            <div className="text-center w-100">Môn học</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 70 }}>
                            <div className="text-center w-100">Tiết thứ</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 200 }}>
                            <div className="text-center w-100">Tên bài dạy</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 200 }}>
                            <div className="text-center w-100">Ghi chú</div>
                        </div>
                        <div className="d-flex border p-2 align-items-center bg-secondary text-white" style={{ minWidth: 70 }}>
                            <div className="text-center w-100">#</div>
                        </div>
                    </div>
                    {listTimeTable.length > 0 && <div className="d-flex">
                        <div className="d-flex border p-2 align-items-center bg-gradient-secondary" style={{ minWidth: 80 }}>
                            <div className="text-center w-100">Tuần {weekChoice}</div>
                        </div>
                        <div >
                            {Object.keys(createWeekDate()).map((date, index) => {
                                return (<div className="d-flex bg-light" key={index}  >
                                    <div className="d-flex border p-2 align-items-center" style={{ minWidth: 100 }}>
                                        <div className="text-center w-100">
                                            <div className="text-center text-capitalize"><Moment locale="vi" format="dddd">{date}</Moment></div>
                                            <div className="text-center"><Moment format="DD/MM/YYYY">{date}</Moment></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex">
                                            <div className="d-flex border p-2 align-items-center" style={{ minWidth: 60 }}><div className="text-center w-100">Sáng</div></div>
                                            <div>
                                                {morning.map((i) => <div key={i} className="d-flex"><ItemTeaching i={i} timeTable={filterCalendar(date, i)}></ItemTeaching></div>)}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="d-flex border p-2 align-items-center" style={{ minWidth: 60 }}><div className="text-center w-100">Chiều</div></div>
                                            <div>
                                                {afternoon.map((i) => <div key={i} className="d-flex"><ItemTeaching i={i} timeTable={filterCalendar(date, i)}></ItemTeaching></div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>}
                </div>
            </div>
        </AkkhorLayout>
    );
}
