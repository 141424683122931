import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { URL_LIST_ALL } from "../configs/api";
import HomeChartArea from "./ChartArea";
import HomeChartBar from "./ChartBar";
import HomeWidget from "./widget";
import Tour from 'reactour'
import Loading from "../component/Loading";

export default function DashboardAdmin() {
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const [mdata, setMdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);


  useEffect(
    function () {
      let mounted = true;
      if (year_id > 0) {
        setLoading(true);
        if (mounted) setMdata(null);
        Axios.get(URL_LIST_ALL + "dashboard/1?year_id="+year_id, user.config)
          .then((res) => {
            if (mounted) setLoading(false);
            if (res.data.status === "success") {
              if (mounted) setMdata(res.data.data);
            } else console.log(res.data.message);
          })
          .catch((err) => console.log(err));
      }
      return () => (mounted = false);
    },
    [user,year_id]
  );

  const steps = [
    {
      selector: '.totalstudents',
      content: 'Đây là số lượng học sinh',
    },
    {
      selector: '.totalteachers',
      content: 'Đây là số lượng giáo viên',
    },
    {
      selector: '.totalclass',
      content: 'Đây là số lượng lớp học',
    },
    {
      selector: '.totalsubjects',
      content: 'Đây là số lượng môn học',
    }
  ];

  const getSeria1 = () => {
    return [{
      name: 'Đi muộn',
      data: mdata.listweek ? mdata.listweek.diligent_late : 0
    }, {
      name: 'Nghỉ có phép',
      data: mdata.listweek ? mdata.listweek.diligent_granted : 0
    }, {
      name: 'Nghỉ không phép',
      data: mdata.listweek ? mdata.listweek.diligent_nogranted : 0
    }];
  }
  const getSeria2 = () => {
    return [{
      name: 'Đủ, cẩu thả',
      data: mdata.listweek ? mdata.listweek.workbook_careless : 0
    }, {
      name: 'Ghi chép thiếu',
      data: mdata.listweek ? mdata.listweek.workbook_missing : 0
    }, {
      name: 'Không ghi chép',
      data: mdata.listweek ? mdata.listweek.workbook_donot : 0
    }];
  }

  const getSeria3 = () => {
    return [{
      name: 'Chưa hoàn thành',
      data: mdata.listweek ? mdata.listweek.exercise_unfinished : 0
    }, {
      name: 'Không làm',
      data: mdata.listweek ? mdata.listweek.exercise_donot : 0
    }];
  }

  return (<>
    {loading ? (<Loading />) : (
      <><Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
        {(mdata !== null && mdata.listweek) && (
          <>
            {/* Breadcubs Area Start Here */}
            <div className="breadcrumbs-area">
              <h3>Admin Dashboard</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Admin</li>
              </ul>
            </div>
            {/* Breadcubs Area End Here */}

            {/* Dashboard Content Start Here */}
            <div className="row gutters-20">
              <div className="col-12 col-xl-8">
                <HomeChartArea categories={mdata.listweek.listDate} series={getSeria1()} title={'Điểm danh tuần ' + mdata.date} more="/report-check?typecheck=1"></HomeChartArea>
              </div>
              <div className="col-12 col-xl-4">
                <HomeChartBar mdata={mdata} title={'Điểm danh trong tháng ' + mdata.month} categories={mdata.listmonth.diligent.labels} data={mdata.listmonth.diligent.values}></HomeChartBar>
              </div>
            </div>
            <div className="row gutters-20">
              <div className="col-12 col-xl-8">
                <HomeChartArea categories={mdata.listweek.listDate} series={getSeria2()} title={'Kiểm tra vở ghi chép ' + mdata.date} more="/report-check?typecheck=2"></HomeChartArea>
              </div>
              <div className="col-12 col-xl-4">
                <HomeChartBar mdata={mdata} title={'Kiểm tra vở ghi chép trong tháng ' + mdata.month} categories={mdata.listmonth.workbook.labels} data={mdata.listmonth.workbook.values}></HomeChartBar>
              </div>
            </div>

            <div className="row gutters-20">
              <div className="col-12 col-xl-8">
                <HomeChartArea categories={mdata.listweek.listDate} series={getSeria3()} title={'Kiểm tra bài tập ' + mdata.date} more="/report-check?typecheck=3"></HomeChartArea>
              </div>
              <div className="col-12 col-xl-4">
                <HomeChartBar mdata={mdata} title={'Kiểm tra bài tập trong tháng ' + mdata.month} categories={mdata.listmonth.exercise.labels} data={mdata.listmonth.exercise.values}></HomeChartBar>
              </div>
            </div>


            {/* Dashboard Content End Here */}
            {/* Dashboard summery Start Here */}
            <HomeWidget mdata={mdata}></HomeWidget>
            {/* Dashboard summery End Here */}
          </>
        )}
      </>
    )}
  </>)
}