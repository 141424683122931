import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterLunchMonth from "../../component/FilterLunchMonth";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import moment from "moment";
import DataDate from'./dataDate';
import { CSVLink } from "react-csv";
import ReportAtendsMonth from "./ReportAtendsMonth";

export default function ReportAttendMonthPage() {
  const { user } = useSelector((state) => state.auth);
  const [listStudent, setListStudent] = useState([]);
  const [classChoice, setClassChoice] = useState(null);
  const [gradeChoice, setGradeChoice] = useState(null);
  const [dayChoice, setDayChoice] = useState(null);
  const [lunchMonth, setLunchMonth]= useState(null)
  const [month, setMonth] = useState(moment().month()+1);
  const [years, setYears] = useState(moment().year());

  //Lấy danh sách khối
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null) {
      setListStudent([])
      let link=URL_LIST_ALL + "students_class?page=1&limit=40";
      if(classChoice>0)link+="&class_id=" + classChoice;
      if(gradeChoice>0)link+="&grade_id=" + gradeChoice;
      Axios.get(link,user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListStudent(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice,gradeChoice,user]);

// lấy danh sách báo cáo ăn trưa
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null) {
      setLunchMonth(null)
      let link=URL_LIST_ALL + "report_attends_month/";
      if(classChoice>0)link+=classChoice;
      if(years>0)link+="?year=" + years;
      if(month>0)link+="&month=" + month;
      Axios.get(link,user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setLunchMonth(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice,month,years,user]);
  
// lấy ngày trong tháng tự động
  function getDaysOfMonth(year, month) {
    return (new Date(year, month ,0).getDate());
  }
  const dates=(getDaysOfMonth(new Date().getFullYear(), month))
  
   const filterDataExport=()=>{
    let array= [];
    listStudent.map((student, index) => {
      let obj={
        'TT':''+(index+1),
        'Họ và tên':student.fullname
      }
      let date=years+'-'+(month>9?month:'0'+month)+'-';
      for(var i=1; i<=dates; i++){
        const key= ''+(i>9?i:'0'+i);
        if(lunchMonth!==null && lunchMonth.list && lunchMonth.list[date+key] && lunchMonth.list[date+key][student.id])
        {
          let day = lunchMonth.list[date+key][student.id];
          if(day){
            obj['0'+i]= day.status===1?'X':'O';
          }else obj['0'+i]= null;
        }else obj['0'+i]= null;
      } 
      array.push(obj);
      return student
    });
    return array
   }
    return (
        <AkkhorLayout idrole="report.report-attends-month">
        <h4 className="mt-2">Báo cáo đi học theo tháng</h4>
        <FilterLunchMonth
            classChoice={classChoice}
            setClassChoice={setClassChoice}
            gradeChoice={gradeChoice}
            setGradeChoice={setGradeChoice}
            dayChoice={dayChoice}
            setDayChoice={setDayChoice}
            month={month}
            setMonth={setMonth}
            years={years}
            setYears={setYears}
        ></FilterLunchMonth>
        {lunchMonth!==null&&<>
        <div className="info-table table-responsive">
        <div className="d-flex justify-content-between" >
          <h5 className="mb-2">Lớp học: <span className="text-primary">{lunchMonth.title}</span> Giáo viên chủ nhiệm: <span className="text-primary">{lunchMonth.fullname}</span></h5>
          <CSVLink
            data={filterDataExport()}
            filename={ "Diem-Danh-An-Trua-Lop-"+lunchMonth.title+"-Thang-"+moment().format('MM-YYYY')+".csv"}
            className="btn"
            target="_blank"
          >
            Tải xuống <span className="fas fa-download text-info"></span>
          </CSVLink>
        </div>

        <table className="table table-bordered">
            <thead>
            <tr>
            <td className="align-items-center bg-secondary text-white ">
            <div className="text-center w-100">TT</div>
            </td>
            <td className="align-items-center bg-secondary text-white ">
            <div className="text-center w-100">Họ và tên</div>
            </td>
            {dates && <DataDate dates={dates}  month={month} years={years} />}
        </tr>
        </thead>
            <tbody>
            {listStudent.map((item, i) => (
                <tr key={i} >
                <ReportAtendsMonth
                    i={i}
                    dates={dates}
                    student={item}
                    lunchs={lunchMonth.list}
                ></ReportAtendsMonth>
                </tr>
            ))}
            </tbody>
        </table>
        <span className="text-dark">Chú ý: Tích  <b className="text-success">X</b> là có đi học và  <b className="text-danger">O</b> là không đi học</span>
        </div>
        </>}
    </AkkhorLayout>
    )
}
