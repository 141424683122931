import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterClassWeek from "../../component/FilterClassWeek";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import ItemExtrac from "./ItemExtrac";
import { confirmAlert } from "react-confirm-alert";
import { StickyTable, Row, Cell } from 'react-sticky-table';
let studentChoice = {}

export default function ExtracPage(props) {
  const { idgroup, title } = props
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const [listStudent, setListStudent] = useState([]);
  const [listTeacher, setListTeacher] = useState([]);
  const [listOnday, setListOnday] = useState(null);
  const [classChoice, setClassChoice] = useState(null);
  const [weekChoice, setWeekChoice] = useState(0);
  const [dayChoice, setDayChoice] = useState(null);
  const [timeNotes, setTimeNotes] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timetableInfo, setTimetableInfo] = useState(null);

  const typecheck = 4;
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice && year_id) {
      setListStudent([])
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_class?page=1&limit=500&class_id=" + classChoice + '&year_id=' + year_id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListStudent(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, user, year_id]);
  //Lấy danh sách giáo viên dạy lớp này
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null && year_id) {
      setListTeacher([])
      Axios.get(URL_LIST_ALL + "teacher_class?page=1&limit=500&class_id=" + classChoice + '&year_id=' + year_id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListTeacher(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, user, year_id])
  //Lấy danh sách chuyên cần
  useEffect(() => {
    let mounted = true;
    if (classChoice && weekChoice && dayChoice) {
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=" + typecheck + "&class_id=" + classChoice + '&week=' + weekChoice + "&date=" + dayChoice, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) {
              let listOnday = {};
              res.data.data.forEach(element => {
                listOnday[element.student_id] = element;
              });
              setListOnday(listOnday);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      //Lấy thông tin notes 
      setTimeNotes(null);
      Axios.get(URL_LIST_ALL + "timetable_notes?limit=1&typecheck=" + typecheck + "&class_id=" + classChoice + "&week=" + weekChoice + "&date=" + dayChoice, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) {
            if (res.data.data.length > 0) setTimeNotes(res.data.data[0]);
          }
        } else console.log(res.data.message);
      })
        .catch((err) => console.log(err));
    } else {
      setListOnday(null);
    }
    return () => (mounted = false);
  }, [classChoice, weekChoice, user, dayChoice, reload]);

  useEffect(() => {
    let mounted = true;
    if (timeNotes && timeNotes.id) {
      Axios.get(URL_LIST_ALL + "timetable_info/"+timeNotes.id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if(mounted)  setTimetableInfo(res.data.data.title)
          } else {
            setTimetableInfo('')
            console.log(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [timeNotes,user])

  const choiceItem = (e) => {
    studentChoice[e.student_id] = e;
  }
  const callApi = () => {
    setLoading(true)
    let data = Object.values(studentChoice);
    Axios.post(URL_LIST_ALL + "students_onday", data, user.config).then(res => {
      setLoading(false)
      if (res.data.status === 'success') {
        setReload(!reload);
        confirmAlert({ title: 'Thành công', message: 'Bạn đã lưu thành công', buttons: [{ label: 'OK' }] });
      }
      else alert(res.data.message)
    }).catch(err => {
      setLoading(false);
      console.log(err)
    });
  }


  const formik = useFormik({
    initialValues: { notes: timeNotes ? (timeNotes.notes || '') : '',title: timetableInfo },
    enableReinitialize: true,
    validationSchema: null,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      if (timeNotes) {
        Axios.put(URL_LIST_ALL + "timetable_notes/" + timeNotes.id + '?year_id=' + year_id, values, user.config).then(res => {
          callApi();
        }).catch(err => console.log(err));
      } else {
        let data = {
          class_id: classChoice,
          week: weekChoice,
          date: dayChoice,
          typecheck,
          notes: values.notes,
          status: 1
        }
        Axios.post(URL_LIST_ALL + "timetable_notes?year_id=" + year_id, data, user.config).then(res => {
          callApi();
        }).catch(err => console.log(err));
      }
    },
  });


  const createDataForItem = (student, onday) => {
    let obj = onday ? onday : { violation: 0, notes: '', notes2: '', status: 1 }
    let data = {
      ...student,
      student_id: student.id,
      week: weekChoice,
      date: dayChoice,
      time: null,
      orderonday: 10,
      typecheck,
      violation: obj.violation,
      notes: obj.notes,
      notes2: obj.notes2,
      status: obj.status
    };
    delete data.id;
    if (!studentChoice.hasOwnProperty(student.id))
      studentChoice[student.id] = data;
    return data;
  }

  const checkUser = () => {
    let find = false;
    listTeacher.map((item) => {
      if (user.data.id === item.id) {
        find = true
        return true;
      }
      return item;
    })
    return find;
  }


  return (
    <AkkhorLayout idrole="extrac">
      <h4 className="mt-2">{title}</h4>
      <FilterClassWeek
        disableeGrade={true}
        classChoice={classChoice}
        setClassChoice={setClassChoice}
        weekChoice={weekChoice}
        setWeekChoice={setWeekChoice}
        dayChoice={dayChoice}
        setDayChoice={setDayChoice}
        idgroup={idgroup}
        typeclass={2}
      ></FilterClassWeek>
      <div className="d-flex my-2">
        Giáo viên: {listTeacher.map((teacher) => <b key={teacher.id} className="ml-2"> {teacher.fullname} ,</b>)}
      </div>
      <div style={{ width: '100%', height: '600px' }}>
        <StickyTable leftStickyColumnCount={2} borderWidth={0}>
          <Row className="bg-secondary text-light font-weight-bold" style={{ fontSize: 14 }}>
            <Cell className="bg-secondary text-center">#</Cell>
            <Cell className="bg-secondary text-center">Họ và tên</Cell>
            <Cell className="bg-secondary text-center">Giới tính</Cell>
            <Cell className="bg-secondary text-center">Có mặt</Cell>
            <Cell className="bg-secondary text-center">Đến muộn</Cell>
            <Cell className="bg-secondary text-center">Vắng có phép</Cell>
            <Cell className="bg-secondary text-center">Vắng không phép</Cell>
            <Cell className="bg-secondary text-center">Vi phạm</Cell>
            <Cell className="bg-secondary text-center">Ghi chú</Cell>
          </Row>
          {listOnday && <>{listStudent.map((item, i) => (
            <Row key={i}>
              <ItemExtrac
                i={i}
                data={createDataForItem(item, (listOnday && listOnday[item.id] ? listOnday[item.id] : null))}
                choiceItem={choiceItem}
                isaction={checkUser()}
              ></ItemExtrac>
            </Row>
          ))}
          </>}
        </StickyTable>
      </div>
      {checkUser() ? <div className="d-flex mt-4">
        <div style={{ width: 600 }} >
          <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <textarea className="form-control" rows={4} placeholder="Tên bài học" {...formik.getFieldProps("title")} ></textarea>
            </div>
            <button type="submit" disabled={loading} className="btn btn-success btn-block btn-lg">Lưu</button>
          </form>
        </div>

      </div> : <p className="text-danger mt-3"><i>Bạn không có quyền điểm danh tiết ngoại khoá này</i></p>}
    </AkkhorLayout>
  );
}
