import Axios from 'axios';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { URL_LIST_ALL } from '../configs/api';
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";

export default function ChangeTeaching(props) {
    const { user } = useSelector((state) => state.auth);
    const { timeTable, onChange } = props;
    const [show, setShow] = useState(false);

    const formik = useFormik({
        initialValues: timeTable,
        validationSchema: null,
        onSubmit: (values, { setSubmitting }) => {
            setShow(false)
            setSubmitting(false)
            Axios.put(URL_LIST_ALL + "timetable/" + (timeTable ? timeTable.id : 0), values, user.config).then((res) => {
                if (res.data.status === 'success') {
                    onChange()
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật lịch báo giảng thành công',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            })
        },
    });


    return <>
        <button type="button" onClick={() => setShow(true)} className="btn btn-sm btn-warning">Lịch báo giảng</button>
        <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">
            <form method="post" onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Cập nhật lịch báo giảng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label">Tiết học thứ</label>
                    <input type="text" className="form-control form-control-sm"  {...formik.getFieldProps("orderonsubject")}></input>
                    <label className="form-label mt-2">Tên bài dạy</label>
                    <input type="text" className="form-control form-control-sm"  {...formik.getFieldProps("name")}></input>
                    <label className="form-label mt-2">Ghi chú</label>
                    <input type="text" className="form-control form-control-sm"  {...formik.getFieldProps("notes")}></input>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
                    <button type="submit" disabled={formik.isSubmitting} className="btn btn-sm btn-success"> Lưu</button>
                </Modal.Footer>
            </form>
        </Modal>

    </>
}