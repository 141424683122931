import React from 'react';

export default function ItemStudentHome(props) {
    const { i, timeTable } = props;
    return (
        <>
           <tr  key={i}>
                <td>{timeTable.time}</td>
                <td>{timeTable.orderonday}</td>
                <td>{timeTable.classtitle}</td>
                <td>{timeTable.fullname}</td>
                <td>{timeTable.title}</td>
                <td>{timeTable.name}</td>
            </tr>
           
        </>
    );
}
