import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'

export default function QChooseMulti(props) {
    const {item,currentAnswer} = props;
    const {Answers} = useSelector(state=>state.save);
    const dispatch = useDispatch();
    const answers = item.answers
    const [choice, setChoice] = useState(currentAnswer||{})
    const chooseItem=(index,stt) => {
        let newChoice = {...choice}
        if(stt)newChoice[index]= true;
        else delete newChoice[index]
        setChoice(newChoice)
        let percent = 0
        if(answers.correct && answers.correct.length === Object.keys(newChoice).length)
        {
            answers.correct.map((i)=>{
                if(newChoice[i]===true) percent += 100/answers.correct.length
                return i
            });
        }
        let newListAnswers = {...Answers}
        newListAnswers[item.id] = {question:item, answers:newChoice, percent: percent === 100 ? 100 : 0};
        dispatch(saveAnswers(newListAnswers))
    }
    return (<div className="col-12 form-group mt-3">
        <ul>
        {answers &&<>
        {answers.list.map((item,i)=><li key={i} className="d-flex mb-2 align-items-center " >
            {LIST_ALPHA[i]}. <div  className={"py-1 px-2 cursor-pointer rounded "+(choice[i]?' btn-info':' btn-secondary')} onClick={()=>chooseItem(i,choice[i]?false:true)}>{parse(item)}</div>
            </li>)}
        </>}
        </ul>
    </div>);
}