import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../../layout/akkhor";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../../component/UserDropDown";

import Spinner from "../../component/Spinner";
import FormQuestionFormat from "./FormQuestionFormat";
import ExamTree from "../../component/ExamTree";
import AlertErrorForm from "../../component/AlertErrorForm";
import Loading from "../../component/Loading";
import SelectTags from "../../component/SelectTags";

export default function FormQuestionBank(props) {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({ list: null, correct: null });

  const [maxNumber, setMaxNumber] = useState(4);//setMaxNumber

  useEffect(() => {
    let mounted = true;
    if (id > 0) {
      setLoading(true)
      Axios.get(URL_LIST_ALL + "at_exam_questions/" + id, user.config)
        .then(async (res) => {
          setLoading(false)
          if (res.data.status === "success") {
            if (mounted) {
              let _data = res.data.data
              const _answers = _data.answers ? JSON.parse(_data.answers) : []
              const _corrects = _data.corrects ? JSON.parse(_data.corrects) : []
              setDetail(res.data.data)
              setAnswers({ list: _answers, correct: _corrects });
              let _max = _answers ? _answers.length : 0;
              _max = (_corrects && (_corrects.length > 0 && _corrects.length) > _max ? _corrects.length : _max)
              setMaxNumber(_max > 0 ? _max : 1);
            }
          } else console.log(res.data.message);
        }).catch((err) => { setLoading(false); console.log(err) });
    }
    return () => (mounted = false);

  }, [user, id, refresh]);

  const formik = useFormik({
    initialValues: detail ? detail : { school_id, type_id: "", author_id: user.data.id, level_id: "", grade_id: "", subject_id: "", scope_id: "", tags: "", answers: {}, corrects: {}, title: "", status: 1, is_child: null, children: [], listtree: [0, -1, -1, -1] },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      grade_id: Yup.string().trim().nullable().required("Chọn khối"),
      subject_id: Yup.string().trim().nullable().required("Chọn môn học"),
      level_id: Yup.string().trim().nullable().required("Chọn mức độ  nhận biết "),
      scope_id: Yup.string().trim().nullable().required("Chọn phạm vi sử dụng"),
      type_id: Yup.string().trim().nullable().required("Chọn loại câu hỏi"),
      title: Yup.string().trim().nullable().required("Nhập nội dung câu hỏi"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      values.answers = JSON.stringify(answers.list);
      values.corrects = JSON.stringify(answers.correct);

      if (id > 0) {
        Axios.put(URL_LIST_ALL + "at_exam_questions/" + id, values, user.config)
          .then((res) => {
            setLoading(false);
            setSubmitting(false);
            setRefresh(!refresh)
            if (res.data.status === "success") {
              if (!refresh) resetForm();
              confirmAlert({ title: "Thành công", message: "Bạn cập nhật câu hỏi thành công", buttons: [{ label: "OK",onClick:()=>{window.location.reload()} }], });
            } else {
              confirmAlert({ title: "Lỗi", message: res.data.message, buttons: [{ label: "OK" }], });
            }
          })
          .catch((err) => console.log(err));
      } else {
        Axios.post(URL_LIST_ALL + "at_exam_questions", values, user.config)
          .then((res) => {
            setLoading(false);
            setSubmitting(false);
            setRefresh(!refresh)
            if (res.data.status === "success") {
              
              confirmAlert({ title: "Thành công", message: "Bạn thêm mới câu hỏi thành công", buttons: [{ label: "OK" ,onClick:()=>{window.location.reload()}}] });
            } else {
              confirmAlert({ title: "Lỗi", message: res.data.message, buttons: [{ label: "OK" }], });
            }
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <AkkhorLayout idrole="exam_question">
      {loading && (<Loading buttonhide={true} />)}
      <div className="card height-auto mt-4">
        <div className="card-body ">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>Thêm câu hỏi</h3>
            </div>
          </div>
          <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label>Khối <span className="text-danger">*</span></label>
                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} formik={formik} name="grade_id"
                  onChange={async (e) => { await formik.setFieldValue("grade_id", e); }}
                ></UserDropDown>
                <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label> Môn học <span className="text-danger">*</span></label>
                <UserDropDown apiname={"subjects?key=&page=1&istree=1&orderby=title-ASC&limit=50&school_id=" + school_id + "&grade_id=" + formik.values.grade_id} placeholder="Chọn môn học" colvalue={"subjects.id"} colname={"subjects.title"} formik={formik}
                  name="subject_id" onChange={async (e) => { await formik.setFieldValue("subject_id", e); }}
                ></UserDropDown>
                <AlertErrorForm formik={formik} name="subject_id"></AlertErrorForm>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label> Phạm vi sử dụng <span className="text-danger">*</span></label>
                <UserDropDown apiname={"exam_param?page=1&limit=500&orderby=title-ASC&idtype=5&school_id=" + school_id} placeholder="Chọn phạm vi" colvalue={"exam_param.id"} colname={"exam_param.title"} name="scope_id" formik={formik}
                  onChange={async (e) => { await formik.setFieldValue("scope_id", e); }}></UserDropDown>
                <AlertErrorForm formik={formik} name="scope_id"></AlertErrorForm>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label> Mức độ nhận biết <span className="text-danger">*</span> </label>
                <UserDropDown apiname={"exam_param?page=1&limit=500&orderby=title-ASC&idtype=4&school_id=" + school_id} placeholder="Chọn mức độ" colvalue={"exam_param.id"} colname={"exam_param.title"} name="level_id" formik={formik}
                  onChange={async (e) => { await formik.setFieldValue("level_id", e); }}></UserDropDown>
                <AlertErrorForm formik={formik} name="level_id"></AlertErrorForm>
              </div>

              <ExamTree grade_id={formik.values.grade_id} subject_id={formik.values.subject_id} onChange={async (e) =>
                await formik.setFieldValue("tree_id", e)
              } value={formik.values.listtree} />

              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label>Câu hỏi cha</label>
                <UserDropDown apiname={"at_exam_questions?dropdown=1&page=1&limit=500&orderby=title-ASC&type_id=5&school_id=" + school_id} placeholder="Chọn mức độ" colvalue={"at_exam_questions.id"} colname={"at_exam_questions.title"} name="parent_id" formik={formik}
                  onChange={async (e) => { await formik.setFieldValue("parent_id", e); }}></UserDropDown>
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label>Chọn thẻ </label>
                <SelectTags apiname={"at_tags_questions?key=&page=1&orderby=title-ASC&limit=500&grade_id=" + (formik.values.grade_id) + "&school_id=" + school_id + "&subject_id=" + (formik.values.subject_id)} placeholder="Tất cả thẻ" detail={detail} colvalue={'at_tags_questions.alias'} formik={formik} colname={'at_tags_questions.title'} name='tags'
                  onChange={async (e) => { await formik.setFieldValue('tags', e); }}
                ></SelectTags>
                <AlertErrorForm formik={formik} name="tags"></AlertErrorForm>
              </div>

              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                <label>Trạng thái</label>
                <select className="form-control height32" {...formik.getFieldProps("status")}>
                  <option value="0">Khoá</option>
                  <option value="1">Hoạt động</option>
                </select>
              </div>


              <div className="col-12 form-group mg-t-8">
                <FormQuestionFormat onRefresh={() => setRefresh(!refresh)} formik={formik} answers={answers} setAnswers={setAnswers}
                  detail={detail} maxNumber={maxNumber} setMaxNumber={setMaxNumber} />
                <AlertErrorForm formik={formik} name="type_id"></AlertErrorForm>
              </div>

              <div className="col-12 form-group mg-t-8">
                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {loading ? <Spinner /> : <>Lưu câu hỏi</>}
                </button>
                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">
                  Nhập lại
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
