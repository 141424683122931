import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from "../../layout/akkhor";
import FilterGradeClassWeek from "../../component/FilterGradeClassWeek";
import Moment from "react-moment";
import { useLocation } from "react-router";

let arr = {
  '1': {//Điểm danh tiết học
    title: 'Điểm danh tiết học',
    status: {
      '1': { title: 'Đúng giờ', color: '#007bff' },
      '2': { title: 'Đến muộn', color: '#efd732' },
      '3': { title: 'Vắng có phép', color: '#fd7a23' },
      '4': { title: 'Vắng không phép', color: '#db0d18' }
    }
  },
  '2': {//vở ghi chép
    title: 'Kiểm tra vở ghi chép',
    status: {
      '2': { title: 'Đủ, cẩu thả', color: '#efd732' },
      '3': { title: 'Ghi chép thiếu', color: '#fd7a23' },
      '4': { title: 'Không ghi chép', color: '#db0d18' }
    }
  },
  '3': {//vở bài tập
    title: 'Kiểm tra vở bài tập',
    status: {
      '1': { title: 'Đã hoàn thành', color: '#007bff' },
      '2': { title: 'Chưa hoàn thành', color: '#efd732' },
      '3': { title: 'Không làm', color: '#db0d18' }
    }
  }
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ReportCheckPage() {
  let query = useQuery();
  const tc = query.get('typecheck');
  const { user } = useSelector((state) => state.auth);
  const [classChoice, setClassChoice] = useState(null);
  const [gradeChoice, setGradeChoice] = useState(null);
  const [subjectChoice, setSubjectChoice] = useState(null);
  const [dayChoice, setDayChoice] = useState(null);
  const [typecheck, setTypeCheck] = useState(tc ? parseInt(tc) : 1);
  const [list, setList] = useState([]);
  useEffect(
    function () {
      let mounted = true;
      if (typecheck && dayChoice) {
        if (mounted) setList([]);
        let url = URL_LIST_ALL + "report_check?date=" + dayChoice + "&typecheck=" + typecheck
        if (gradeChoice > 0) url += "&grade_id=" + gradeChoice;
        if (classChoice > 0) url += "&class_id=" + classChoice;
        if (subjectChoice > 0) url += "&subject_id=" + subjectChoice;
        Axios.get(url, user.config).then((res) => {
          if (res.data.status === "success") {
            if (mounted) setList(res.data.data);
          } else console.log(res.data.message);
        }).catch((err) => console.log(err));
      }
      return () => (mounted = false);
    },
    [user, dayChoice, gradeChoice, classChoice, typecheck,subjectChoice]
  );
  //user, dayChoice, typecheck
  const getStatus = (stt) => {
    const obj = arr[typecheck].status['' + stt]
    if (obj) return <span style={{ color: obj.color }}>{obj.title}</span>
    else return null;
  }

  const removeDuplicate = (_data, _date) => {
    setDayChoice(_date)
  }

  return (
    <AkkhorLayout idrole="report.report-check">
      <div className="card height-auto mt-4">
        <div className="card-body">
          {Object.values(arr).map((item, i) => <span key={i} className="mr-2 ">
            <button type="button" className={typecheck === (i + 1) ? "btn btn-sm btn-primary" : "btn btn-sm btn-secondary"} onClick={() => setTypeCheck(i + 1)}>{item.title}</button>
          </span>)}
          <div className="heading-layout1">

            <div className="item-title mt-3">
              <h4>Báo cáo {arr[typecheck].title} </h4>
            </div>
          </div>
          <FilterGradeClassWeek
            classChoice={classChoice}
            setClassChoice={setClassChoice}
            gradeChoice={gradeChoice}
            setGradeChoice={setGradeChoice}
            subjectChoice={subjectChoice}
            setSubjectChoice={setSubjectChoice}
            dayChoice={dayChoice}
            onListTimeTable={removeDuplicate}
          ></FilterGradeClassWeek>
          {list.length > 0 && <>
            <table className="table table-sm table-hover table-striped mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lớp</th>
                  <th>Tiết thứ</th>
                  <th>Môn học</th>
                  <th>Học sinh</th>
                  <th>Mã</th>
                  <th>Tình trạng</th>
                  <th>Ngày giờ</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.classtitle}</td>
                  <td>Tiết {item.orderonday}</td>
                  <td>{item.subjecttitle}</td>
                  <td>{item.fullname}</td>
                  <td>{item.code}</td>
                  <th>{getStatus(item.status)}</th>
                  <td><Moment format="H:m DD/MM/YYYY">{new Date(item.datecreate * 1000)}</Moment></td>
                </tr>
                )}
              </tbody>
            </table></>}
        </div>
      </div>
    </AkkhorLayout>
  );
}
