import Axios from "axios";
import React, { useEffect, useState } from "react";
import AkkhorLayout from '../../layout/akkhor';
import { useDispatch,useSelector } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import { URL_LIST_ALL } from "../../configs/api";
import ChoiceQuestion from "../questions/ChoiceQuestion";
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import moment from "moment";
import TimeOut from "./TimeOut";
import ActionSend from "./ActionSend";
import parse from 'html-react-parser'
import { confirmAlert } from "react-confirm-alert";
import { saveAnswers } from "../../redux/Actions";
const _ = require('lodash');


export default function DetailExam(props) {
  const  {id}  = useParams();
  let history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const {Answers} = useSelector(state=>state.save);
  const [detail, setDetail] = useState(null);
  const [listQuestion, setListQuestion] = useState([]);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [now] = useState(moment(new Date()));
  const [formatMask,setFormatMask] = useState('HH:mm:ss');
  const [timeOut, setTimeOut] = useState(false);
  const [considers, setConsiders] = useState(null);
  const [sendSuccess, setSendSuccess] = useState(false);// eslint-disable-line
  const dispatch = useDispatch();

  useEffect(() => {
    if (id>0) {
        let mounted = true;
        Axios.get(URL_LIST_ALL  + "at_exam_tests/"+id,user.config).then( async (res) => {
            if (res.data.status === "success" && mounted){
              const _detail = res.data.data;
              const localTime = _detail.time;
              if(localTime>=60)setFormatMask('HH:mm:ss');
              else setFormatMask('mm:ss');
              now.add(localTime, 'minutes')//minutes
              setDetail(_detail);
              let _listquestion=[];
              const questions = _detail.questions
              Object.values(questions).map(ques => {
                ques.list.map((q)=>{
                
                  if(q.code ==='QMatching'){
                    q.corrects = _.shuffle(q.corrects);
                    console.log(q.corrects);
                  }
                  q.answers = {list:q.answers,correct:q.corrects}
                  _listquestion.push(q);
                  return q;
                })
                return (ques)
              })
              setListQuestion(_listquestion);
              if(_detail.currentAnswer){
                dispatch(saveAnswers(_detail.currentAnswer))
              }
            } 
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id,now]);// eslint-disable-line
  
  
  
  const checkActiveQues=(index,indexcurrent) => {
    const _ques = listQuestion[index];
    if(index === indexcurrent)return 'bg-primary text-light';
    else if(considers && considers.hasOwnProperty(_ques.id)) return 'bg-warning text-drak';
    else if(Answers && Answers.hasOwnProperty(_ques.id)) return 'bg-success text-light';
    else return 'bg-light text-drak';
  }
  
  const onChangeConsider=(e) => {
    const stt = e.target.checked;
    const idques = e.target.value;
    let _localconsider = {...considers};
    if(stt)
    {
      _localconsider[idques] = true;
    }else{
      delete _localconsider[idques]
    }
    setConsiders(_localconsider)
  }

  const onSuccess=(status) => {
    setSendSuccess(true)
    const mess = status === 1 ? "nộp bài" : "lưu nháp"
    confirmAlert({ title: "Thành công",message: "Bạn đã thực hiện "+mess+" thành công", buttons: [{ label: "OK" ,onClick: () => {
        history.goBack()
      }}],
    });
  }

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
    {detail && listQuestion && (<div  className="container-fluid">
    <div className="breadcrumbs-area py-3">
      <ul>
          <li>
              <Link to="/">Trang chủ</Link>
          </li>
          <li>{detail.type===1?"Phiếu bài tập":"Bài kiểm tra"}</li>
      </ul>
  </div>
        <div className="card height-auto">
          <div className="card-body">
            <div>
              <div className="row">
                <div className="col-sm-9">
          {/*localResult&&<div className="p-3">
          <h1 className="text-danger text-center">Kết quả: {localResult.point} điểm ({localResult.number}/{localResult.total})</h1>
          </div>*/}
           <h2 className="text-center mb-3">{detail.title}</h2>
          {listQuestion.length>0 && listQuestion[indexQuestion]&&(
            <div className="mb-2 border rounded overflow-hidden " id={"nuques-"+indexQuestion}>
              <div>
                <div className="font-weight-bold p-2 bg-info text-light d-flex justify-content-between">
                  <div>Câu hỏi {indexQuestion + 1}</div>
                  <div className="text-warning"><input type="checkbox" checked={(considers && considers[listQuestion[indexQuestion].id])?true:false} onChange={onChangeConsider} value={listQuestion[indexQuestion].id} /> Đang cân nhắc</div>
                </div>
                {listQuestion[indexQuestion].parent_title && <div className="p-2 border-bottom">{parse(listQuestion[indexQuestion].parent_title)}</div>}
                <div className="p-3">{parse(listQuestion[indexQuestion].title)}</div>
                <div>
                  <ChoiceQuestion
                    key={indexQuestion}
                    index={indexQuestion}
                    item={listQuestion[indexQuestion]}
                    test={detail}
                    action={false}
                  ></ChoiceQuestion>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <div>{indexQuestion>0&&<button className="btn btn-info" onClick={()=>setIndexQuestion(indexQuestion-1)} >Câu trước</button>}</div>
            <div>{indexQuestion<listQuestion.length-1&&<button className="btn btn-info" onClick={()=>setIndexQuestion(indexQuestion+1)} >Câu tiếp</button>}</div>
          </div>
           </div>
           <div className="col-sm-3">
           {!timeOut&&<TimeOut className="p-3" now={now} formatMask={formatMask} onTimeOut={setTimeOut}></TimeOut>}
           <h5 className="mb-3 text-warning">Hiện tại: <Moment interval={1000} format="DD/MM/YYYY HH:mm:ss"></Moment></h5>
           <ActionSend timeOut={timeOut} listQuestion={listQuestion} detail={detail} test="1" onSuccess={(val)=>{onSuccess(val)}}/>
           <div className="border rounded p-2 mt-3">
            <div className="text-center pb-4"><b>Danh sách câu hỏi</b></div>
            <div className="container-fluid">
            <div className="row">
            {listQuestion.map((item, index) => (<div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-3" style={{padding:5}}>
              <div onClick={()=>setIndexQuestion(index)}  className={"border rounded  mb-2 text-center font-weight-bold cursor-pointer "+checkActiveQues(index,indexQuestion)}>{index+1}</div>
              </div>))}
            </div>
            </div>
          </div>
          <div className="border rounded p-2 mt-3">
            <div className="text-center pb-4"><b>Chú thích</b></div>
            <div className="container-fluid">
            <ul>
              <li className="d-flex mb-3"><div className="border rounded p-2 mr-2 px-3 bg-light"></div> Câu hỏi chưa trả lời</li>
              <li className="d-flex mb-3"><div className="border rounded p-2 mr-2 px-3 bg-primary"></div> Câu hỏi hiện tại</li>
              <li className="d-flex mb-3"><div className="border rounded p-2 mr-2 px-3 bg-warning"></div> Câu hỏi đang cân nhắc</li>
              <li className="d-flex mb-3"><div className="border rounded p-2 mr-2 px-3 bg-success"></div> Câu hỏi đã trả lời</li>
            </ul>
            </div>
          </div>
           </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    )}
    </AkkhorLayout>
  );
}
