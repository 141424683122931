import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'
const _ = require('lodash');

export default function QDropDrag(props) {
    const { item, currentAnswer } = props;
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();

    const answers = item.answers
    const [corrects, setCorrects] = useState(currentAnswer || answers.list)
    const [temp, setTemp] = useState(null)

    const move = (arr, index1, index2) => {
        var temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
    }

    const changePostion = (index) => {
        if (temp === null) {
            setTemp(index)
        } else {
            let newCorrect = [...corrects]
            move(newCorrect, temp, index)
            setCorrects(newCorrect)
            setTemp(null)

            let newListAnswers = { ...Answers }
            newListAnswers[item.id] = { question: item, answers: newCorrect, percent: _.isEqual(newCorrect, answers.correct) ? 100 : 0 };
            dispatch(saveAnswers(newListAnswers))
        }
    }


    return (<div className="col-12 form-group mt-3">
        {answers && corrects && <>
            {
                corrects.left ?
                    corrects.left.map((item, i) => <button key={i} className={"mb-2 btn btn-sm mr-3 " + (temp === i ? "btn-info" : "btn-secondary")} onClick={() => changePostion(i)}>{parse(item)}</button>)
                    :
                    corrects.map((item, i) => <button key={i} className={"mb-2 btn btn-sm mr-3 " + (temp === i ? "btn-info" : "btn-secondary")} onClick={() => changePostion(i)}>{parse(item)}</button>)
            }
            <div className="mt-3"><i>Click chọn các câu trả lời để đổi chỗ cho nhau</i></div>
        </>}
    </div>);
}