import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import { confirmAlert } from "react-confirm-alert";

export default function ChangeClass(props) {
  const { refresh, title, index, selected, row } = props;
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [show, setShow] = useState(false);
  const [listClass, setListClass] = useState([]);
  //Lấy danh sách lớp
  useEffect(() => {
    let mounted = true;
    setListClass([]);
    if (show === true && year_id>0 && school_id>0) {
      Axios.get(URL_LIST_ALL + "class?page=1&limit=500&school_id=" + school_id + '&year_id=' + year_id + '&orderby=id-ASC', user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) {
            let _list = []
            res.data.data.map((item => {
              if(selected)
              {
                const check = selected.split(',').includes(item.title)
                item.checked = check
              }
              _list.push(item)
              return item;
            }))
            setListClass(_list);
          }
        } else console.log(res.data.message);
      }).catch(err => console.log(err));
    }
    return () => (mounted = false);
  }, [user, school_id, selected, show, year_id]);// eslint-disable-line

  //Sự kiện chọn lớp trên danh sách
  const onChoiceClass = (row, index, stt) => {
    const student = { ...row, checked: stt }
    let newClass = listClass.slice();;
    newClass[index] = student;
    setListClass(newClass);
  }



  const saveClass = () => {
    let classChoice = [];
    listClass.map((item) => {
      if (item.checked) classChoice.push(item.id);
      return (item)
    })
    Axios.put(URL_LIST_ALL + 'students_multi_class/' + row[0]+'?year_id='+year_id, { listclass: classChoice, year_id }, user.config).then((res) => {
      setShow(false)
      if (res.data.status === 'success') {
        if (refresh) refresh();
        confirmAlert({ title: 'Thành công', message: 'Cập nhật lại lớp cho học sinh thành công', buttons: [{ label: 'OK' }] });
      } else {
        confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
      }
    }).catch((err) => {
      setShow(false)
      console.log(err)
    })
  }

  return (
    <>
      <a href={"#class" + index} className="text-info" onClick={() => setShow(true)}>{title?title:'Thêm'}</a>
      <Modal show={show} onHide={() => setShow(false)} animation={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Thay đổi lớp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="row">
            {listClass.map((item, i) => <li key={item.id} className="col-6 col-sm-3 col-md-4" id={"class" + item.id}>
              <input type="checkbox" checked={item.checked} htmlFor={"class" + item.id} value={item.id} style={{ margin: 5 }} onChange={(e) => onChoiceClass(item, i, e.target.checked)} /> <label>{item.title}</label>
            </li>)}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>Đóng</Button>
          <Button variant="success" onClick={saveClass}>Lưu</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
