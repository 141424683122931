import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, {useState  } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "react-moment";
import AkkhorLayout from "../../layout/akkhor";
import UserDropDown from "../../component/UserDropDown";
import SRList from "../../component/List";

export default function MarkTests(props) {
  const {disableAction} = props;
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id>0?user.data.class.grade_id:0;
  const localclass_id = user && user.data && user.data.class && user.data.class.class_id>0?user.data.class.class_id:0;
  const [params, setParam] = useState('istest=1&school_id='+school_id);
  const [keyword, setKeyword] = useState('');
  const [grade_id, setGrade_id] = useState(localgrade_id>0?localgrade_id:'all');
  const [class_id, setClass_id] = useState(localclass_id>0?localclass_id:'all');
  const [students_id, setStudents_id] = useState('all');
  const [subject_id, setSubject_id] = useState('all');
  const [type_id, setType_id] = useState('all');

  const handleCallback = (start, end, label) =>{
    setStartDate(start)
    setEndDate(end)
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = 'istest=1&school_id='+school_id
    if(grade_id > 0) _param +="&grade_id="+grade_id
    if(class_id > 0) _param +="&class_id="+class_id
    if(subject_id > 0) _param +="&subject_id="+subject_id
    if(students_id > 0) _param +="&students_id="+students_id
    if(type_id > 0) _param +="&type_id="+type_id
    if(keyword !=='') _param +="&key="+keyword
    if(startDate>0 && endDate>0) _param +="&daterange="+ startDate.format('YYYY-MM-DD')+"_"+endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "test_title", label: "Tên bài kiểm tra", options: { filter: true, sort: true } },
    {name: "datecreated", label: "Ngày đăng", options: { filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      },
    }
  ];
  columns.push({name: "id", label: "#", options: { filter: false, sort: true,
    customBodyRender: (value) => {
      return <Link to={"/marktests-student/"+value} className="btn btn-sm btn-info" >Chấm bài</Link>;
    }
  }})
  
  return (
    <AkkhorLayout idrole="marktests" roleignone={user.data.typeaccount===2}>
      <div className="card pb-0 height-auto mt-4">
        <div className="card-body">
          <div className="d-flex pb-4">
            <div className="mr-auto">
              <h3>Tìm kiếm bài kiểm tra đã làm</h3>
            </div>
          </div>
          <form className="new-added-form" method="get" >
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label>Từ khoá </label>
                <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e)=>setKeyword(e.target.value)} className="form-control height32"  autoComplete="off"/>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label>Khối </label>
                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"}  name="grade_id" defaultValue={grade_id}
               onChange={async (e) => {setGrade_id(e)}}  all={true} disabled={user.data.typeaccount===2}
                ></UserDropDown>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label>Lớp </label>
                <UserDropDown apiname={"class?key=&page=1&limit=500&grade_id=0" +grade_id+ "&year_id=" + year_id} placeholder="Tất cả lớp" defaultValue={class_id} colvalue={'class.id'} colname={'class.title'} name='class_id'  
                  onChange={async(e)=>{ setClass_id(e);}}  all={true} disabled={user.data.typeaccount===2}
                ></UserDropDown>
             </div>
             <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label>Học sinh</label>
                <UserDropDown apiname={"students?key=&page=1&limit=500&class_id="+ (class_id>0?class_id: '100')} placeholder="Tất cả lớp" defaultValue={students_id} colvalue={'students.id'} colname={'students.fullname'} name='students_id'  
                  onChange={async(e)=>{ setStudents_id(e);}}  all={true} disabled={user.data.typeaccount===2}
                ></UserDropDown>
             </div>
              <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label> Môn học </label>
                <UserDropDown apiname={ "subjects?key=&page=1&limit=50&school_id="+user.data.school_id+"&grade_id=" + grade_id} placeholder="Chọn môn học"  defaultValue={subject_id} colvalue={"subjects.id"} colname={"subjects.title"} name="subject_id"
                onChange={async (e) => {setSubject_id(e)}} all={true}
                ></UserDropDown>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 form-group">
                <label> Loại bài kiểm tra </label>
                <UserDropDown apiname={"exam_param?page=1&limit=500&idtype=1&school_id="+school_id} placeholder="Chọn loại" colvalue={"exam_param.id"} defaultValue={type_id} colname={"exam_param.title"} name='type_id'  
                onChange={async (e) => {setType_id(e)}} all={true}></UserDropDown>
              </div>
              <div className="col-sm-6 col-12 form-group">
                <label> Thời gian </label>
                <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: ""  }}
                    onCallback={handleCallback}
                    >
                    <div className='d-flex align-items-center'>
                        <span style={{whiteSpace:'nowrap'}} className="mr-2">Từ ngày</span>
                        <input
                          className="form-control h-31 form-control-sm"
                          placeholder='Từ ngày'
                          readOnly={true}
                          onChange={(e)=> setStartDate(e)}
                          value={startDate>0 ? startDate.format('YYYY-MM-DD'): ""}
                        />
                        <span className='fas fa-arrow-right mx-4' />
                        <span style={{whiteSpace:'nowrap'}} className="mr-2">Đến ngày</span>
                        <input
                        className="form-control h-31 form-control-sm"
                          placeholder='Đến ngày'
                          readOnly={true}
                          onChange={(e)=> setEndDate(e)}
                          value={endDate>0 ? endDate.format('YYYY-MM-DD'): ""}
                        />
                      </div>
                    </DateRangePicker>
              </div>
              <div className="col-12 form-group mg-t-8">
                <button type="submit" onClick={handleSubmit} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              </div>
            </div>
          </form>
        </div>
        <div className="card-footer pl-0 pr-0 pt-3 pb-0">
            <SRList name="exam_tests_check" idrole="marktests"  params={params} title="Danh sách bài kiểm tra đã làm"  defaultLimit={50} serverSide={true} noAction={disableAction} onlyList={true} columns={columns}></SRList>
        </div>
      </div>
    </AkkhorLayout>
  );
}
