import React from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import DashboardTeacher from "./DashboardTeacher";
import DashboardAdmin from "./DashboardAdmin";
import DashboardStudent from "./DashboardStudent";

export default function HomePage() {
  const { user } = useSelector((state) => state.auth);
  
  return (
    <AkkhorLayout idrole="dashboard" roleignone={user.data.typeaccount===2}>
        {user.data.typeaccount===0&&<DashboardAdmin />}
        {user.data.typeaccount===1&&<DashboardTeacher />}
        {user.data.typeaccount===2&&<DashboardStudent />}
    </AkkhorLayout>
  );
}
