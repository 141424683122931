import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useParams } from "react-router-dom";
import AVatarStudent from "../assets/img/avata_student.png";
var _ = require('lodash');

export default function DetailStudentsPage() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [detail, setDetail] = useState([]);
    const [refresh] = useState(false);

    //Lấy thông tin học sinh
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "students/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                const data = _.pickBy(res.data.data, v => v !== null && v !== undefined && v !== '');
                                setDetail(data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    console.log(detail)

    //   const obj = JSON.parse(detail.info);

    return (
        <AkkhorLayout idrole="list-students">
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Hồ sơ Học sinh</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post">
                        <div className="row mt-4">
                            <div className="col-lg-5">
                                <div className="row">
                                    <div className="col-sm-5 align-items-center">
                                        <img src={AVatarStudent} alt="logo" width="150" />

                                    </div>
                                    <div className="col">
                                        <div><b>Họ và tên:</b> {detail.fullname}</div>
                                        <div><b>Mã học sinh:</b> {detail.code}</div>
                                        <div><b>Ngày sinh:</b> {detail.birthday}</div>
                                        <div><b>Giới tính:</b> {detail.gender === 1 ? 'Nam' : (detail.gender === 2 ? 'Nữ' : 'Khác')}</div>
                                        <div><b>Email:</b> {detail.email}</div>
                                        <div><b>Số điện thoại:</b> {detail.mobile}</div>
                                        <div><b>Lớp học:</b> {detail.listclass}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {detail.info}<br />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                Lịch học & Sự kiện
                            </div>
                            <div className="col">
                                Hoạt động hàng ngày
                            </div>
                            <div className="col">
                                Điểm hành vi
                            </div>
                            <div className="col">
                                Chuyên cần
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                Sổ điểm
                            </div>
                            <div className="col">
                                Thông sức khỏe
                            </div>
                            <div className="col">
                                CLB ngoại khóa
                            </div>
                            <div className="col">
                                Lớp chuyên
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
