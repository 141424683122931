import { CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { URL_LIST_ALL } from '../../configs/api';
import { saveChangeTimeTable } from '../../redux/Actions';
import ChangeTimeTable from './ChangeTimeTable';

export default function ItemTimeTable(props) {
    const { i, timeTable,datetimeable, allowEdit, allowChange, setRefresh, gradeChoice, classChoice, width } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const { setting } = user.data;
    const { TimeTableSave } = useSelector(state => state.save);
    const changeCalendar = () => {
        dispatch(saveChangeTimeTable(timeTable))
    }
    const toCalendar = () => {
        setLoading(true)
        let datasave = {from: TimeTableSave.id, to: timeTable.id}
        if(!timeTable.id) datasave = {from: TimeTableSave.id,indexTo:i,dateto:datetimeable,classto:classChoice}
        Axios.post(URL_LIST_ALL + 'reschedule', datasave, user.config).then(res => {
            setLoading(false)
            if (res.data.status === "success") {
                dispatch(saveChangeTimeTable(null))
                if (setRefresh) setRefresh()
                confirmAlert({
                    title: 'Thành công',
                    message: 'Bạn đã đổi lịch thành công',
                    buttons: [{ label: 'OK' }]
                });
            } else {
                confirmAlert({
                    title: 'Lỗi',
                    message: res.data.message,
                    buttons: [{ label: 'OK' }]
                });
            }
        }).catch(err => { setLoading(false); console.log(err) });
    }
    let color = '#212529';
    if (setting) {
        if (timeTable.isreplace && timeTable.isrotate) {
            color = setting.color_both;
        } else if (timeTable.isreplace) {
            color = setting.color_isreplace;
        } else if (timeTable.isrotate) {
            color = setting.color_isrotate;
        }
    }

    return (
        <>
            <div className="border p-1 align-items-center text-center" style={{ minWidth: 50, color }}>{i}</div>
            <div className="border p-1 align-items-center" style={{ minWidth: 145, color }}>{timeTable.title}</div>
            <div className="border p-1 align-items-center" style={{ minWidth: 80, color }}>{timeTable.orderonsubject}</div>
            <div className="border p-1 align-items-center" style={{ minWidth: 200, color }}>{timeTable.fullname}  {timeTable.fullname2 && <>, {timeTable.fullname2}</>} </div>
            {width > 1439 && <>
                <div className="border p-1 align-items-center" style={{ minWidth: 150, color }}>{timeTable.name}</div>
                <div className="border p-1 align-items-center" style={{ minWidth: 150, color }}>{timeTable.notes}</div>
            </>}
            {allowEdit && <div className="border p-1 align-items-center" style={{ minWidth: 90 }}>
                <Button className="btn btn-sm btn-warning" onClick={() => setShow(true)} disabled={Object.keys(timeTable).length === 0}>Dạy thay</Button>
            </div>}
            {allowChange && <div className="border p-1 align-items-center" style={{ minWidth: 80 }}>
                {TimeTableSave ? <>
                    {timeTable.id === TimeTableSave.id ? <Button disabled={Object.keys(timeTable).length === 0} className="btn btn-sm btn-block btn-secondary" >Từ</Button> : <Button className="btn btn-sm btn-block btn-primary" onClick={toCalendar}>{loading ? <CircularProgress color="secondary" size={20} value={100} /> : 'Đến'}</Button>}
                </> : <Button disabled={Object.keys(timeTable).length === 0} className="btn btn-sm btn-info btn-block" onClick={changeCalendar}>Đổi lịch</Button>}
            </div>}
            {show && <ChangeTimeTable timeTable={timeTable} setShow={setShow} setRefresh={setRefresh} gradeChoice={gradeChoice} classChoice={classChoice} />}
        </>
    );
}
