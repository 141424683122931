import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserDropDown from "../../component/UserDropDown";
import UserDropDownClass from "../../component/UserDropDownClass";
import AlertErrorForm from "../../component/AlertErrorForm";
import Spinner from "../../component/Spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ViewStep from "./ViewStep";
import { CONFIG_STEP_TEST_2 } from "../../configs";

export default function BaiKiemTraStep1(props) {
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const { nextStep, detail } = props
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [dateBegin, setDateBegin] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);

  const defaultdetail = { type: 2,year_id, school_id, author_id: user.data.id, title: '', status: 1,number:0,total_point:10, grade_id: '', subject_id: '', listclass: '', questions: {}, time: '',datebegin: '', dateend: '',dateview: '' }

  const formik = useFormik({
    initialValues: detail ? detail : defaultdetail,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      grade_id: Yup.string().trim().nullable().required('Vui lòng chọn khối'),
      type_id: Yup.string().trim().nullable().required('Vui lòng chọn loại'),
      title: Yup.string().trim().nullable().required('Nhập tên bài khảo thí').max(150, "Độ dài không quá 150 ký tự"),
      subject_id: Yup.string().trim().nullable().required('Vui lòng chọn môn'),
      time: Yup.string().trim().nullable().required('Nhập thời gian làm bài'),
      listclass: Yup.string().trim().nullable().required('Nhập số lượng lớp'),
      datebegin: Yup.string().trim().nullable().required('Nhập thời gian mở'),
      dateend: Yup.string().trim().nullable().required('Nhập thời gian đóng')
    }),

    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(false)
      nextStep(values)
    },
  });
  const handleCallback = (start, end, label) => {
    formik.setFieldValue("datebegin", Math.floor(start / 1000.0))
    formik.setFieldValue("dateend", Math.floor(end / 1000.0))
    setDateBegin(start.format('DD-MM-yyyy HH:mm'))
    setDateEnd(end.format('DD-MM-yyyy HH:mm'))
  }


  return (
   
    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
      <div className="col-sm-12 col-md-6 offset-md-3"><ViewStep step={0} arrStep={CONFIG_STEP_TEST_2} /></div>
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label>Tên bài kiểm tra <span className="text-danger">*</span></label>
          <input type="text" className="form-control h-31" placeholder="Nhập tên bài kiểm tra" name="title"
            {...formik.getFieldProps("title")}
          ></input>
          <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
        </div>
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label>Khối <span className="text-danger">*</span></label>
          <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} formik={formik} name="grade_id"
            onChange={async (e) => {
              await formik.setFieldValue('grade_id', e)
            }}
          ></UserDropDown>
          <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
        </div>
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label> Môn học <span className="text-danger">*</span></label>
          <UserDropDown apiname={"subjects?key=&istree=1&orderby=title-ASC&page=1&limit=50&school_id=" + user.data.school_id + "&grade_id=" + (formik.values.grade_id)} placeholder="Chọn môn học" colvalue={"subjects.id"} colname={"subjects.title"} formik={formik}
            name="subject_id" onChange={async (e) => { await formik.setFieldValue("subject_id", e); }}
          ></UserDropDown>
          <AlertErrorForm formik={formik} name="subject_id"></AlertErrorForm>
        </div>
        <div className="col-xl-3 col-lg-3 col-12 form-group">
          <label>Lớp <span className="text-danger">*</span> </label>
          <UserDropDownClass apiname={"class?key=&orderby=title-ASC&page=1&limit=500&grade_id=" + (formik.values.grade_id)+ "&year_id=" + year_id} placeholder="Tất cả lớp" detail={detail} colvalue={'class.id'} formik={formik} colname={'class.title'} name='listclass'
            onChange={async (e) => { await formik.setFieldValue('listclass', e); }}
          ></UserDropDownClass>
          <AlertErrorForm formik={formik} name="listclass"></AlertErrorForm>
        </div>
        <div className="col-lg-3 col-12 form-group">
          <label>Thời gian làm bài (phút)<span className="text-danger">*</span></label>
          <input type="number" className="form-control h-31" placeholder="Nhập thời gian làm bài" name="time"
            {...formik.getFieldProps("time")} autoComplete="off"
          ></input>
          <AlertErrorForm formik={formik} name="time"></AlertErrorForm>
        </div>
      
        <div className="col-lg-6 col-12 form-group">
          <label> Thời gian mở <span className="text-danger">*</span></label>
          <DateRangePicker initialSettings={{ startDate: dateBegin, endDate: dateEnd, minDate: new Date(), timePicker: true }}
            onCallback={handleCallback}
          >
            <div className='d-flex align-items-center'>
              <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
              <input
                className="form-control h-31 form-control-sm"
                placeholder='Từ ngày'
                readOnly={true}
                onChange={(e) => setDateBegin(e)}
                value={dateBegin}
              />
              <span className='fas fa-arrow-right mx-4' />
              <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
              <input
                className="form-control h-31 form-control-sm"
                placeholder='Đến ngày'
                readOnly={true}
                onChange={(e) => setDateEnd(e)}
                value={dateEnd}
              />
            </div>
          </DateRangePicker>
        </div>
      
        <div className="col-lg-3 col-12 form-group">
          <label>Loại <span className="text-danger">*</span></label>
          <UserDropDown apiname={"exam_param?page=1&orderby=title-ASC&limit=500&idtype=1&school_id=" + school_id} placeholder="Chọn loại" colvalue={'exam_param.id'} colname={'exam_param.title'} formik={formik} name='type_id' onChange={async (e) => { await formik.setFieldValue('type_id', e) }}></UserDropDown>
          <AlertErrorForm formik={formik} name="type_id"></AlertErrorForm>
        </div>
        <div className="col-12 form-group mg-t-8 text-center">
          <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" > {formik.isSubmitting ? <Spinner /> : <>Tiếp tục</>}</button>
        </div>
      </div>
    </form>
  );
}
