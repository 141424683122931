import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import UserDropDown from "../component/UserDropDown";
import * as Yup from "yup";
import AlertErrorForm from "../component/AlertErrorForm";

export default function FormAddTeachersManager() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [detail, setDetail] = useState({ school_id: school_id, author_id: user.data.id, user_id: "", grade_id: "", subject_id: "" });

    useEffect(() => {
        let mounted = true;
        if (id > 0)
            Axios.get(URL_LIST_ALL + "teachers_manager/" + id, user.config)
                .then(async (res) => {
                    if (res.data.status === "success") {
                        if (mounted) {
                            // console.log(res.data.data);
                            setDetail(res.data.data)
                        }
                    } else console.log(res.data.message);
                })
                .catch((err) => console.log(err));
        return () => (mounted = false);
    }, [user, id, refresh]);

    const formik = useFormik({
        initialValues: detail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            grade_id: Yup.string().trim().nullable().required("Chọn khối"),
            user_id: Yup.string().trim().nullable().required("Chọn giáo viên"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {

                Axios.put(URL_LIST_ALL + 'teachers_manager/' + id, values, user.config).then(res => {

                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật tổ trưởng thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                Axios.post(URL_LIST_ALL + 'teachers_manager', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới tổ trưởng thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }

        },
    });

    return (
        <AkkhorLayout idrole="list-teachers">
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Thêm giáo viên tổ trưởng</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 col-12 form-group">
                                <label>Giáo viên</label>
                                <UserDropDown apiname={"users?limit=1000&typeaccount=1&school_id=" + school_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} formik={formik} name='user_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('user_id', e)
                                    }}></UserDropDown>
                                <AlertErrorForm formik={formik} name="user_id"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                                <label>Khối <span className="text-danger">*</span></label>
                                <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} formik={formik} name="grade_id"
                                    onChange={async (e) => { await formik.setFieldValue("grade_id", e); }}
                                ></UserDropDown>
                                <AlertErrorForm formik={formik} name="grade_id"></AlertErrorForm>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 form-group">
                                <label> Môn học</label>
                                <UserDropDown apiname={"subjects?key=&page=1&istree=1&orderby=title-ASC&limit=50&school_id=" + school_id + "&grade_id=" + formik.values.grade_id} placeholder="Chọn môn học" colvalue={"subjects.id"} colname={"subjects.title"} formik={formik}
                                    name="subject_id" onChange={async (e) => { await formik.setFieldValue("subject_id", e); }} all={true}
                                ></UserDropDown>
                                <AlertErrorForm formik={formik} name="subject_id"></AlertErrorForm>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
