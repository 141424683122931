import React from "react";

export default function HomeWidget({mdata}) {
  return <>
  <div className="row gutters-20">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-summery-one mg-b-20 totalstudents">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-green ">
                  <i className="flaticon-classmates text-green"></i>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-title">Học sinh</div>
                  <div className="item-number">
                    <span className="counter" data-num="{mdata.number_students}">
                      {mdata.number_students}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-summery-one mg-b-20 totalteachers">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-blue">
                  <i className="flaticon-multiple-users-silhouette text-blue"></i>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-title">Giáo viên</div>
                  <div className="item-number">
                    <span className="counter" data-num="{mdata.number_teachers}">
                      {mdata.number_teachers}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-summery-one mg-b-20 totalclass">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-yellow">
                  <i className="flaticon-couple text-orange"></i>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-title">Lớp học</div>
                  <div className="item-number">
                    <span className="counter" data-num="{mdata.number_class}">
                      {mdata.number_class}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="dashboard-summery-one mg-b-20 totalsubjects">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="item-icon bg-light-red">
                  <i className="flaticon-money text-red"></i>
                </div>
              </div>
              <div className="col-6">
                <div className="item-content">
                  <div className="item-title">Môn học</div>
                  <div className="item-number">
                    <span className="counter" data-num="{mdata.number_subjects}">
                      {mdata.number_subjects}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>;
}
