import React, { useState } from "react";
import { LIST_ALPHA } from "../../../configs";
import parse from 'html-react-parser'

export default function QChooseMulti(props) {
    const { item, currentAnswer } = props;
    const answers = item.answers
    const [choice] = useState(currentAnswer || {})
    return (<div className="col-12 form-group mt-3">
        <ul>
            {answers && <>
                {answers.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer rounded " + (choice[i] ? ' btn-info' : ' btn-secondary')} >
                        {parse(item+'')}
                    </div>
                </li>)}
            </>}
        </ul>
    </div>);
}