import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LIST_ALPHA } from "../../../configs";
import { saveAnswers } from "../../../redux/Actions";
import parse from 'html-react-parser'

export default function QChooseOne(props) {
    const { item, currentAnswer } = props; // LNT
    const { Answers } = useSelector(state => state.save);
    const dispatch = useDispatch();

    const answers = item.answers

    const chooseItem = (index) => {
        let newListAnswers = { ...Answers }
        newListAnswers[item.id] = { question: item, answers: index, percent: (index + '') === (answers.correct + '') ? 100 : 0 };
        dispatch(saveAnswers(newListAnswers))
    }

    return (<div className="col-12 form-group">
        <ul>
            {answers && <>
                {answers.list.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                    {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer rounded " + (currentAnswer === i ? ' btn-info' : ' btn-secondary')} onClick={() => chooseItem(i)}>{parse(item + '')}</div></li>)}
            </>}
        </ul>
    </div>);
}
