
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";
import ResetPass from "../component/ResetPass";
import PartsOfMember from "../component/PartsOfMember";
import FastLogin from "../component/FastLogin";
import { checkRole } from "../model";
import { TYPE_ACCOUNT } from "../configs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import UserDropDown from "../component/UserDropDown";
const _gender = { 'Nữ': 0, 'Nam': 1 };

export default function ListUsersPage() {

  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [params, setParam] = useState('school_id=' + school_id);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [keyword, setKeyword] = useState('');
  const [typeAccount, setTypeAccount] = useState('all');
  const [role_id, setRole_Id] = useState('all');


  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = 'school_id=' + school_id
    if (typeAccount > -1) _param += "&typeaccount=" + typeAccount
    if (role_id > 0) _param += "&role_id=" + role_id
    if (keyword !== '') _param += "&key=" + keyword
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };



  const allowLogin = checkRole(user, 'list-users', 'login');
  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "username", label: "Tên đăng nhập", options: { filter: true, sort: true, filterType: 'textField' } },
    { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true, filterType: 'textField' } },
    {
      name: "gender",
      label: "Giới tính",
      options: {
        filter: true, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const icon = value === 1 ? "fas fa-male text-success" : "fas fa-female text-danger";
          return <i className={icon}></i>;
        },
        customFilterListOptions: { render: v => `Giới tinh: ${v}` },
        filterType: 'dropdown',
        filterOptions: {
          names: ['Nữ', 'Nam'],
          logic: (location, filters, row) => {
            if (filters.length && _gender[filters[0]] === location) return false;
            else return true;
          }
        }
      }
    },
    { name: "mobile", label: "Điện thoại", width: 150 },
    { name: "email", label: "Email", width: 250 },
    {
      name: "datecreate", label: "Ngày đăng",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    {
      name: "typeaccount", label: "Loại tài khoản",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return TYPE_ACCOUNT[value];
        },
      }
    },
    {
      name: "id", label: "Đặt lại mật khẩu",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          return <ResetPass id={value} />;
        },
      }
    },
    {
      name: "id", label: "Bộ phận",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          return <PartsOfMember id={value} />;
        },
      }
    }
  ];
  if (allowLogin) {
    columns.push({
      name: "id", label: "Đăng nhập",
      options: {
        filter: false, sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          return <FastLogin id={value} />;
        },
      }
    })
  }

  const listTypeAccount = [
    { value: "all", name: "Tất cả" },
    { value: "0", name: "Quản trị viên" },
    { value: "1", name: "Giáo viên" },
    { value: "2", name: "Học sinh" }
  ]

  return (<AkkhorLayout idrole="list-users">
    <SRList name="users" idrole="list-users" params={params} title="Tài khoản" defaultLimit={50} serverSide={true} columns={columns} >
      <div className="card mb-3">
        <div className="card-body ">
          <div className="d-flex pb-2">
            <div className="mr-auto">
              <h3>Bộ lọc tài khoản</h3>
            </div>
          </div>
          <form className="new-added-form" method="get" >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
                <label>Từ khoá </label>
                <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
                <label> Loại tài khoản </label>
                <UserDropDown data={listTypeAccount} placeholder="Chọn loại tài khoản" defaultValue={typeAccount} name="typeAccount"
                  onChange={async (e) => { setTypeAccount(e); }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 form-group">
                <label> Quyền</label>
                <UserDropDown apiname={"roles?key=&page=1&limit=50&school_id=" + user.data.school_id} placeholder="Chọn quyền" defaultValue={role_id} colvalue={"roles.id"} colname={"roles.title"} name="role_id"
                  onChange={async (e) => { setRole_Id(e) }} all={true}
                ></UserDropDown>
              </div>
              <div className="col-md-6 col-12 form-group">
                <label> Thời gian khởi tạo </label>
                <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: "", }}
                  onCallback={handleCallback}
                >
                  <div className='d-flex align-items-center'>
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Từ ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Từ ngày'
                      readOnly={true}
                      onChange={(e) => setStartDate(e)}
                      value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                    />
                    <span className='fas fa-arrow-right mx-4' />
                    <span style={{ whiteSpace: 'nowrap' }} className="mr-2">Đến ngày</span>
                    <input
                      className="form-control h-31 form-control-sm"
                      placeholder='Đến ngày'
                      readOnly={true}
                      onChange={(e) => setEndDate(e)}
                      value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                    />
                  </div>
                </DateRangePicker>
              </div>
              <div className="col-12 ">
                <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SRList>
  </AkkhorLayout>);
}
