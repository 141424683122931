import Axios from 'axios';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { URL_LIST_ALL } from '../configs/api';
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";

export default function NhanXetTietHoc(props) {
    const { user } = useSelector((state) => state.auth);
    const {timeTable, onChange} = props;
    const [show, setShow] = useState(false);

    const formik = useFormik({
        initialValues: {...timeTable,comment_advantages:(timeTable.comment_advantages||''),comment_defect:(timeTable.comment_defect||'') },
        validationSchema: null,
        onSubmit: (values, { setSubmitting }) => {
            values.point_total = parseInt(values.point_learn) + parseInt(values.point_discipline) + parseInt(values.point_hygiene) + parseInt(values.point_diligent)

            console.log(values)
            setShow(false)
            setSubmitting(false)
           Axios.put(URL_LIST_ALL + "timetable/"+(timeTable?timeTable.id:0) ,values, user.config).then((res) => {
                if(res.data.status==='success') {
                    onChange()
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật nhận xét thành công',
                        buttons: [{ label: 'OK' }]
                    });
                }else{
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            })
        },
      });


    return <>
    <button type="button" onClick={()=>setShow(true)} className="btn btn-sm btn-danger mr-3">Nhận xét</button>
    <Modal show={show} onHide={() => setShow(false)} animation={true} size="md">
    <form method="post" onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
        <Modal.Title>Nhận xét tiết học</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="form-label">Ưu điểm</label>
        <textarea className="form-control form-control-sm"  {...formik.getFieldProps("comment_advantages")}></textarea>
        <label className="form-label mt-2">Tồn tại</label>
        <textarea className="form-control form-control-sm"  {...formik.getFieldProps("comment_defect")}></textarea>
        <label className="form-label mt-2">Xếp loại tiết học</label>
        <div className="d-flex justify-content-between">
            <div className="flex-fill p-1">
                <label className="form-label">Học tập</label>
                <select  className="form-control form-control-sm" {...formik.getFieldProps("point_learn")}>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                    <option value="0">0</option>
                </select>
            </div>
            <div className="flex-fill p-1">
                <label className="form-label">Kỷ luật</label>
                <select  className="form-control form-control-sm" {...formik.getFieldProps("point_discipline")}>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                    <option value="0">0</option>
                </select>
            </div>
            <div className="flex-fill p-1">
                <label className="form-label">Vệ sinh</label>
                <select  className="form-control form-control-sm" {...formik.getFieldProps("point_hygiene")}>
                    <option value="2">2</option>
                    <option value="1">1</option>
                    <option value="0">0</option>
                </select>
            </div>
            <div className="flex-fill p-1">
                <label className="form-label">Chuyên cần</label>
                <select  className="form-control form-control-sm" {...formik.getFieldProps("point_diligent")}>
                    <option value="1">1</option>
                    <option value="0">0</option>
                </select>
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
            <button type="submit" disabled={formik.isSubmitting}  className="btn btn-sm btn-success"> Lưu</button>
        </Modal.Footer>
        </form>
    </Modal>
   
    </>
}