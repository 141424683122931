import React, { useEffect, useState } from 'react';
import AkkhorLayout from '../../layout/akkhor';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Axios from 'axios';
import { URL_LIST_ALL } from '../../configs/api';
import Moment from "react-moment";
import styles from './ReportExamDetail.module.scss';
import SRList from "../../component/List";

export default function ReportExamDetail() {
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null);
    const [listClass, setListClass] = useState([]);

    const now = (new Date()).getTime() / 1000;
    useEffect(() => {
        let mounted = true;
        let url = URL_LIST_ALL + "at_exam_tests/" + id + '?info=1'
        Axios.get(url, user.config).then(async (res) => {
            if (res.data.status === "success" && mounted) {
                const _detail = res.data.data;
                // console.log(_detail);
                setListClass(_detail.listclass)
                setDetail(_detail);

            }
            else console.log(res.data.message);
        }).catch((err) => console.log(err));

        return () => (mounted = false);
    }, [user, id]);

    const countNumQues = (_test) => {
        if (_test && _test.hasOwnProperty('questions')) {
            const _ques = _test.questions;
            const _listQues = Object.values(_ques);
            let number = 0;
            _listQues.map((item) => {
                number += item.list.length;
                return item;
            });
            return number;
        } return 0;
    }

    const statusQues = (_test) => {
        if (_test) {
            if (now >= _test.datebegin && now <= _test.dateend) {
                return <b className="text-success">Trong hạn</b>
            } else {
                if (now > _test.dateend) return <b className="text-danger">Hết hạn</b>
                else return <b className="text-info">Chưa đến hạn</b>
            }
        } else return null

    }
    let columns = [
        { name: "id", label: "ID", options: { filter: false, sort: true } },
        { name: "code", label: "Mã số", options: { filter: true, sort: true } },
        { name: "fullname", label: "Họ và tên", options: { filter: true, sort: true } },
        {
            name: "student_id", label: "Lớp", options: {
                filter: true, sort: true, customBodyRender: (value) => {
                    let classtitle = ""
                    if (listClass && listClass.length > 0) {
                        if (listClass.length === 1) {
                            classtitle = listClass[0].label
                        } else {
                            listClass.forEach(inf_class => {
                                const className = inf_class.label
                                inf_class.students.forEach(st => {
                                    if (st.id === value) classtitle = className
                                });
                            })
                        }
                    }

                    return classtitle;
                }
            }
        },
        {
            name: "status", label: "Trạng thái", options: {
                filter: true, sort: true, customBodyRender: (value) => {
                    if (value > 0)
                        return <b className="text-success">Đã làm</b>
                    else return <b className="text-danger">Chưa làm</b>
                }
            }
        },
        {
            name: "info", label: "Thời gian nộp bài", options: {
                filter: false, sort: true,
                customBodyRender: (value) => {
                    let info = JSON.parse(value)
                    const time = new Date(info.datecreate * 1000);
                    if (!isNaN(time.getTime())) return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
                    return null;
                },
            },
        },
        {
            name: "info", label: "Số câu đúng", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    if (value) {
                        let info = JSON.parse(value)
                        return info.correct_number;
                    } else return null;
                },
            }
        },
        {
            name: "info", label: "Số câu sai", options: {
                filter: true, sort: true,
                customBodyRender: (value) => {
                    if (value) {
                        let info = JSON.parse(value)
                        if (info.total_number > 0) return info.total_number - info.correct_number;
                    } return null;
                },
            }
        },
        { name: "point", label: "Điểm", options: { filter: true, sort: true } },
        {
            name: "id", label: "#", options: {
                filter: true, sort: true, customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[4];
                    if (status > 0)
                        if (detail.type !== 3) {
                            return <Link to={"/examination-view/" + value} target="_blank"><b className="text-info">Chi tiết</b></Link>
                        } else {
                            return <Link to={"/examination-azota-view/" + value} target="_blank"><b className="text-info">Chi tiết</b></Link>
                        }
                    else return null;
                }
            },
        }
    ];


    //lastname-ASC_fullname-ASC
    return (
        <AkkhorLayout idrole="examination-report" roleignone={user.data.typeaccount === 2}>
            {detail && <>
                <div className="breadcrumbs-area py-2">
                    <ul>
                        <li>
                            <Link to="/">Trang chủ</Link>
                        </li>
                        <li> <Link to="/examination-report">Thống kê</Link></li>
                        <li>{detail.title}</li>
                    </ul>
                </div>
                <div className="card height-auto">
                    <div className="card-body">
                        <div className="py-2">
                            <div >Trạng thái:  {statusQues(detail)}. {detail.title} có {countNumQues(detail)} câu hỏi</div>
                            <div >Từ <Moment format="DD/MM/YYYY">{new Date(detail.datebegin * 1000)}</Moment>
                                đến <Moment format="DD/MM/YYYY">{new Date(detail.dateend * 1000)}</Moment></div>
                            <div style={{ fontSize: '1.3em' }} className=" my-2">{detail.gradetitle} - {detail.subjecttitle}</div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + "  bg-danger "}>
                                    <b className="mr-3">{detail.total_students}</b> Học sinh được giao bài
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-success "}>
                                    <b className="mr-3">{detail.total_finish}</b> Học sinh đã làm bài
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-warning "} >
                                    <b className="mr-3">{detail.total_unfinish}</b> Học sinh chưa làm bài
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + "  bg-primary "}>
                                    <b className="mr-3">{detail.diemtb}</b> Điểm trung bình bài
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-info "}>
                                    <b className="mr-3">{detail.diemgioi}</b> Học sinh đạt điểm giỏi
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-info "} >
                                    <b className="mr-3">{detail.diemkhagioi}</b> Học sinh đạt điểm khá giỏi
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-info "} >
                                    <b className="mr-3">{detail.diemkha}</b> Học sinh đạt điểm Khá
                                </div>
                            </div>
                            <div className="col-sm-3 pb-3">
                                <div className={styles.fastinfo + " bg-info "} >
                                    <b className="mr-3">{detail.diemkem}</b> Học sinh đạt điểm dưới 7
                                </div>
                            </div>
                        </div>
                        <SRList name="at_exam_students" idrole={"list-tests"} linkaction={'tests'} params={"join=2&test_id=" + id + '&checked=1'} title={""} defaultLimit={10} serverSide={true} noAction={true} onlyList={true} columns={columns} />
                    </div>
                </div>
            </>}
        </AkkhorLayout>
    );
}
