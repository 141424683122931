import { useFormik } from "formik";
import * as Yup from "yup";
import React, {  useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useDispatch, useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import moment from "moment";
import { LIST_NATION } from "../configs";
import { actions } from "../redux/authRedux";

export default function FormProfilePage() {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [dateBirhtDay, setDateBirhtDay] = React.useState(null);
    const dispatch = useDispatch();
    const detail = user.data;
    useEffect(
        function () {
            setDateBirhtDay(Date.parse(detail.birthday))
        },[detail]
    );
    const formik = useFormik({
        initialValues: detail,
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
          fullname: Yup.string().trim().nullable().required('Nhập họ và tên').max(150, "Độ dài không quá 150 ký tự"),
          lastname: Yup.string().trim().nullable().required('Nhập tên').max(50, "Độ dài không quá 50 ký tự"),
          birthday: Yup.string().trim().nullable().required('Chọn ngày sinh'),
          mobile: Yup.string().trim().nullable().required('Nhập số điện thoại'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            Axios.put(URL_LIST_ALL+'users/'+detail.id, values, user.config).then(res => {
                let newUser = Object.assign({}, user);
                newUser.data = values
                dispatch(actions.login(newUser));
                resetForm();
                setLoading(false);
                setSubmitting(false);
            }).catch(err => console.log(err));
        },
    });
    const handleDateChange = async (date) => {
        await formik.setFieldValue('birthday',moment(date).format('YYYY-MM-DD'))
        await formik.setFieldTouched('birthday')
        setDateBirhtDay(date);
      };

  return (
    <AkkhorLayout idrole="update-profile">
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Sửa hồ sơ</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Họ và tên <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Tên  <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("lastname")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="lastname"></AlertErrorForm>
                    </div>
                    
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Ngày sinh <span className="text-danger">*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker variant="inline" format="dd/MM/yyyy" value={dateBirhtDay} className="form-control height32 air-datepicker" 
                            onChange={handleDateChange} margin="none"   />
                        </MuiPickersUtilsProvider>
                        <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Điện thoại <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")}/>
                        <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Email</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Địa chỉ</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Giới tính</label>
                        <select className="form-control height32" {...formik.getFieldProps("gender")}>
                            <option value="0">Nữ</option>
                            <option value="1">Nam</option>
                        </select>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Dân tộc</label>
                        <select className="form-control height32" {...formik.getFieldProps("nation")}>
                            <option value="" >Chọn dân tộc</option>
                            {LIST_NATION.map((nation,index)=><option value={nation} key={index.toString()}>{nation}</option>)}
                        </select>
                    </div>
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner />:<>Lưu hồ sơ</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
