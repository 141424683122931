import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import moment from "moment";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { LIST_NATION } from "../configs";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
var _ = require('lodash');

export default function FormStudentsPage() {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const  {id}  = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [dateBirhtDay, setDateBirhtDay] = useState(null);
    const [refresh, setRefresh] = useState(false);
    
    const defaultdetail = {
        islunch:0,  school_id, author_id:user.data.id, code:'', fullname:'',  lastname:'', gender:'',mobile:'',email:'', birthday:'', address:'', nation:'', ispolicy:0, isdisability:0,notes:'', parentinfo:null, status:1
    }
    useEffect(
        function () {
            if(id>0)
            {
                let mounted = true;
                Axios.get(URL_LIST_ALL  + "students/"+id,user.config)
                    .then( async (res) => {
                    if (res.data.status === "success") {
                        if (mounted){
                            const data = _.pickBy(res.data.data, v => v !== null && v !== undefined && v !== '');
                            setDetail(data);
                            setDateBirhtDay(Date.parse(res.data.data.birthday))
                        } 
                    } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                    return () => (mounted = false);
            }
        },[user,id,refresh]
      );
      console.log(detail)
    const formik = useFormik({
        initialValues:{...defaultdetail, ...detail},
        enableReinitialize:true,
        validationSchema: Yup.object().shape({
            code: Yup.string().trim().nullable().required('Nhập mã học sinh').max(10, "Độ dài không quá 10 ký tự"),
            fullname: Yup.string().trim().nullable().required('Nhập họ và tên').max(150, "Độ dài không quá 150 ký tự"),
            lastname: Yup.string().trim().nullable().required('Nhập tên').max(50, "Độ dài không quá 50 ký tự"),
            gender: Yup.string().trim().nullable().required('Chọn giới tính'),
            birthday: Yup.string().trim().nullable().required('Chọn ngày sinh'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if(id>0)
            {
                Axios.put(URL_LIST_ALL+'students/'+id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật học sinh thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }else{
                Axios.post(URL_LIST_ALL+'students', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới học sinh thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    }else{
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
            
        },
    });

    const handleDateChange = async (date) => {
        await formik.setFieldValue('birthday',moment(date).format('YYYY-MM-DD'))
        await formik.setFieldTouched('birthday')
        setDateBirhtDay(date);
      };
  return (
    <AkkhorLayout idrole="list-students">
      <div className="card height-auto mt-4">
        <div className="card-body">
            <div className="heading-layout1">
                <div className="item-title">
                    <h3>Thông tin Học sinh</h3>
                </div>
            </div>
            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Mã học sinh  <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("code")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="code"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Họ và tên  <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Tên  <span className="text-danger">*</span></label>
                        <input type="text" placeholder="" className="form-control height32"  {...formik.getFieldProps("lastname")} autoComplete="off"/>
                        <AlertErrorForm formik={formik} name="lastname"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Giới tính <span className="text-danger">*</span></label>
                        <select className="form-control height32" {...formik.getFieldProps("gender")}>
                            <option value="" disabled>Hãy chọn giới tính</option>
                            <option value="1">Nam</option>
                            <option value="2">Nữ</option>
                            <option value="3">Khác</option>
                        </select>
                        <AlertErrorForm formik={formik} name="gender"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Ngày sinh <span className="text-danger">*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker  autoOk={true} variant="inline" format="dd/MM/yyyy" value={dateBirhtDay} className="form-control height32 air-datepicker" 
                            onChange={handleDateChange} margin="none"   />
                        </MuiPickersUtilsProvider>
                        <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Điện thoại </label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")}/>
                        <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Email</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")}/>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Địa chỉ</label>
                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")}/>
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Dân tộc</label>
                        <select className="form-control height32" {...formik.getFieldProps("nation")}>
                            <option value="" >Chọn dân tộc</option>
                            {LIST_NATION.map((nation,index)=><option value={nation} key={index.toString()}>{nation}</option>)}
                        </select>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Là đối tượng chính sách</label>
                        <select className="form-control height32" {...formik.getFieldProps("ispolicy")}>
                            <option value="0">Không</option>
                            <option value="1">Có</option>
                        </select>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Là đối tượng khuyết tật</label>
                        <select className="form-control height32" {...formik.getFieldProps("isdisability")}>
                            <option value="0">Không</option>
                            <option value="1">Có</option>
                        </select>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label>Đăng ký ăn trưa</label>
                        <select className="form-control height32" {...formik.getFieldProps("islunch")}>
                            <option value="0">Không</option>
                            <option value="1">Có</option>
                        </select>
                    </div>
                    <div className="col-lg-6 col-12 form-group">
                        <label>Ghi chú</label>
                        <textarea className="textarea form-control" cols="10" rows="5" {...formik.getFieldProps("notes")}></textarea>
                    </div>
                    <div className="col-12 form-group mg-t-8">
                    <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ?<Spinner />:<>Lưu học sinh</>}</button>
                        <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </AkkhorLayout>
  );
}
