import React from "react";
import Chart from 'react-apexcharts'

export default function HomeChartBar({ data, title, categories }) {
  const state =  {
    series: [{
      name: title,
      data: data
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'string',
        categories: categories
      },
    },
  
  
  };
  return (
    <div className="card dashboard-card-two pd-b-20">
      <div className="card-body p-2">
        <div className="heading-layout1">
          <div className="item-title">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="expense-chart-wrap">
        <Chart options={state.options} series={state.series} type="bar" height={400} />
        </div>
      </div>
    </div>
  );
}
