
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";
import MemberOfParts from "../component/MemberOfParts";

export default function ListPartsPage() {
  let params = '';
  const { user } = useSelector((state) => state.auth);
  const [refresh, setRefresh] = useState(false);
  params += user && user.data && user.data.school_id>0?'school_id='+user.data.school_id:0;
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    { name: "title", label: "Tên bộ phận", options: { filter: true, sort: true } },
    { name: "number", label: "Số nhân sự", options: { filter: true, sort: true } },
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    },
    { name: "id",label: "Nhân sự",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          return <MemberOfParts id={value} onRefresh={()=>setRefresh(!refresh)} />;
        },
      }
    }
  ];
  

  return (
    <AkkhorLayout idrole="list-parts">
      <SRList name="parts" idrole="list-parts" refresh={refresh} params={params}  title="Bộ phận" defaultLimit={50}  serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>
  );
}
