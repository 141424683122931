import React from "react";
import QTrueFlase from "./QTrueFlase";
import QChooseMulti from "./QChooseMulti";
import QChooseOne from "./QChooseOne";
import QDropDrag from "./QDropDrag";
import QEssay from "./QEssay";
import QFillTo from "./QFillTo";
import QGeneral from "./QGeneral";
import QMatching from "./QMatching";
import FormQuestion from "../FormQuestion/index";
import { useSelector } from "react-redux";


export default function ViewQuestion(props) {
  const {
    test,
    item,
    index,
    onRefresh,
    action,
  } = props;
  const {Answers} = useSelector(state=>state.save);

  const objQues = {
    QTrueFlase: (props) => <QTrueFlase {...props} />,
    QChooseMulti: (props) => <QChooseMulti {...props} />,
    QChooseOne: (props) => <QChooseOne {...props} />,
    QDropDrag: (props) => <QDropDrag {...props} />,
    QEssay: (props) => <QEssay {...props} />,
    QFillTo: (props) => <QFillTo {...props} />,
    QMatching: (props) => <QMatching {...props} />,
    QGeneral: (props) => <QGeneral {...props} />,
  };
  const Tag = objQues[item.code];

  const currentAnswer = () =>{
    let result = null
    if(item && item.id && Answers && Answers[item.id]){
      result = Answers[item.id].answers
    }else{  
      if(test.currentAnswer){
        const itemTest = test.currentAnswer;
        if(itemTest[item.id]) result = item.currentAnswer
      }
    }
    return result
  }

  return (
    <>
     <Tag item={item} currentAnswer={currentAnswer()} />
      {action && (
        <FormQuestion
          index={index}
          item={item}
          test={test}
          id={item.id}
          edit={true}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}
