import Axios from "axios";
import React, {  useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import { checkRole } from "../model";
import { confirmAlert } from "react-confirm-alert";

export default function PartsOfMember(props) {
    const { id } = props;
    const { user } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [listParts, setListParts] = useState([]);

    useEffect(() => {
        if(show===true)
        {
            setListParts([])
            Axios.get(URL_LIST_ALL+'partsofmember?limit=100&id='+id, user.config).then((res)=>{
                if(res.data.status==='success'){
                    setListParts(res.data.data)
                }else{
                    alert(res.data.message); 
                }
            })
        }
    }, [show,id,user]);

    const allowParts = checkRole(user,'list-users','parts');


    const checkChoice = (index,row,checked, ismain) => {
        let newRow = Object.assign({}, row);
        let arrParts = [...listParts];
        if(checked)
        {
            newRow.user_id = id
            newRow.ismain = ismain
        }else{
            if(ismain===0){ 
                newRow.user_id = null
            }
            newRow.ismain = null
        }
        arrParts[index] = newRow;
        setListParts(arrParts);
    }

    const saveParts=() => {
        Axios.put(URL_LIST_ALL+'partsofmember/'+id,listParts, user.config).then((res)=>{
            setShow(false)
            if(res.data.status==='success'){
                
                confirmAlert({ title: 'Thành công',message: 'Cập nhật bộ phận thành công',buttons: [{ label: 'OK' }]}); 
            }else{
                confirmAlert({ title: 'Lỗi',message: res.data.message,buttons: [{ label: 'OK' }]}); 
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
        {allowParts?<>
        <button className="btn btn-primary btn-sm" onClick={()=>setShow(true)}>Bộ phận</button>
        </>:<>
        <button className="btn btn-secondary btn-sm" disabled >Bộ phận</button>
        </>}
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>Bộ phận</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {listParts.length>0 && <table className="table table-sm table-hover table-striped m-0">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Tên bộ phận</th>
                        <th>Chọn</th>
                        <th>Trưởng</th>
                    </tr>
                    </thead>
                    <tbody>
                        {listParts.map((item,i)=><tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.title}</td>
                            <td><input type="checkbox" value={item.id} checked={item.user_id===id} onChange={(e)=>checkChoice(i,item, e.target.checked,0)}></input></td>
                            <td><input type="checkbox" value={item.id} checked={item.ismain===1} onChange={(e)=>checkChoice(i,item, e.target.checked,1)}></input></td>
                        </tr>)}
                    </tbody>
                </table>}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary btn-sm" onClick={()=>setShow(false)}>Đóng</button>
                <button type="submit" className="btn btn-success btn-sm" onClick={saveParts}>Lưu</button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
