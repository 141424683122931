
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SRList from "../component/List";
import Moment from "react-moment";
import ListStudentToolbar from "./ListStudentToolbar";
import AkkhorLayout from "../layout/akkhor";
import ChangeClass from "../component/ChangeClass";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import ModalAddStudent from "./ListStudent_AddStudent";
import UserDropDown from "../component/UserDropDown";
import { ID_TYPE_ACCOUNT } from "../configs";
import { confirmAlert } from "react-confirm-alert";

const queryString = require('query-string');
const _gender = { 'Nữ': 0, 'Nam': 1 };

export default function ListStudentsPage() {
  const query = queryString.parse(useLocation().search);
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const user_id = user && user.data && user.data.id > 0 ? user.data.id : 0;
  const typeaccount = user && user.data && user.data.typeaccount > -1 ? user.data.typeaccount : -1;
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const localgrade_id = user && user.data && user.data.class && user.data.class.grade_id > 0 ? user.data.class.grade_id : 0;
  const [myclass, setMyClass] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [grade_id, setGrade_id] = useState(localgrade_id > 0 ? localgrade_id : 'all');
  const [class_id, setClass_id] = useState('all');
  let paramDefault ='school_id=' + school_id + (typeaccount === ID_TYPE_ACCOUNT.GIAO_VIEN ? '&teacher_id=' + user_id : '')
  const [params, setParams] = useState(paramDefault);

  useEffect(() => {
    setParams(paramDefault+'&year_id='+year_id)
  }, [year_id])// eslint-disable-line 

  const addAccount = (_id) => {
    Axios.get(URL_LIST_ALL + "account/" + _id, user.config).then(async (res) => {
      if (res.data.status === "success") {
        setRefresh(!refresh)
      } else console.log(res.data.message);
    }).catch((err) => console.log(err));
  }

  const resetPassword = (_id, pass) => {
    confirmAlert({
      title: "Reset Mật khẩu", message: "Mật khẩu tài khoản của học sinh này sẽ là: " + pass, buttons: [{
        label: "Xác nhận", onClick: () => {
          Axios.get(URL_LIST_ALL + "account-reset/" + _id, user.config).then(async (res) => {
            if (res.data.status === "success") {
              setRefresh(!refresh)
            } else console.log(res.data.message);
          }).catch((err) => console.log(err));
        }
      }, {
        label: "Đóng",
      }],
    });

  }

  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    {
      name: "username", label: "Tên đăng nhập", options: {
        filter: true, sort: true,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[tableMeta.columnIndex - 1];
          const code = tableMeta.rowData[tableMeta.columnIndex + 1];
          if (value) return <button className="btn btn-sm btn-info" onClick={() => resetPassword(id, code)} >{value}</button>;
          else return <button className="btn btn-sm btn-success" onClick={() => addAccount(id)} ><i className="fas fa-plus-circle"></i></button>;
        }
      }
    },
    { name: "code", label: "Mã số", options: { filter: false, sort: true } },
    { name: "fullname", label: "Họ và tên", options: { filter: false, sort: true } },
    {
      name: "gender",
      label: "Giới tính",
      options: {
        filter: true, sort: true,
        customBodyRender: (value) => {
          const icon = value === 1 ? "fas fa-male text-success" : "fas fa-female text-danger";
          return <i className={icon}></i>;
        },
        customFilterListOptions: { render: v => `Giới tinh: ${v}` },
        filterType: 'dropdown',
        filterOptions: {
          names: ['Nữ', 'Nam'],
          logic: (location, filters, row) => {
            if (filters.length && _gender[filters[0]] === location) return false;
            else return true;
          }
        }
      }
    },
    {
      name: "birthday",
      label: "Tuổi",
      options:
      {
        filter: true, sort: true,
        customBodyRender: (value) => {
          if (value) {
            const year1 = new Date(value).getFullYear();
            const year2 = new Date().getFullYear();
            return <b className="text-info">{year1 && year2 ? year2 - year1 : 0}</b>;
          } return null;
        }, filterType: 'textField'
      }
    },
    { name: "mobile", label: "Điện thoại", options: { filter: false, sort: true } },
    { name: "classtitle", options: { display: 'excluded' } },

    {
      name: "listclass", label: "Lớp", options: {
        filter: true, sort: true,
        customBodyRender: (value, tableMeta) => {
          const oneclass = tableMeta.rowData[tableMeta.columnIndex - 1];
          return <ChangeClass title={oneclass || value} index={tableMeta.rowIndex} row={tableMeta.rowData} selected={value} refresh={() => setRefresh(!refresh)} />
        }
      }
    },
    {
      name: "islunch", label: "Ăn trưa",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          return value ? <b className="text-success">Có</b> : <b className="text-danger">Không</b>
        },
      }
    },
    {
      name: "datemodified", label: "Ngày",
      options: {
        filter: false, sort: true,
        customBodyRender: (value) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];


  //Lấy danh sách lớp
  useEffect(() => {
    let mounted = true;
    if (query.class_id > 0 && myclass === null) {
      Axios.get(URL_LIST_ALL + "class/" + query.class_id, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setMyClass(res.data.data);
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [query]);// eslint-disable-line

  const CutomToolBar = (props) => {

    return <ListStudentToolbar {...props} myclass={myclass} refresh={() => setRefresh(!refresh)} />
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = paramDefault+'&year_id='+year_id
    if (grade_id > 0) _param += "&grade_id=" + grade_id
    if (class_id > 0) _param += "&class_id=" + class_id
    setParams(_param);
  };

  return (<AkkhorLayout idrole="list-students"><SRList idrole="list-students" refresh={refresh} name={"students"} params={params} title={myclass === null ? "Học sinh" : <>Học sinh lớp {myclass.title} <ModalAddStudent myclass={myclass} refresh={() => setRefresh(!refresh)}></ModalAddStudent></>} defaultLimit={10} serverSide={true} columns={columns} Toolbar={CutomToolBar}>
    <div className="card pb-0 height-auto my-3">
      <div className="card-body">
        <div className="d-flex pb-2">
          <div className="mr-auto">
            <h3>Tìm kiếm học sinh</h3>
          </div>
        </div>
        <form className="new-added-form" method="get" >
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label>Khối <span className="text-danger">*</span></label>
              <UserDropDown apiname={"grades?orderby=title-ASC&school_id=" + school_id} placeholder="Chọn khối" colvalue={"grades.id"} colname={"grades.title"} defaultValue={grade_id} name="grade_id"
                onChange={async (e) => { setGrade_id(e); setClass_id('all') }} all={true}
              ></UserDropDown>
            </div>
            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label> Lớp học <span className="text-danger">*</span></label>
              <UserDropDown apiname={"class?key=&orderby=title-ASC&page=1&limit=50&school_id=" + school_id + "&grade_id=" + grade_id+ "&year_id=" + year_id} placeholder="Chọn lớp học" defaultValue={class_id} colvalue={"class.id"} colname={"class.title"} name="class_id"
                onChange={async (e) => { setClass_id(e); }} all={true}
              ></UserDropDown>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SRList>
  </AkkhorLayout>);
}
