import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Logo from "../assets/img/tomo.png";
const _ = require('lodash');
//904832009
export default function SiderBar(props) {
    const { user } = useSelector((state) => state.auth);
    const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
    const location = useLocation();
    let localsegment = location.pathname;

    let listMenus = [
        { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard' },
        {
            title: 'Lịch học', icon: 'flaticon-calendar', link: '', active: ['/timetable', '/teaching'].includes(localsegment),
            children: [
                { title: 'Thời khoá biểu', icon: 'fas fa-angle-right', link: '/timetable' },
                { title: 'Lịch báo giảng', icon: 'fas fa-angle-right', link: '/teaching' },
            ]
        },
        {
            title: 'Chuyên cần', icon: 'flaticon-checklist', link: '', active: ['/lunch', '/attends', '/sleep', '/special', '/bus', '/extrac', '/examofnotebook', '/examofexercise'].includes(localsegment),
            children: [
                { title: 'Điểm danh hàng ngày', icon: 'fas fa-angle-right', link: '/lunch' },
                { title: 'Điểm danh tiết học', icon: 'fas fa-angle-right', link: '/attends' },
                { title: 'Điểm danh ngủ trưa', icon: 'fas fa-angle-right', link: '/sleep' },
                { title: 'Điểm danh thể thao', icon: 'fas fa-angle-right', link: '/extrac' },
                { title: 'Điểm danh lớp chuyên', icon: 'fas fa-angle-right', link: '/special' },
                { title: 'Điểm danh xe buýt', icon: 'fas fa-angle-right', link: '/bus' },
                { title: 'Kiểm tra vở ghi chép', icon: 'fas fa-angle-right', link: '/examofnotebook' },
                { title: 'Kiểm tra bài tập', icon: 'fas fa-angle-right', link: '/examofexercise' },
            ]
        },
        { title: 'Học sinh', icon: 'flaticon-classmates', link: '/list-students' },
        {
            title: 'Khảo thí', icon: 'flaticon-classmates', link: '', active: ['/examination-report', '/list-exercise', '/examination', '/exam_question', '/list-tests','list-tests-azota', '/marktests', '/list-param', '/tree', '/add-tests'].includes(localsegment),
            children: [
                { title: 'Thông số khảo thí', icon: 'fas fa-angle-right', link: '/list-param' },
                { title: 'Cây kiến thức', icon: 'fas fa-angle-right', link: '/tree' },
                { title: 'Quản lý thẻ', icon: 'fas fa-angle-right', link: '/list-tags' },
                { title: 'Ngân hàng câu hỏi', icon: 'fas fa-angle-right', link: '/exam_question' },
                { title: 'Phiếu bài tập', icon: 'fas fa-angle-right', link: '/list-exercise' },
                { title: 'Bài kiểm tra', icon: 'fas fa-angle-right', link: '/list-tests' },
                { title: 'Bài tập kiểu azota', icon: 'fas fa-angle-right', link: '/list-tests-azota' },
                { title: 'Thống kê', icon: 'fas fa-angle-right', link: '/examination-report' },
                { title: 'Chấm bài kiểm tra', icon: 'fas fa-angle-right', link: '/marktests' },
            ]
        },
      
        {
            title: 'Lớp học', icon: 'flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler', link: '', active: ['/list-group-extrac', '/list-class', '/list-extrac'].includes(localsegment),
            children: [
                { title: 'Lớp học', icon: 'fas fa-angle-right', link: '/list-class' },
                /* { title: 'Nhóm ngoại khoá', icon: 'fas fa-angle-right', link: '/list-group-extrac' },*/
                { title: 'Ngoại khoá', icon: 'fas fa-angle-right', link: '/list-extrac' }
            ]
        },

        {
            title: 'Danh sách khác', icon: 'flaticon-books', link: '', active: ['/list-subjects', '/list-years', '/list-grades'].includes(localsegment),
            children: [
                { title: 'Năm học', icon: 'fas fa-angle-right', link: '/list-years' },
                { title: 'Khối', icon: 'fas fa-angle-right', link: '/list-grades' },
                { title: 'Môn học', icon: 'flaticon-open-book', link: '/list-subjects' },
            ]
        },
        {
            title: 'Nhân sự', icon: 'flaticon-user', link: '', active: ['/list-users', '/list-roles', '/list-parts','/teachers-manager'].includes(localsegment), children: [
                { title: 'Tài khoản', icon: 'fas fa-angle-right', link: '/list-users' },
                { title: 'Bộ phận', icon: 'fas fa-angle-right', link: '/list-parts' },
                { title: 'Quyền', icon: 'fas fa-angle-right', link: '/list-roles' },
                { title: 'Tổ trưởng', icon: 'fas fa-angle-right', link: '/teachers-manager' },
            ]
        },
        {
            id: 'internal', title: 'Nội bộ', icon: 'flaticon-earth-globe', link: '', active: ['/internal', '/registry-lunch', '/history-login', '/history-action', '/history-action-user'].includes(localsegment), children: [
                { title: 'Đăng ký suất ăn', icon: 'fas fa-angle-right', link: '/registry-lunch' },
                { title: 'Lịch sử đăng nhập', icon: 'fas fa-angle-right', link: '/history-login' },
                { title: 'Lịch sử thao tác', icon: 'fas fa-angle-right', link: '/history-action' },
                { title: 'Lịch sử thao tác của mình', icon: 'fas fa-angle-right', link: '/history-action-user' },
            ]
        },

        {
            id: 'setting', title: 'Cài đặt', icon: 'flaticon-settings', link: '', active: ['/import-data', '/create-timetable', '/general-setting',].includes(localsegment), children: [
                { title: 'Cài đặt chung', icon: 'fas fa-angle-right', link: '/general-setting' },
                { title: 'Nhập dữ liệu', icon: 'fas fa-angle-right', link: '/import-data' },
                { title: 'Tạo thời khoá biểu', icon: 'fas fa-angle-right', link: '/create-timetable' },

            ]
        },
        {
            id: 'report', title: 'Báo cáo', icon: 'flaticon-script', link: '', active: ['/report-activity', '/report-allschool', '/report-check', '/report-diary', '/report-attends','report-attends-month', '/report-timekeeping', '/report-lunch', '/report-lunch-month'].includes(localsegment), children: [
                { title: 'Điểm danh học sinh', icon: 'fas fa-angle-right', link: '/report-attends' },
                { title: 'Báo cáo đi học theo tháng', icon: 'fas fa-angle-right', link: '/report-attends-month' },
                { title: 'Chuyên cần học sinh', icon: 'fas fa-angle-right', link: '/report-check' },
                { title: 'Nhật ký lớp học', icon: 'fas fa-angle-right', link: '/report-diary' },
                { title: 'Báo cáo đăng ký ăn trưa', icon: 'fas fa-angle-right', link: '/report-lunch' },
                { title: 'Báo cáo ăn trưa tháng', icon: 'fas fa-angle-right', link: '/report-lunch-month' },
                { title: 'Giáo viên điểm danh', icon: 'fas fa-angle-right', link: '/report-timekeeping' },
                { title: 'Hoạt động giáo viên', icon: 'fas fa-angle-right', link: '/report-activity' },
                { title: 'Báo cáo toàn trường', icon: 'fas fa-angle-right', link: '/report-allschool' },
            ]
        },
    ];
    if (user.data.typeaccount === 2) {
        listMenus = [
            { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard', role: true },
            { title: 'Thời khoá biểu', icon: 'flaticon-calendar', link: '/timetable', role: true },
            { title: 'Khảo thí', icon: 'flaticon-classmates', link: '/examination', role: true },
        ];
    }


    const checkRole = (idparent, menu) => {
        let isrole = false;
        let id = menu.id;
        if (!id) id = _.trimStart(menu.link, ' /');
        if (id) {
            if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(id) && MyRole[idparent].children[id].checked === true) isrole = true
            else if (MyRole.hasOwnProperty(id) && MyRole[id].checked === true) isrole = true
        } else {
            if (menu.hasOwnProperty('children') && menu.children && menu.children.length > 0) {
                menu.children.map((sub) => {
                    let idsub = _.trimStart(sub.link, ' /');
                    if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(idsub) && MyRole[idparent].children[idsub].checked === true) isrole = true
                    else if (idsub && MyRole.hasOwnProperty(idsub) && MyRole[idsub].checked === true) isrole = true
                    return sub;
                })
            }
        }
        // if(menu.link === '/marktests') console.log(MyRole);
        return isrole
    }


    const subMenu = (idparent, list, active) => {
        return <>
            {list && list.length > 0 ? <ul className={`nav sub-group-menu ${active ? 'sub-group-active' : ''}`}>
                {list.map((item, i) => {
                    if (checkRole(idparent, item)) {
                        return <li className="nav-item" key={i}>
                            <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                <i className="fas fa-angle-right"></i>
                                {item.title}
                            </Link>
                        </li>
                    } else {
                        return <li className="nav-item" key={i}>
                            <a href={'#' + i} className={`nav-link disabled text-dark`}>
                                <i className="fas fa-angle-right"  ></i>
                                {item.title}
                            </a>
                        </li>
                    }
                })}
            </ul> : null}
        </>
    }
    return (
        <>
            {/* Sidebar Area Start Here */}
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none">
                    <div className="header-logo">
                        <Link to="/"><img src={Logo} alt="logo" /></Link>
                    </div>
                </div>
                <div className="sidebar-menu-content">
                    <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                        {listMenus.map((item, i) => {
                            if (checkRole(null, item) || item.role) {
                                return <li key={i} className={`nav-item ${item.children ? 'sidebar-nav-item' : ''} `}>
                                    <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}><i
                                        className={item.icon}></i><span>{item.title}</span></Link>
                                    {subMenu(item.id, item.children, item.active)}
                                </li>
                            } else return <li key={i} className={`nav-item ${item.children ? 'sidebar-nav-item' : ''} `}>
                                <a href={'#' + i} className={`nav-link disabled `}><i
                                    className={item.icon}></i><span className="text-dark">{item.title}</span></a>
                                {subMenu(item.id, item.children, item.active)}
                            </li>
                        })}
                    </ul>
                </div>
            </div>
            {/* Sidebar Area End Here */}
        </>
    );
}
