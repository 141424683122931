import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
import moment from "moment";
export default function SelectWeek(props) {
    const { weekChoice, setWeekChoice, setWeek } = props;
    const { user } = useSelector((state) => state.auth);
    const { year_id } = useSelector((state) => state.save);
    const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
    const [listWeek, setListWeek] = useState([]);


    //Lấy danh sách thời khoá biểu
    useEffect(() => {
        if (year_id) {
            setListWeek([]);
            let mounted = true;
            Axios.get(URL_LIST_ALL + "currentweek/" + moment().format('YYYY-MM-DD') + '?year_id=' + year_id, user.config).then((res2) => {
                if (res2.data && res2.data.status === "success") {
                    if (mounted) {
                        const findWeek = res2.data.data;
                        if (setWeek) setWeek(findWeek);
                        setWeekChoice(findWeek.numberofweek);
                        Axios.get(URL_LIST_ALL + "weeks?orderby=id-asc&page=1&limit=500&year_id=" + year_id + "&school_id=" + school_id, user.config).then((res) => {
                            if (res.data && res.data.status === "success") {
                                if (mounted) {
                                    const list = res.data.data;
                                    setListWeek(list);
                                }
                            } else console.log(res.data.message);
                        }).catch((err) => console.log(err));
                    }
                } else {
                    setWeekChoice(null);
                    setWeek(null)
                }
            }).catch((err2) => console.log(err2));
            return () => (mounted = false);
        }
    }, [user, school_id, year_id]);//eslint-disable-line




    const onChange = (index) => {
        listWeek.map((week) => {
            if (week.numberofweek + '' === index + '') {
                setWeekChoice(week.numberofweek);
                if (setWeek) setWeek(week);
                return week;
            }
            return week;
        });
    }

    const GetTime = (week) => {
        const datebegin = moment(Date.parse(week.begin)).format('DD-MM-YYYY');
        const dateend = moment(Date.parse(week.end)).format('DD-MM-YYYY');
        return datebegin + ' -> ' + dateend;
    }
    return (<select className="form-control form-control-sm" value={weekChoice ? weekChoice : 0} onChange={(e) => onChange(e.target.value)}>
        <option value={0} disabled>Chọn tuần</option>
        {listWeek.map((item) => {
            return <option key={item.id} value={item.numberofweek} >{item.title} ({GetTime(item)})</option>
        })}
    </select>)
}