import Axios from 'axios';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { URL_LIST_ALL } from '../../configs/api';
import { useFormik } from "formik";
import UserDropDown from '../../component/UserDropDown';
import { confirmAlert } from "react-confirm-alert";
import { CircularProgress } from '@material-ui/core';

export default function ChangeTimeTable(props) {
    const { user } = useSelector((state) => state.auth);
    const school_id = user && user.data && user.data.school_id>0?user.data.school_id:0;
    const {setShow, timeTable, setRefresh,gradeChoice,classChoice } = props;
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: timeTable,
        validationSchema: null,
        onSubmit: (values) => {
            values.isreplace = JSON.stringify(timeTable);
            values.title  = 
            setLoading(true)
            Axios.put(URL_LIST_ALL + "timetable/"+(timeTable?timeTable.id:0) ,values, user.config).then((res) => {
                setLoading(false)
                setShow(false)
                if(setRefresh)setRefresh();
                if(res.data.status==='success') {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Bạn cập nhật thời khoá biểu thành công',
                        buttons: [{ label: 'OK' }]
                    });
                }else{
                    confirmAlert({
                        title: 'Thông báo',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => {
                setLoading(false)
                setShow(false)
                confirmAlert({
                    title: 'Lỗi',
                    message: err,
                    buttons: [{ label: 'OK' }]
                });
                console.log(err)
            })
        },
      });


    return <>
    
    <Modal show={true} onHide={() => setShow(false)} animation={true} size="md">
    <form method="post" onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
        <Modal.Title>Cập nhật dạy thay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label className="form-label">Chọn môn học</label>
        <UserDropDown apiname={"subjects?limit=50&school_id="+school_id+"&grade_id="+gradeChoice} placeholder="Chọn môn học" colvalue={'subjects.id'} colname={'subjects.title'} formik={formik} name='subject_id'
            onChange={async(e)=>{
                await formik.setFieldValue('subject_id',e)
                await formik.setFieldTouched('subject_id')
            }}></UserDropDown>
        <label className="form-label mt-2">Chọn giáo viên 1</label>
        <UserDropDown apiname={"users?limit=1000&typeaccount=1&school_id="+school_id+'&class_id='+classChoice+'&subject_id='+formik.values.subject_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} formik={formik} name='teacher_id'
            onChange={async(e)=>{
                await formik.setFieldValue('teacher_id',e)
                await formik.setFieldTouched('teacher_id')
            }}></UserDropDown>
         <label className="form-label mt-2">Chọn giáo viên 2</label>
        <UserDropDown apiname={"users?limit=1000&typeaccount=1&school_id="+school_id+'&class_id='+classChoice+'&subject_id='+formik.values.subject_id} placeholder="Chọn giáo viên" colvalue={'users.id'} colname={'users.fullname'} formik={formik} name='teacher_id2'
            onChange={async(e)=>{
                await formik.setFieldValue('teacher_id2',e)
                await formik.setFieldTouched('teacher_id2')
            }}></UserDropDown>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-sm btn-secondary" onClick={() => setShow(false)}>Đóng</button>
            <button type="submit" disabled={formik.isSubmitting}  className="btn btn-sm btn-success"> {loading?<CircularProgress color="secondary" size={20} value={100} />:'Lưu'}</button>
        </Modal.Footer>
        </form>
    </Modal>
   
    </>
}