
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useSelector } from "react-redux";

export default function StudentByClass(props) {
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const { myclass, onUpdate } = props;
  const [curentClass, setCurentClass] = useState(myclass);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (myclass !== null && year_id) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "students?limit=100&orderby=lastname-ASC_fullname-ASC&class_id=" + myclass.value + '&year_id=' + year_id, user.config)
        .then(async (res) => {
          if (res.data.status === "success") {
            if (mounted) {
              const _list = res.data.data;

              if (!curentClass.hasOwnProperty('students')) {
                let _curentClass = { ...curentClass };
                _curentClass.students = _list;
                setCurentClass(_curentClass);
                onUpdate(_curentClass)
              }
              setStudents([..._list]);
            }
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [myclass, year_id]);// eslint-disable-line

  const checkExits = (_stud) => {
    if (curentClass.students.length === 0) return false
    let find = false;
    curentClass.students.map((el) => {
      if (el.id === _stud.id) find = true;
      return el;
    });
    return find;
  }

  const checkExitsAll = () => {
    if (students && curentClass.students && students.length === curentClass.students.length) return true;
    return false;
  }

  const onChange = (_e, _stud) => {
    let _curentClass = { ...curentClass };
    if (_e.target.checked) {
      _curentClass.students.push(_stud)
    } else {
      let i = 0;
      _curentClass.students.map((el) => {
        if (el.id === _stud.id) {
          _curentClass.students.splice(i, 1);
        }
        i++
        return el;
      });
    }
    setCurentClass(_curentClass);
    onUpdate(_curentClass)
  }

  const CheckAll = (_e) => {
    let _curentClass = { ...curentClass };
    _curentClass.students = [];
    if (_e.target.checked) {
      students.map((_stud) => {
        _curentClass.students.push(_stud)
        return _stud;
      });
    }
    setCurentClass(_curentClass);
    onUpdate(_curentClass)
  }

  return (
    <table className={"table table-sm table-bordered table-striped table-hover"}>
      <thead>
        <tr>
          <th>#</th>
          <th><input type="checkbox" checked={checkExitsAll()} value={'All'} onChange={CheckAll} /></th>
          <th>Họ và tên học sinh</th>
          <th>Lớp</th>
        </tr>
      </thead>
      <tbody>
        {students.map((item, index) => <tr key={index}>
          <td>{index + 1}</td>
          <td><input type="checkbox" value={item.id} checked={checkExits(item)} onChange={(e) => onChange(e, item)} /></td>
          <td>{item.fullname}</td>
          <td>{myclass.label}</td>
        </tr>)}
      </tbody>
    </table>);
}
