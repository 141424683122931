import React from "react";
import { LIST_ALPHA } from "../../../configs";
import parse from 'html-react-parser'

export default function QChooseOne(props) {
    const { type, items, answers } = props;
    const listAnswer = items && items.decode && items.decode.answers ? items.decode.answers : JSON.parse(items.answers).list;
    return (
        <div className="w-100 form-group">
            {answers && <>
                {type === 'question' ?
                    <ul className="col-12">
                        {answers.list.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                            {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer rounded " + (answers.correct === i ? ' btn-info' : ' btn-secondary')} >{parse(item + '')}</div></li>)}
                    </ul>
                    : <div style={{ display: 'flex' }}>
                        {console.log(items)}
                        <ul className="col-12">
                            {listAnswer.map((item, i) => <li key={i} className="d-flex mb-2 align-items-center " >
                                {LIST_ALPHA[i]}. <div className={"py-1 px-2 cursor-pointer rounded " + (answers.correct === i ? ' btn-info' : ' btn-secondary')} >{parse(item + '')}</div></li>)}
                        </ul>
                    </div>
                }
            </>}
        </div>
    )
}
