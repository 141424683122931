import React, { useEffect, useState } from "react";
import AkkhorLayout from "../../layout/akkhor";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../../configs/api";
import { useParams } from "react-router-dom";
import FormQuestion from "./FormQuestion/index";
import ChoiceQuestion from "./ChoiceQuestion";

export default function QuestionsPage() {
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [listType, setListType] = useState([]);
  const [listQuestion, setListQuestion] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "exam_tests/" + id, user.config)
        .then(async (res) => {
          if (res.data.status === "success" && mounted)
            setDetail(res.data.data);
          else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

      Axios.get(
        URL_LIST_ALL + "exam_param?idtype=2&orderby=id-ASC",
        user.config
      )
        .then(async (res) => {
          if (res.data.status === "success" && mounted)
            setListType(res.data.data);
          else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

      Axios.get(
        URL_LIST_ALL + "exam_question?test_id=" + id + "&orderby=id-ASC",
        user.config
      )
        .then(async (res) => {
          if (res.data.status === "success" && mounted)
            setListQuestion(res.data.data);
          else console.log(res.data.message);
        })
        .catch((err) => console.log(err));

      return () => (mounted = false);
    }
  }, [user, id, refresh,school_id]);

  const onAnswers = (item, answers) => {
    //console.log(item)
    //console.log(answers)
  };

  return (
    <AkkhorLayout idrole="list-tests">
      {detail && (
        <div className="card height-auto mt-4">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Bài khảo thí: {detail.title}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                {listQuestion.map((item, index) => (
                  <div className="row mb-2" key={index}>
                    <div className="col-12 ">
                      {index + 1}. {item.title}
                      <div>
                        <ChoiceQuestion
                          key={index}
                          index={index}
                          item={item}
                          test={detail}
                          action={true}
                          onRefresh={() => setRefresh(!refresh)}
                          onAnswers={onAnswers}
                        ></ChoiceQuestion>
                      </div>
                    </div>
                  </div>
                ))}
                {listType.map((item, index) => (
                  <FormQuestion
                    key={index}
                    index={index}
                    item={item}
                    test={detail}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </AkkhorLayout>
  );
}
