
import React from "react";
import SRList from "../component/List";
import Moment from "react-moment";
import AkkhorLayout from "../layout/akkhor";

export default function ListGroupExtracPage() {
  const columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true }  },
    { name: "title", label: "Tiêu đề", options: { filter: true, sort: true }  },
    { name: "datecreate",label: "Ngày đăng",
      options:{
        filter: false,sort: true ,
        customBodyRender: (value, tableMeta, updateValue) => {
          const time = new Date(value * 1000);
          return <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>;
        },
      }
    }
  ];
  
  return (<AkkhorLayout idrole="list-group-extrac"><SRList name="class_group" linkaction='group-extrac' idrole="list-group-extrac"  title="Nhóm ngoại khoá"  defaultLimit={50}  serverSide={true} columns={columns}></SRList></AkkhorLayout>);
}
