import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";


import { Accordion, Card } from "react-bootstrap";
import TreeModal from "./TreeModal";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from "../../layout/akkhor";

export default function TreePage(props) {
  const { user } = useSelector((state) => state.auth);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [listGrades, setListGrades] = useState(null);
  const [listSubjects, setListSubjects] = useState(null);
  const [choice, setChoice] = useState(null);

  useEffect(() => {
    let mounted = true;
    //Lấy danh sách khối
    Axios.get(URL_LIST_ALL + "grades?limit=100&school_id=" + school_id, user.config).then(async (res) => {
      if (res.data.status === "success") {
        if (mounted) setListGrades(res.data.data);
      } else console.log(res.data.message);
    }).catch((err) => console.log(err));
    //Lấy danh sách môn
    Axios.get(URL_LIST_ALL + "subjects?limit=1000&istree=1&orderby=title-ASC&school_id=" + school_id, user.config).then(async (res) => {
      if (res.data.status === "success") {
        if (mounted) setListSubjects(res.data.data);
      } else console.log(res.data.message);
    }).catch((err) => console.log(err));

    return () => (mounted = false);
  }, []);// eslint-disable-line

  return (
    <AkkhorLayout idrole="tree">
      <div>
        <div className="card height-auto mt-4">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Cây kiến thức</h3>
              </div>
            </div>
            {(listGrades && listSubjects) && <div>
              <Accordion defaultActiveKey={0}>
                {listGrades.map((grade, index) => {
                  return <Card key={index} >
                    <Accordion.Toggle as={Card.Header} eventKey={index} className="cursor-pointer"><b>{grade.title}</b></Accordion.Toggle>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body>
                        <table className={"table table-sm table-bordered table-striped table-hover"}>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Môn học</th>
                              <th width="20%">Sửa</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listSubjects.filter((subject) => subject.grade_id === grade.id).map((subject, jdex) => {
                              return <tr key={jdex}>
                                <td>{jdex + 1}</td>
                                <td>{subject.title}</td>
                                <td><button type="button" className="btn btn-info btn-sm" onClick={() => setChoice(subject)} >Cây kiến thức</button></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                })}
              </Accordion>
              <Modal show={choice !== null} onHide={() => setChoice(null)} animation={true} size="lg" >
                <Modal.Header closeButton>
                  <Modal.Title>{choice !== null && <span>Môn học: {choice.title}</span>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {choice !== null && <TreeModal subject={choice} />}
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => setChoice(null)} >Đóng</button>
                </Modal.Footer>
              </Modal>
            </div>}
          </div>
        </div>
      </div>
    </AkkhorLayout>
  );
}
