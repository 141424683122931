import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterGradeClassWeekSubject from "../../component/FilterGradeClassWeekSubject";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import ItemAttends from "./ItemAttends";
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { checkRole } from "../../model";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let studentChoice = {}
export default function AttendsPage(props) {
  const { idgroup, title } = props

  let query = useQuery();
  const { user } = useSelector((state) => state.auth);
  const { year_id } = useSelector((state) => state.save);
  const school_id = user && user.data && user.data.school_id > 0 ? user.data.school_id : 0;
  const [listStudent, setListStudent] = useState([]);
  const [teacher, setTeacher] = useState(null);
  const [teacher2, setTeacher2] = useState(null);
  const [listOnday, setListOnday] = useState(null);
  const [classChoice, setClassChoice] = useState(query.get('class_id'));
  const [timeTableChoice, setTimeTableChoice] = useState(null);
  const [timeNotes, setTimeNotes] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const typecheck = 1;
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null) {
      setListStudent([])
      setListOnday(null)
      if (school_id > 0 && classChoice > 0) {
        studentChoice = {}
        Axios.get(URL_LIST_ALL + "students_class?page=1&limit=500&class_id=" + classChoice + "&school_id=" + school_id, user.config)
          .then((res) => {
            if (res.data && res.data.status === "success") {
              if (mounted) setListStudent(res.data.data);
            } else console.log(res.data.message);
          })
          .catch((err) => console.log(err));
      }
    }
    return () => (mounted = false);
  }, [classChoice, user, school_id]);
  //Lấy danh sách giáo viên dạy lớp này
  useEffect(() => {
    let mounted = true;
    if (timeTableChoice) {
      setTeacher(null)
      if (timeTableChoice.teacher_id > 0) Axios.get(URL_LIST_ALL + "users/" + timeTableChoice.teacher_id, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTeacher(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
      //Lấy giáo viên hỗ trợ
      setTeacher2(null)
      if (timeTableChoice.teacher_id2 > 0) Axios.get(URL_LIST_ALL + "users/" + timeTableChoice.teacher_id2, user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setTeacher2(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    } else {
      setTeacher(null)
      setTeacher2(null)
    }
    return () => (mounted = false);
  }, [timeTableChoice, user])

  //Lấy danh sách chuyên cần
  useEffect(() => {
    let mounted = true;
    if (classChoice && timeTableChoice) {
      setListOnday(null)
      studentChoice = {}
      Axios.get(URL_LIST_ALL + "students_onday?page=1&limit=500&typecheck=" + typecheck + "&class_id=" + classChoice + "&orderonday=" + timeTableChoice.orderonday + "&date=" + timeTableChoice.date, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) {
            let listOnday = {};
            res.data.data.forEach(element => {
              listOnday[element.student_id] = element;
            });
            setListOnday(listOnday);
          }
        } else console.log(res.data.message);
      })
        .catch((err) => console.log(err));
      //Lấy thông tin notes 
      setTimeNotes(null);
      Axios.get(URL_LIST_ALL + "timetable_notes?limit=1&typecheck=" + typecheck + "&class_id=" + classChoice + "&date=" + timeTableChoice.date + "&time=" + timeTableChoice.time, user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) {
            if (res.data.data.length > 0) setTimeNotes(res.data.data[0]);
          }
        } else console.log(res.data.message);
      })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice, timeTableChoice, user, reload]);

  const choiceItem = (e) => {
    studentChoice[e.student_id] = e;
  }

  const callApi = () => {
    setLoading(true)
    let data = Object.values(studentChoice);
    Axios.post(URL_LIST_ALL + "students_onday", data, user.config).then(res => {
      setLoading(false)
      if (res.data.status === 'success') {
        setReload(!reload);
        confirmAlert({
          title: 'Thành công', message: 'Bạn đã lưu thành công', buttons: [{ label: 'OK' }]
        });
      }
      else alert(res.data.message)
    }).catch(err => {
      setLoading(false)
      console.log(err)
    });
  }

  const formik = useFormik({
    initialValues: { notes: timeNotes ? (timeNotes.notes || '') : '' },
    enableReinitialize: true,
    validationSchema: null,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
      if (timeNotes) {
        Axios.put(URL_LIST_ALL + "timetable_notes/" + timeNotes.id + '?year_id=' + year_id, values, user.config).then(res => {
          callApi();
        }).catch(err => console.log(err));
      } else {
        let data = Object.assign({ typecheck }, timeTableChoice);
        data.notes = values.notes;
        delete data.id;
        Axios.post(URL_LIST_ALL + "timetable_notes?year_id=" + year_id, data, user.config).then(res => {
          callApi();
        }).catch(err => console.log(err));
      }
    },
  });



  const createDataForItem = (student, onday) => {
    let obj = onday ? onday : { violation: 0, notes: '', notes2: '', status: 1 }
    let data = {
      ...student,
      student_id: student.id,
      subject_id: timeTableChoice ? timeTableChoice.subject_id : null,
      week: timeTableChoice ? timeTableChoice.week : null,
      date: timeTableChoice ? timeTableChoice.date : null,
      time: timeTableChoice ? timeTableChoice.time : null,
      orderonday: timeTableChoice ? timeTableChoice.orderonday : null,
      typecheck,
      violation: obj.violation,
      notes: obj.notes,
      notes2: obj.notes2,
      status: obj.status
    };
    delete data.id;
    if (!studentChoice.hasOwnProperty(student.id))
      studentChoice[student.id] = data;
    return data;
  }
  let viewtype = 'me';
  const allowgroup = checkRole(user, 'attends', 'group');
  if (allowgroup) viewtype = 'group';
  const allowall = checkRole(user, 'attends', 'all');
  if (allowall) viewtype = 'all';

  return (
    <AkkhorLayout idrole="attends">
      <h4 className="mt-2">{title}</h4>
      <FilterGradeClassWeekSubject
        idgroup={idgroup}
        classChoice={classChoice}
        setClassChoice={setClassChoice}
        setTimeTableChoice={setTimeTableChoice}
        viewtype={viewtype}
      ></FilterGradeClassWeekSubject>
      <div className="d-flex my-2">
        Giáo viên: {teacher ? <b className="ml-2"> {teacher.fullname}</b> : <i className="text-danger"> Không xác định</i>}
        {teacher2 && <b className="ml-2">, {teacher2.fullname}</b>}
      </div>
      <div style={{ width: '100%', height: '600px' }}>
        <StickyTable leftStickyColumnCount={2} borderWidth={0}>
          <Row className="bg-secondary text-light font-weight-bold" style={{ fontSize: 14 }}>
            <Cell className="bg-secondary text-center">#</Cell>
            <Cell className="bg-secondary text-center">Họ và tên</Cell>
            <Cell className="bg-secondary text-center">Giới tính</Cell>
            <Cell className="bg-secondary text-center">Có mặt</Cell>
            <Cell className="bg-secondary text-center">Đến muộn</Cell>
            <Cell className="bg-secondary text-center">Vắng có phép</Cell>
            <Cell className="bg-secondary text-center">Vắng không phép</Cell>
            <Cell className="bg-secondary text-center">Vi phạm</Cell>
            <Cell className="bg-secondary text-center">Ghi chú</Cell>
          </Row>
          {listStudent && <>{listStudent.map((item, i) => (
            <Row key={i}>
              {timeTableChoice && <ItemAttends
                i={i}
                data={createDataForItem(item, (listOnday && listOnday[item.id] ? listOnday[item.id] : null))}
                choiceItem={choiceItem}
                isaction={timeTableChoice && (timeTableChoice.teacher_id === user.data.id || timeTableChoice.teacher_id2 === user.data.id)}
              ></ItemAttends>}
            </Row>
          ))}
          </>}
        </StickyTable>
      </div>
      {timeTableChoice && (timeTableChoice.teacher_id === user.data.id || timeTableChoice.teacher_id2 === user.data.id) ? <div className="d-flex mt-4">
        <div style={{ width: 600 }} >
          <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
            {/*<div className="mb-3">
              <textarea className="form-control" rows={4} placeholder="Ghi chú" {...formik.getFieldProps("notes")}></textarea>
            </div>*/}
            <button type="submit" disabled={loading} className="btn btn-success btn-block btn-lg">Lưu</button>
          </form>
        </div>
      </div> : <p className="text-danger mt-3"><i>Bạn không có quyền điểm danh tiết học này</i></p>}

    </AkkhorLayout>
  );
}
