import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import FilterGradeClassWeek from "../../component/FilterGradeClassWeek";
import { COLORS_CHECK } from "../../configs";
import { URL_LIST_ALL } from "../../configs/api";
import AkkhorLayout from '../../layout/akkhor';
import ReportItemAttends from "./ItemAttends";

export default function ReportAttendsPage() {
  const { user } = useSelector((state) => state.auth);
  const [listStudent, setListStudent] = useState([]);
  const [listOnday, setListOnday] = useState(null);
  const [classChoice, setClassChoice] = useState(null);
  const [gradeChoice, setGradeChoice] = useState(null);
  const [dayChoice, setDayChoice] = useState(null);
  const [report, setReport] = useState(null);
  const [listTimeTable, setListTimeTable] = useState([]);
  const [tietHoc, setTietHoc] = useState([]);
  //Lấy danh sách học sinh
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null) {
      setListStudent([])
      setListOnday(null)
      let link=URL_LIST_ALL + "students_class?page=1&limit=40";
      if(classChoice>0)link+="&class_id=" + classChoice;
      if(gradeChoice>0)link+="&grade_id=" + gradeChoice;
      Axios.get(link,user.config)
        .then((res) => {
          if (res.data && res.data.status === "success") {
            if (mounted) setListStudent(res.data.data);
          } else console.log(res.data.message);
        })
        .catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [classChoice,gradeChoice,user]);

  
  //Lấy danh sách chuyên cần
  useEffect(() => {
    let mounted = true;
    if (classChoice !== null && dayChoice !== null) {
      setListOnday(null)
      Axios.get(URL_LIST_ALL + "report_attends?page=1&limit=500&class_id=" + classChoice+"&date="+dayChoice,user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setListOnday(res.data.data);
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [dayChoice,classChoice,user]);

  //Lấy danh sách điểm danh
  useEffect(() => {
    let mounted = true;
    if (dayChoice !== null) {
      setReport(null)
      Axios.get(URL_LIST_ALL + "report_attends/1?date="+dayChoice,user.config).then((res) => {
        if (res.data && res.data.status === "success") {
          if (mounted) setReport(res.data.data);
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
    return () => (mounted = false);
  }, [dayChoice,user]);
  

  const removeDuplicate=(_data,_date) => {
    setListTimeTable(_data)
    setDayChoice(_date)
    let newList={};
    _data.map((item,i)=>  {
      if(item.date  === _date)
      {
        newList[item.orderonday] = item;
      }
      return item;
    })
    setTietHoc(Object.values(newList));
  }

  const filterDataExport=()=>
  {
    const titles = [
      "Chưa điểm danh",
      "Có mặt",
      "Đến muộn",
      "Vắng có phép",
      "Vắng không phép",
    ];
    let array=[];
    let firstItem={
      '1':'TT',
      '2':'Họ và tên',
      '3':'Mã số',
      '4':'Đi học',
      '5':'Ăn trưa',
    }
    tietHoc.map((tiet,i)=>  {
      const key= ''+(i+6);
      firstItem[key] = 'Tiết ' + tiet.orderonday;
      return tiet;
    })
    array.push(firstItem)
    listStudent.map((item, i) => {
      let _item = {
        '1': i+1,
        '2':item.fullname,
        '3':item.code,
        '4':(item.lunch?(item.lunch.status2===1?'Có mặt':'Vắng mặt'):'Chưa điểm danh'),
        '5':(item.lunch?(item.lunch.status===1?'Có':'Không'):'Chưa điểm danh'),
      }
      const _listonday=  listOnday[item.id]
      tietHoc.map((tiet,i)=>  {
        let onday = null;
        _listonday.map((day) => {
          if (day.orderonday === tiet.orderonday) {
            onday = day;
            return true;
          }
          return day;
        });
        const key= ''+(i+6);
        if(onday)_item[key] = titles[onday.status];
        else _item[key] = titles[0];
        return tiet;
      })
      array.push(_item); 
      return item;
    })
    return array
  }
  return (
    <AkkhorLayout idrole="report.report-attends">
      <h4 className="mt-2">Báo cáo điểm danh học sinh</h4>
      {report &&<div className="row mt-3">
            <div className="col-6 col-sm-3 col-xl-2">Tổng số học sinh:</div>
            <div className="col-6 col-sm-3 col-xl-2"><b>{report.total_students}</b></div>
         
            <div className="col-6 col-sm-3 col-xl-2">Có mặt: </div>
            <div className="col-6 col-sm-3 col-xl-2" style={{color:COLORS_CHECK[1]}}><b>{report.total_present}</b></div>
         
            <div className="col-6 col-sm-3 col-xl-2">Số suất ăn: </div>
            <div className="col-6 col-sm-3 col-xl-2"  style={{color:COLORS_CHECK[1]}}><b>{report.total_lunch}</b></div>
      </div>}
      <div className="mt-3 d-flex">
      <FilterGradeClassWeek
        classChoice={classChoice}
        setClassChoice={setClassChoice}
        gradeChoice={gradeChoice}
        setGradeChoice={setGradeChoice}
        dayChoice={dayChoice}
        onListTimeTable={removeDuplicate}
      ></FilterGradeClassWeek>
       {(listTimeTable.length>0 && listOnday)&&<CSVLink
            data={filterDataExport()}
            filename={ "Bao-Cao-Diem-Danh-Hoc-Sinh-"+moment().format('DD-MM-YYYY')+".csv"}
            className="btn"
            target="_blank"
          >
            Tải xuống <span className="fas fa-download text-info"></span>
          </CSVLink>}
      </div>
       <div className="info-table table-responsive mt-2">
       <table className="table table-bordered table-hover table-striped"  >
         <thead>
          <tr>
        <td className="align-items-center bg-secondary text-white ">
          <div className="text-center w-100">TT</div>
        </td>
        <td className="align-items-center bg-secondary text-white ">
          <div className="text-center w-100">Họ và tên</div>
        </td>
        <td className="align-items-center bg-secondary text-white ">
          <div className="text-center w-100">Mã số</div>
        </td>
        <td className="align-items-center bg-secondary text-white ">
          <div className="text-center w-100">Đi học</div>
        </td>
        <td className="align-items-center bg-secondary text-white ">
          <div className="text-center w-100">Ăn trưa</div>
        </td>
        {tietHoc.map((item,i)=>  {
          if(item.date  === dayChoice)
          {
            return <td key={i} className="align-items-center bg-secondary text-white " >
              <div className="text-center w-100">Tiết {item.orderonday}</div>
            </td>}
          return null;
        })}
      </tr>
      </thead>
      <tbody>
      {listStudent.map((item, i) => (
        <tr key={i} >
          <ReportItemAttends
            i={i}
            tietHoc={tietHoc}
            student={item}
            listOnday={listOnday && listOnday[item.id]?listOnday[item.id]:[]}
            lunch={listOnday && listOnday[item.id+'_lunch']?listOnday[item.id+'_lunch']:null}
          ></ReportItemAttends>
        </tr>
      ))}
      </tbody>
      </table>
      </div>
    </AkkhorLayout>
  );
}
