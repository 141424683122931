import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswers } from "../../../redux/Actions";

export default function QFillTo(props) {
    const {item,currentAnswer} = props;
    const {Answers} = useSelector(state=>state.save);
    const dispatch = useDispatch();

    const corrects = item.corrects ? JSON.parse(item.corrects) : null;
    const [choice, setChoice] = useState(currentAnswer?currentAnswer:{})

    const chooseItem=(index,str) => {
        let newChoice = {...choice}
        if(str)newChoice[index]= str;
        else delete newChoice[index]
        setChoice(newChoice)
    }
    const onBlur=(e)=> {
        if(e.target.value)
        {
            let percent = 0
            if(corrects)
            {
                corrects.map((i,index)=>{
                    if(i.includes(";")){
                        const arr = i.split(';').map(string => string.trim())
                        if(choice[index] && arr.includes(choice[index].trim())) percent += 100/corrects.length
                    }
                    return i
                });
            }
            let newListAnswers = {...Answers}
            newListAnswers[item.id] = {question:item, answers:choice, percent:percent === 100 ? 100 : 0};
            dispatch(saveAnswers(newListAnswers))
        }
    }
    return (<div className="col-12 d-flex mt-3 mb-3">
        {corrects &&<>
        {corrects.map((item,i)=><input value={choice[i]||''} type="text" key={i} className={"form-control form-control-sm border mr-3"} style={{width:100}} onChange={(e)=>chooseItem(i,e.target.value)} onBlur={onBlur}/>)}
        </>}
    </div>);
}